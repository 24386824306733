import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackMessageComponent } from './feedback-message.component';
import { EquisCardModule } from '@tn-equis/core/components/card';
import { EquisStickerAnimationModule } from '@tn-equis/core/components/sticker-animation';


@NgModule({
  declarations: [
    FeedbackMessageComponent
  ],
  exports: [
    FeedbackMessageComponent
  ],
  imports: [
    CommonModule,
    EquisCardModule,
    EquisStickerAnimationModule
  ]
})
export class FeedbackMessageModule { }
