<div
  class="title"
  [ngStyle]="{ 'text-align': data.align, display: data.display }"
  [ngClass]="{ container: data.display === 'block' }"
>
  <container-element [ngSwitch]="data.heading">
    <ng-container *ngSwitchCase="'h1'"
      ><h1>{{ data.text }}</h1></ng-container
    >
    <ng-container *ngSwitchCase="'h2'"
      ><h2>{{ data.text }}</h2></ng-container
    >
    <ng-container *ngSwitchCase="'h3'"
      ><h3>{{ data.text }}</h3></ng-container
    >
    <ng-container *ngSwitchCase="'h4'"
      ><h4>{{ data.text }}</h4></ng-container
    >
    <ng-container *ngSwitchCase="'h5'"
      ><h5>{{ data.text }}</h5></ng-container
    >
    <ng-container *ngSwitchCase="'h6'"
      ><h6>{{ data.text }}</h6></ng-container
    >
  </container-element>
</div>
