import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { OtpCodeFormComponent } from './otp-code-form.component';
import { EquisCardModule } from '@tn-equis/core/components/card';
import { EquisTextfieldModule } from '@tn-equis/core/components/textfield';
import { TimerButtonComponent } from './timer-button/timer-button.component';
import { EquisControlMessageModule } from '@tn-equis/core/components/control-message';
import { EquisSnackbarModule } from '@tn-equis/core/components/snackbar';
import { ButtonComponent } from '../../../components/atoms/button/button.component';

@NgModule({
  declarations: [
    OtpCodeFormComponent,
    TimerButtonComponent
  ],
  exports: [
    OtpCodeFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    EquisCardModule,
    EquisTextfieldModule,
    ButtonComponent,
    EquisControlMessageModule,
    EquisSnackbarModule
  ]
})
export class OtpCodeFormModule { }
