import { Inject, Injectable, Optional } from '@angular/core';
import {
  EquisModalDialogService,
  IEquisModalDialogConfig,
  IEquisModalDialogConfig as ModalConfig,
} from '@tn-equis/core/components/modal-dialog';

import { PlatformService } from '@/services/platform.service';

import { ModalDownloadAppComponent as ModalComponent } from './modal-download-app/modal-download-app.component';
import { IModalDownloadApp } from './button-download-app.types';
import { DataLayerService } from '@/services/data-layer.service';
import { DOCUMENT } from '@angular/common';
import { VariantContainerComponent } from '../../sections/variant-container/variant-container.component';
import { AmplitudeExperiment } from '../../../common/types/data-layer';

@Injectable()
export class ButtonDownloadAppService {
  constructor(
    private modalDialogService: EquisModalDialogService,
    private platform: PlatformService,
    private dataLayer: DataLayerService,
    @Inject(DOCUMENT) private _document: Document,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  // PROPERTIES
  // This property is set by the component that receives the data from contentful
  // and is later used by the modal to display the texts and images.
  dialogData!: IModalDownloadApp;

  // MODALS
  private readonly SUCCESS_DIALOG: IEquisModalDialogConfig = {
    data: {
      header: {
        title: '',
        crossButton: true,
      },
      body: {
        sticker: {
          path: './assets/stickers/success/success.json',
          height: 150,
          width: 150,
          loop: false,
        },
        title: '¡Listo! Vas a recibir el link por WhatsApp',
        message:
          'Te enviamos el link a tu número. Puede demorar algunos minutos en llegar.',
      },
    },
    size: 'extra-small',
    type: 'alert',
  };
  private readonly RETRY_DIALOG: IEquisModalDialogConfig = {
    data: {
      header: {
        title: '',
        crossButton: true,
      },
      footer: {
        buttonList: [
          {
            text: 'Cancelar',
            disabled: false,
            closeModal: true,
          },
          {
            text: 'Intentar de nuevo',

            disabled: false,
            closeModal: true,
          },
        ],
      },
      body: {
        sticker: {
          path: './assets/stickers/hamster/hamster.json',
          height: 150,
          width: 150,
          loop: true,
        },
        title: 'Algo pasó y no podemos continuar',
        message:
          'Estamos trabajando para solucionarlo. ¿Nos das otra oportunidad?',
      },
    },
    size: 'extra-small',
    type: 'alert',
  };

  private readonly ERROR_DIALOG: IEquisModalDialogConfig = {
    data: {
      header: {
        title: '',
        crossButton: true,
      },
      footer: {
        buttonList: [
          {
            text: 'Entendido',
            disabled: false,
            closeModal: true,
          },
        ],
      },
      body: {
        sticker: {
          path: './assets/stickers/hamster/hamster.json',
          height: 150,
          width: 150,
          loop: true,
        },
        title: 'No podemos continuar',
        message: 'Estamos trabajando para solucionarlo. Probá más tarde.',
      },
    },
    size: 'extra-small',
    type: 'alert',
  };

  // METHODS
  /**
   * Shows the modal dialog.
   * @param title Header title
   */
  public show(title: string = 'Elegí cómo descargar la app') {
    const customConfig: ModalConfig = {
      type: 'custom',
      size: 'small',
      data: {
        header: {
          title,
          crossButton: true,
          alignContent: 'center',
        },
      },
    };
    this.modalDialogService
      .showModalDialog(customConfig, ModalComponent)
      // Subscribe to on close event
      .subscribe(() => {
        this.hideReCaptcha();
        this.pushOnClose();
      });

    this.pushOnShow(title);
  }

  /**
   * Closes the modal dialog.
   */
  public close() {
    this.modalDialogService.closeModalDialog({});
  }

  /**
   * Shows the modal dialog with the given state.
   * @param state Dialog state to show
   */
  public showStateDialog(state: 'success' | 'retry' | 'error') {
    const config = {
      success: this.SUCCESS_DIALOG,
      retry: this.RETRY_DIALOG,
      error: this.ERROR_DIALOG,
    };

    return this.modalDialogService.showModalDialog(config[state]);
  }

  /**
   * Redirects the user to the app. This must only be done on mobile devices (like Android or iOS devices)
   */
  public redirectToApp(link: string) {
    if (this.platform.isMobile) {
      window.open(link, '_blank');
    }
  }

  // PRIVATE METHODS
  private pushOnShow(title: string) {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    if (this.dialogData.dataLayer)
      this.dataLayer.push(
        {
          eventName: 'view_modal',
          eventParams: {
            component: title,
            section: this.dialogData.dataLayer.eventParams.section,
            flow: this.dialogData.dataLayer.eventParams.flow,
          },
        },
        this.dialogData.section,experiment
      );
  }
  private pushOnClose() {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }
    if (this.dialogData.section && this.dialogData.dataLayer)
      this.dataLayer.push(
        {
          eventName: 'modal_app',
          eventParams: {
            action: 'click',
            element: 'cerrar',
            section: this.dialogData.dataLayer.eventParams.section,
            flow: this.dialogData.dataLayer.eventParams.flow,
          },
        },
        this.dialogData.section,experiment
      );
  }

  private hideReCaptcha() {
    const recaptcha = this._document.getElementById('recaptcha');
    if (recaptcha) {
      const element: HTMLElement | null =
        this._document.querySelector('.grecaptcha-badge');
      if (element) {
        element.style.display = 'none';
      }
    }
  }
}
