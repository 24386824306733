import { Component, Input, OnInit } from '@angular/core';
import { EquisIconContainerSize } from '@tn-equis/core/components/icon-container';

import { IBooleanProperty, IconProps } from './boolean-property.types';

@Component({
  selector: 'nx-branch-boolean-property',
  templateUrl: './boolean-property.component.html',
  styleUrls: ['./boolean-property.component.scss'],
})
export class BooleanPropertyComponent implements IBooleanProperty, OnInit {
  // REQUIRED INPUTS
  @Input() accepts!: IBooleanProperty['accepts'];
  @Input() text!: IBooleanProperty['text'];

  // PROPERTIES
  iconProps!: IconProps;
  isEnabledClass!: string;

  // HOOKS
  ngOnInit() {
    this.iconProps = {
      icon: this.accepts ? 'icon-check' : 'icon-cross',
      // Transparent background
      containerColor: '#00000000',
      iconColor: this.accepts ? '#fe5000' : '#b2b2b2',
      hover: false,
      size: EquisIconContainerSize.SMALL,
    };

    this.isEnabledClass = this.accepts ? 'enabled' : 'disabled';
  }
}
