import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Self,
} from '@angular/core';

import { SectionService } from '@/services/section.service';

import { INavbar } from './navbar.types';
import { ActiveTabService } from './active-tab.service';
import { PlatformService } from '../../../services/platform.service';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { DOCUMENT } from '@angular/common';
import { Subscription, map, tap } from 'rxjs';
import { ContentType } from '../../../common/types/contentful';

@Component({
  selector: 'nx-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [SectionService],
})
export class NavbarComponent implements INavbar, OnInit, OnDestroy {
  constructor(
    @Self() section: SectionService,
    public activeTab: ActiveTabService,
    public platform: PlatformService,
    @Inject(DOCUMENT) private document: Document
  ) {
    section.sectionName = 'header';
  }

  // REQUIRED INPUTS
  @Input() data!: INavbar['data'];
  public stickyComponent?: any;

  // PROPERTIES
  isMenuOpen!: boolean;

  private windowResize$?: Subscription;

  // METHODS
  menuToggle(isOpen: boolean) {
    this.isMenuOpen = isOpen;
    const navbarBefore = this.document?.getElementById('before-navbar')!;
    const banner = this.document?.getElementById('banner');
    if (isOpen) {
      document.body.classList.add('stop-scroll');
      navbarBefore.style.top = `0px`;
    } else {
      document.body.classList.remove('stop-scroll');
      navbarBefore.style.top = `${banner?.clientHeight}px`;
    }
  }

  // HOOKS
  public ngOnInit() {
    if (!this.platform.isServer) this.setStickyButtonData();
    this.activeTab.updateActiveTab(this.data.tabs);
    setTimeout(() => {
      this.loadBanner();
    }, 250);
  }

  ngOnDestroy(): void {
    this.windowResize$?.unsubscribe();
  }

  private loadBanner(): void {
    if (this.platform.isServer) return;
    this.updateBannerAndNavbarHeight();
    const bannerState = sessionStorage.getItem('bannerState');
    if (bannerState) return;

    this.windowResize$ = fromEvent(window, 'resize')
      .pipe(
        map(() => window.innerWidth),
        tap(() => this.updateBannerAndNavbarHeight())
      )
      .subscribe();
  }

  private updateBannerAndNavbarHeight(): void {
    const banner = this.document?.getElementById('banner');
    const navbarBefore = this.document?.getElementById('before-navbar');
    const navbarHeight = this.document?.getElementById('navbar-height');

    if (!banner || !navbarBefore || !navbarHeight) return;
    banner.style.display = 'flex';
    navbarBefore.style.top = `${banner.clientHeight}px`;
    navbarHeight.style.height = `${
      navbarBefore.clientHeight + banner.clientHeight
    }px`;
  }

  private setStickyButtonData = () => {
    if (!this.data.stickyDownloadApp) return;

    this.stickyComponent = JSON.parse(
      JSON.stringify(this.data.stickyDownloadApp)
    );

    const contentType = this.data.stickyDownloadApp?.sys.contentType.sys.id as
      | 'nx23DownloadAppButton'
      | 'variantContainer';

    if (contentType === 'variantContainer') {
      const data = this.stickyComponent.fields.variants?.map(
        (obj: ContentType) => ({
          ...obj,
          fields: {
            ...obj?.fields,
            isFloating: true,
          },
        })
      );
      this.stickyComponent.fields.variants = data;
      return;
    }

    this.stickyComponent.fields.isFloating = true;
  };
}
