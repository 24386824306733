import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '@/directives/directives.module';

import { ClickeableContainerComponent } from './clickeable-container.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ClickeableContainerComponent],
  imports: [CommonModule, DirectivesModule, RouterModule],
  exports: [ClickeableContainerComponent],
})
export class ClickeableContainerModule {}
