<div class="grid-container" [class]="[imagePositionClass, typeClass]">
  <div
    class="image-content"
    [class.hero-mask_left]="
      isHero && imagePositionClass === 'image-position__right'
    "
    [class.hero-mask_right]="
      isHero && imagePositionClass === 'image-position__left'
    "
  >
    <picture [nxContentfulImage]="data.image">
      <source />
      <source />
      <source />
      <img [attr.loading]="isHero ? 'eager' : 'lazy'" />
    </picture>
  </div>
  <div
    class="text-content"
    [class.hero-text-content_right]="
      isHero && imagePositionClass === 'image-position__right'
    "
    [class.hero-text-content_left]="
      isHero && imagePositionClass === 'image-position__left'
    "
  >
    <nx-text-content
      [title]="data.title"
      [text]="data.text"
      [titleHierarchy]="data.titleHierarchy"
      [cta]="data.cta"
      [backgroundColor]="data.backgroundColor"
    ></nx-text-content>
  </div>
</div>
