<div class="pre-footer container">
  <a nxAbsoluteAnchor="/">
    <picture [nxContentfulImage]="data.logo">
      <source />
      <source />
      <source />
      <img class="footer-logo" loading="lazy" (click)="pushGoHome($event)" />
    </picture>
  </a>
  <div class="text-and-button">
    <div class="equis-h3-medium">{{ data.prefooterText }}</div>
    <nx-button-download-app [data]="data.prefooterButton.fields">
    </nx-button-download-app>
  </div>
</div>
