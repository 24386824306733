import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
} from '@angular/core';

import type { ICalculator } from './calculator.types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  EquisControlMessageStatus,
  IEquisControlMessageBullet,
} from '@tn-equis/core/components/control-message';
import { DataLayerService } from '../../../services/data-layer.service';
import { SectionService } from '../../../services/section.service';
import { AmplitudeExperiment } from '../../../common/types/data-layer';
import { VariantContainerComponent } from '../../sections/variant-container/variant-container.component';

@Component({
  selector: 'nx-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculatorComponent implements ICalculator, OnInit {
  constructor(
    private readonly formBuilder: FormBuilder,
    private dataLayer: DataLayerService,
    private section: SectionService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}
  @Input() public data!: ICalculator['data'];
  public form!: FormGroup;
  public isSubmited = false;
  public dailyInterests!: number;
  public earnings!: number;
  public result?: string;

  public amountMax: IEquisControlMessageBullet[] = [];
  public daysMax: IEquisControlMessageBullet[] = [
    {
      text: 'El máximo de días es 180',
      status: EquisControlMessageStatus.ERROR,
    },
  ];

  get amount() {
    return this.form.get('amount');
  }
  get days() {
    return this.form.get('days');
  }

  public bulletList: IEquisControlMessageBullet[] = [];

  public ngOnInit(): void {
    this.amountMax.push({
      text: `El tope a remunerar es de $${new Intl.NumberFormat('es-ES', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(this.data.limit)}`,
      status: EquisControlMessageStatus.ERROR,
    });
    this.initForm(this.data.limit);
    this.dailyInterests = this.data.tna / 365;
  }

  public initForm(amountMax: number): void {
    this.form = this.formBuilder.group({
      amount: ['', [Validators.required, Validators.max(amountMax)]],
      days: ['', [Validators.required, Validators.max(180)]],
    });
  }

  public onSubmit(): void {
    if (this.form.invalid) return;

    this.earnings = this.calculateCompoundInterest(
      parseInt(this.amount!.value),
      parseInt(this.days!.value)
    );

    this.bulletList = [
      {
        text: `Interés diario: ${this.dailyInterests.toFixed(2)}%`,
        status: EquisControlMessageStatus.DEFAULT,
      },
      {
        text: 'El valor es aproximado',
        status: EquisControlMessageStatus.DEFAULT,
      },
    ];

    this.result = `Total ganancias a ${this.days?.value} ${
      this.days?.value > 1 ? 'días' : 'día'
    }: $${(this.earnings - this.amount!.value).toFixed(2)}`;

    this.isSubmited = true;
    this.submitDataLayer();
  }

  private calculateCompoundInterest(amount: number, days: number): number {
    const dailyTna = this.dailyInterests;
    return Array.from<number>({ length: days }).reduce(
      (acc) => acc + acc * (dailyTna / 100),
      amount
    );
  }

  private submitDataLayer() {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    this.dataLayer.push(
      {
        eventName: 'ui_interaction',
        eventParams: {
          action: 'click',
          element: 'calcular interes generado',
          section: 'Calculator',
        },
      },
      this.section.sectionName,
      experiment
    );
  }
}
