import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquisSelectModule } from '@tn-equis/core/components/select';
import { EquisModalDialogModule } from '@tn-equis/core/components/modal-dialog';
import { EquisChipGroupModule } from '@tn-equis/core/components/chip';
import { EquisCardModule } from '@tn-equis/core/components/card';
import { EquisTagModule } from '@tn-equis/core/components/tag';
import { EquisPaginatorBigModule } from '@tn-equis/core/components/paginator-big';
import { EquisIconContainerModule } from '@tn-equis/core/components/icon-container';
import { EquisTooltipModule } from '@tn-equis/core/components/tooltip';
import { GoogleMapsModule } from '@angular/google-maps';

import { HeadingRendererModule } from '@/tooling/heading-renderer/heading-renderer.module';

import { BranchesMapComponent } from './branches-map.component';
import { BranchComponent } from './branch/branch.component';
import { GoogleMapService } from './google-map.service';
import { BranchFilteringService } from './branch-filtering.service';
import { BooleanPropertyComponent } from './boolean-property/boolean-property.component';

@NgModule({
  declarations: [
    BranchesMapComponent,
    BranchComponent,
    BooleanPropertyComponent,
  ],
  imports: [
    CommonModule,
    EquisSelectModule,
    EquisModalDialogModule,
    EquisChipGroupModule,
    EquisCardModule,
    EquisTagModule,
    EquisPaginatorBigModule,
    GoogleMapsModule,
    HeadingRendererModule,
    EquisIconContainerModule,
    EquisTooltipModule,
  ],
  providers: [GoogleMapService, BranchFilteringService],
  exports: [BranchesMapComponent],
})
export default class BranchesMapModule {
  public static component = BranchesMapComponent;
}
