<equis-card>
  <div class="padding flex-column">
    <div class="header">
      <p class="equis-h4-medium capitalize">{{ data.name }}</p>
      <equis-tag
        *ngIf="getProduct('1')"
        [text]="terminalTag"
      ></equis-tag>
    </div>

    <!-- DESKTOP -->
    <ng-container *ngIf="!isMobile">
      <p class="equis-body-2 text-with-ellipsis capitalize" [title]="data.address.full_address">
        {{ data.address.full_address }}
      </p>
      <p
        class="equis-body-2 text-with-ellipsis capitalize"
        [title]="data.opening_hours"
      >
        {{ data.opening_hours }}
      </p>
    </ng-container>

    <!-- MOBILE -->
    <ng-container *ngIf="isMobile">
      <p
        equisTooltip
        tooltipDirection="top"
        [tooltipContent]="data.address.full_address"
        class="equis-body-2 text-with-ellipsis capitalize"
        [title]="data.address.full_address"
      >
        {{ data.address.full_address }}
      </p>
      <p
        equisTooltip
        tooltipDirection="top"
        [tooltipContent]="data.opening_hours"
        class="equis-body-2 text-with-ellipsis capitalize"
        [title]="data.opening_hours"
      >
        {{ data.opening_hours }}
      </p>
    </ng-container>

    <!-- BOOLEAN PROPERTIES -->
    <div class="flex-column boolean-properties">
      <nx-branch-boolean-property
        [accepts]="!!getProduct('2')"
        text="Depósito de $ y pago de resumen de cuenta"
      ></nx-branch-boolean-property>
      <nx-branch-boolean-property
        [accepts]="!!getProduct('3')"
        text="Depósito, extracción de $ y pago de resumen de cuenta"
      ></nx-branch-boolean-property>
      <nx-branch-boolean-property
        [accepts]="!!getProduct('4')"
        text="Sector 24hs"
      ></nx-branch-boolean-property>
    </div>
  </div>
</equis-card>
