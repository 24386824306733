<div class="container vertical-padding" *ngIf="!platform.isServer">
  <nx-heading-renderer
    *ngIf="data.title && data.titleHierarchy"
    class="equis-h1-semibold title"
    [data]="data.titleHierarchy"
    >{{ data.title }}</nx-heading-renderer
  >
  <!-- SELECTS -->
  <div class="row selects">
    <div class="col-xs-12 col-md-4">
      <equis-select
        [items]="(states$ | async)!"
        [item]="(states$ | async)![0]"
        placeholderDefault="Provincia"
        messagesError="Seleccioná una provincia"
        [dropUp]="true"
        (itemSelected)="selectChange($event, 'state')"
        (click)="pushClickSelect('state')"
      >
      </equis-select>
    </div>
    <div class="col-xs-12 col-md-4">
      <equis-select
        *ngIf="localitySelectEnabled"
        [items]="(localities$ | async)!"
        [item]="(localities$ | async)![0]"
        placeholderDefault="Localidad"
        messagesError="Seleccioná una localidad"
        [dropUp]="true"
        [disabled]="!(localities$ | async)?.length"
        (itemSelected)="selectChange($event, 'locality')"
        (click)="pushClickSelect('locality')"
      >
      </equis-select>
    </div>
  </div>
  <!-- FILTERS -->
  <div class="row filters">
    <equis-chip-group
      [type]="'choice'"
      [chipList]="filterChips"
      (chipGroupEvents)="toggleFilter($event)"
    >
    </equis-chip-group>
  </div>
  <!-- MAP AND BRANCHES -->
  <div class="row map-and-branches">
    <div class="col-xs-12 col-md-6 map">
      <!-- GOOGLE MAP -->
      <div *ngIf="maps.apiLoaded | async" class="async-map-container">
        <google-map [options]="MAP_OPTIONS" height="100%" width="100%">
          <map-marker
            *ngFor="let branch of branchesForMap"
            #marker="mapMarker"
            (mapClick)="onMarkerClick(marker, branch)"
            [options]="maps.MARKER_OPTIONS"
            [position]="branch.marker"
          ></map-marker>
          <map-info-window>
            <div class="map-info-window-container">
              <p class="equis-body-1-medium capitalize">
                {{ maps.infoWindow.locality }}
              </p>
              <p class="equis-body-2 capitalize">
                {{ maps.infoWindow.address }}
              </p>
            </div>
          </map-info-window>
        </google-map>
      </div>
    </div>
    <div class="col-xs-12 col-md-6 branch-list">
      <ng-container *ngIf="paginatedBranches.length > 0; else noBranches">
        <nx-branch
          *ngFor="let branch of paginatedBranches"
          [data]="branch"
          [terminalFilter]="data.terminalFilter"
        ></nx-branch>
      </ng-container>

      <ng-template #noBranches>
        <p class="equis-h2-semibold">
          No existen sucursales que coincidan con los filtros seleccionados.
        </p>
      </ng-template>
    </div>
  </div>
  <!-- PAGINATOR -->
  <div class="row paginator" *ngIf="nPages > 1">
    <div class="col-xs-12 col-md-6">
      <equis-paginator-big
        [currentPage]="1"
        [totalItems]="nPages"
        (changePage)="changePage($event)"
      >
      </equis-paginator-big>
    </div>
  </div>
</div>
