import { Component, Input, Optional } from '@angular/core';

import type { ICtaSection } from './cta-section.types';
import { DataLayerService } from '@/services/data-layer.service';
import { SectionService } from '@/services/section.service';
import { AmplitudeExperiment } from '../../../common/types/data-layer';
import { VariantContainerComponent } from '../variant-container/variant-container.component';

@Component({
  selector: 'nx-cta-section',
  templateUrl: './cta-section.component.html',
  styleUrls: ['./cta-section.component.scss'],
  providers: [SectionService],
})
export class CtaSectionComponent implements ICtaSection {
  constructor(
    private dataLayer: DataLayerService,
    section: SectionService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {
    section.sectionName = 'cta';
  }

  // REQUIRED INPUTS
  @Input() data!: ICtaSection['data'] | any;

  get isDownloadAppButton() {
    const identifierName: string = this.data.cta.sys.contentType.sys.id;
    return identifierName === 'nx23DownloadAppButton';
  }

  pushClick() {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    // Add section name fallback
    this.dataLayer.push(this.data.dataLayer, 'content', experiment);
  }
}
