import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonChatbotComponent } from './button-chatbot.component';
import { ButtonComponent } from '../button/button.component';

@NgModule({
  declarations: [ButtonChatbotComponent],
  imports: [CommonModule, ButtonComponent],
  exports: [ButtonChatbotComponent],
})
export default class ButtonChatbotModule {
  public static component = ButtonChatbotComponent;
}
