import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import type { IGroupOfCards } from './group-of-cards.types';
import {
  NX23Card,
  NX23CardCountdown,
  NX23CardLarge,
  NX23CardVideo,
  NX23CardXl,
} from '@/types/content-types';
import { IPaginatorBigData } from '@tn-equis/core/components/paginator-big';
import { PlatformService } from '../../../../services/platform.service';
import {
  interval,
  takeUntil,
  switchMap,
  from,
  catchError,
  of,
  tap,
  Subject,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'nx-group-of-cards',
  templateUrl: './group-of-cards.component.html',
  styleUrls: ['./group-of-cards.component.scss'],
})
export class GroupOfCardsComponent implements IGroupOfCards, OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(
    public platform: PlatformService,
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  // REQUIRED INPUTS
  @Input() data!: IGroupOfCards['data'];
  @Input() id!: string;
  numberOfComponents!: number;
  numberOfPages!: number;
  max?: number;
  min?: number;
  filteredComponents!: (NX23Card | NX23CardLarge | NX23CardXl)[];
  page: number = 1;
  isCountdownStyle = false;

  // Type guard functions that helps TypeScript narrow down the type of card (an NX23 Card, an NX23 Card Large or an NX23 Card XL)

  isCardSmallOrMedium(
    card: NX23Card | NX23CardLarge | NX23CardXl
  ): card is NX23Card {
    return (card as NX23Card).sys.contentType.sys.id === 'nx23Card';
  }

  isCardLarge(
    card: NX23Card | NX23CardLarge | NX23CardXl
  ): card is NX23CardLarge {
    return (card as NX23CardLarge).sys.contentType.sys.id === 'nx23CardLarge';
  }

  isCardXl(card: NX23Card | NX23CardLarge | NX23CardXl): card is NX23CardXl {
    return (card as NX23CardXl).sys.contentType.sys.id === 'nx23CardXl';
  }

  isCardVideo(
    card: NX23Card | NX23CardLarge | NX23CardXl | NX23CardVideo
  ): card is NX23CardVideo {
    return (card as NX23CardVideo).sys.contentType.sys.id === 'nx23CardVideo';
  }

  isCardCountdown(
    card:
      | NX23Card
      | NX23CardLarge
      | NX23CardXl
      | NX23CardVideo
      | NX23CardCountdown
  ): card is NX23CardCountdown {
    return (
      (card as NX23CardCountdown).sys.contentType.sys.id === 'nx23CardCountdown'
    );
  }

  ngOnInit() {
    this.filterComponents();
    if (!this.platform.isServer) this.refreshData().subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private refreshData = () =>
    interval(60000).pipe(
      takeUntil(this.destroyed$),
      tap(() => {
        if (
          !Boolean(this.data.cards?.length) ||
          this.data.cards.some((card) => !this.isCardCountdown(card))
        ) {
          this.destroyed$.next();
        }
      }),
      switchMap(() =>
        from(
          this.http.get<any>(
            `/api/entry/${this.id}`
          )
        )
      ),
      catchError((e) => {
        console.log(e);
        this.destroyed$.next();
        return of();
      }),
      tap((content) => {
        this.data.cards = content.fields.cards;
        this.filterComponents();
      }),
      tap(() => this.changeDetectorRef.detectChanges())
    );

  private filterComponents = () => {
    if (!Boolean(this.data.cards?.length)) {
      return this.destroyed$.next();
    }
    this.numberOfComponents = this.data.cards.length;
    this.numberOfPages = Math.ceil(
      this.numberOfComponents /
        (this.data.componentsPerPage || this.numberOfComponents)
    );

    const cards = this.filter(this.page);
    this.setCountdownStyles(cards);
    this.filteredComponents = cards;
  };

  filter(page: number) {
    this.max = page * (this.data.componentsPerPage || this.numberOfComponents);
    this.min =
      page * (this.data.componentsPerPage || this.numberOfComponents) -
      (this.data.componentsPerPage || this.numberOfComponents);
    this.filteredComponents = this.data.cards.slice(this.min, this.max);
    return this.filteredComponents;
  }

  // Gets the number of pages needed depending on the number of items per page defined by the user
  get pages() {
    return this.numberOfPages;
  }

  changePageEvent(event: IPaginatorBigData) {
    this.filter(event.currentPage);
  }

  private setCountdownStyles = (
    cards: (NX23Card | NX23CardLarge | NX23CardXl)[]
  ) => {
    if (cards.every((card) => this.isCardCountdown(card))) {
      this.isCountdownStyle = true;
      cards.length > 1 ? (this.data.columns = '2') : (this.data.columns = '1');
    }
  };
}
