import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  EquisControlMessageStatus,
  IEquisControlMessageBullet,
} from '@tn-equis/core/components/control-message';
import { DeleteAccountService } from '../delete-account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FeedbackFormData } from '../models/feedback-form-data';
import { customEmailValidator } from './email.validator';
import { first } from 'rxjs/internal/operators/first';
import { catchError } from 'rxjs/internal/operators/catchError';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { IEnviroment } from '../../../common/types/enviroment';
import { PlatformService } from '../../../services/platform.service';
declare const grecaptcha: any;

const enviromentState = makeStateKey<IEnviroment>('enviroment');

@Component({
  selector: 'nx-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
})
export class FeedbackFormComponent implements OnInit {
  feedbackForm!: FormGroup;
  @Output() formSubmitSuccess = new EventEmitter<FeedbackFormData>();
  @Output() formSubmitError = new EventEmitter<HttpErrorResponse | Error>();
  loading = false;

  get email() {
    return this.feedbackForm.get('email');
  }
  get name() {
    return this.feedbackForm.get('name');
  }
  get lastname() {
    return this.feedbackForm.get('lastname');
  }
  get reason() {
    return this.feedbackForm.get('reason');
  }

  emailRequired: IEquisControlMessageBullet[] = [
    {
      text: 'El email es requerido',
      status: EquisControlMessageStatus.ERROR,
    },
  ];
  emailInvalid: IEquisControlMessageBullet[] = [
    {
      text: 'El email no es válido',
      status: EquisControlMessageStatus.ERROR,
    },
  ];
  nameRequired: IEquisControlMessageBullet[] = [
    {
      text: 'El nombre es requerido',
      status: EquisControlMessageStatus.ERROR,
    },
  ];
  lastnameRequired: IEquisControlMessageBullet[] = [
    {
      text: 'El apellido es requerido',
      status: EquisControlMessageStatus.ERROR,
    },
  ];
  reasonRequired: IEquisControlMessageBullet[] = [
    {
      text: 'El motivo es requerido',
      status: EquisControlMessageStatus.ERROR,
    },
  ];

  private RECAPTCHA_API_KEY!: string;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: DeleteAccountService,
    private platform: PlatformService,
    private readonly transferState: TransferState,
    @Optional()
    @Inject('enviromentFromVault')
    public enviroment: IEnviroment
  ) {
    if (this.platform.isServer) {
      this.transferState.set(enviromentState, this.enviroment);
    } else {
      this.RECAPTCHA_API_KEY = this.transferState.get(
        enviromentState,
        null
      )!.RECAPTCHA_API_KEY;
    }
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.feedbackForm = this.formBuilder.group({
      email: ['', [Validators.required, customEmailValidator]],
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      reason: ['', Validators.required],
    });
  }

  async onSubmit() {
    if (this.feedbackForm.valid) {
      this.loading = true;
      let token = '';
      try {
        token = await grecaptcha.enterprise.execute(this.RECAPTCHA_API_KEY, {
          action: 'baja_cuenta',
        });
        this.onStart(this.feedbackForm.value, token);
      } catch (error) {
        this.formSubmitError.emit(
          new Error(
            `'Error al obtener token de recaptcha', ${JSON.stringify(error)}`
          )
        );
      }
    }
  }

  onStart(formData: FeedbackFormData, token: string) {
    this.apiService
      .start({ formData, 'g-recaptcha-response': token })
      .pipe(
        first(),
        catchError((error: HttpErrorResponse) => {
          this.loading = false;
          this.formSubmitError.emit(error);
          throw error;
        })
      )
      .subscribe(() => {
        this.formSubmitSuccess.emit(this.feedbackForm.value);
      });
  }
}
