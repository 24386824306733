import { Component, Input } from '@angular/core';
import { ICard } from '../card.types';

@Component({
  selector: 'nx-card-small',
  templateUrl: './card-small.component.html',
  styleUrls: ['./card-small.component.scss'],
})
export class CardSmallComponent {
  constructor() {}

  // REQUIRED INPUTS
  @Input() data!: ICard['data'];

  // BOOLEAN PROPERTIES
  get isClickeable() {
    return !!this.data.url;
  }
  get isInsideSlider() {
    return this.data.isInsideSlider;
  }
  get hasDescription() {
    return !!this.data.description;
  }

  /**
   * Generates a class based on the direction of the card and other properties
   */
  get directionalClass() {
    if (this.data.isVertical)
      return {
        vertical_card: true,
        'vertical_card-without-description': !this.hasDescription,
        'card-with-url': this.isClickeable,
        'card-for-slider': !this.isInsideSlider,
      };
    else
      return {
        horizontal_card: true,
        'horizontal_card-without-description': !this.hasDescription,
        'card-with-url': this.isClickeable,
      };
  }
}
