import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { NX23NavbarTab } from '@/types/content-types';

@Injectable()
export class ActiveTabService {
  constructor(private location: Location) {}

  // PROPERTIES
  /**
   * Currently active tab
   */
  public activeTab!: NX23NavbarTab;
  /**
   * Active tab's signin button
   */
  public get signInButton() {
    return this.activeTab.fields.signinButton.fields;
  }

  // METHODS
  /**
   * Stores the current active tab (/personas, /comercios, etc) in the navbar
   * @param tabs The tabs from the navbar
   */
  public updateActiveTab(tabs: NX23NavbarTab[]) {
    const path = this.location.path();

    this.activeTab =
      tabs.find((tab) => {
        // Compare the current path with the tab's pathList and url
        const paths = tab.fields.pathList ?? [];
        return paths.includes(path) || tab.fields.url === path;
      }) ?? tabs[0];
  }
}
