import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { IComponentInjector } from './component-injector.types';
import { ComponentInjectorService } from './component-injector.service';

@Component({
  selector: 'nx-component-injector',
  template: '<ng-template #container></ng-template>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentInjectorComponent implements IComponentInjector, OnInit {
  constructor(
    private service: ComponentInjectorService,
    private injector: Injector,
    private cdr: ChangeDetectorRef
  ) {}

  // REQUIRED INPUTS
  @Input() component!: IComponentInjector['component'];

  // FIELDS
  @ViewChild('container', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;

  // PRIVATE METHODS

  // HOOKS
  ngOnInit() {
    this.service.injectComponent(
      this.component,
      this.container,
      this.injector,
      this.cdr
    );
  }
}
