import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostFooterComponent } from './post-footer/post-footer.component';

import LinkModule from '@/atoms/link/link.module';
import ButtonDownloadAppModule from '@/atoms/button-download-app/button-download-app.module';
import { DirectivesModule } from '@/directives/directives.module';

import { FooterComponent } from './footer.component';
import { PreFooterComponent } from './pre-footer/pre-footer.component';
import { BodyFooterComponent } from './body-footer/body-footer.component';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '../../atoms/button/button.component';

@NgModule({
  declarations: [
    FooterComponent,
    PreFooterComponent,
    BodyFooterComponent,
    PostFooterComponent,
  ],
  imports: [
    CommonModule,
    ButtonComponent,
    LinkModule,
    ButtonDownloadAppModule,
    DirectivesModule,
    RouterModule
  ],
  exports: [FooterComponent],
})
export default class FooterModule {
  public static component = FooterComponent;
}
