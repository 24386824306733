// Chatbot Documentation: https://naranjafintech.atlassian.net/wiki/spaces/SM/pages/526942306312/Componete+chat+flotante
declare function bmMaximize(): void;
declare function bmMinimize(): void;
declare function bmHide(): void;
declare function bmShow(): void;
import { Inject, Injectable } from '@angular/core';
// const {
//   default: webchat,
// } = require('@tn-assistedchannels/bik-webchat-botmaker');
import { DOCUMENT } from '@angular/common';

import { DataLayer } from '@/types/contentful';

import { PlatformService } from './platform.service';
import { DataLayerService } from './data-layer.service';

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  constructor(
    private platform: PlatformService,
    private dataLayer: DataLayerService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  private initialized = false;

  /**
   * Initialize the chatbot. Should be called in app start.
   */
  public initialize(callback: () => void) {
    // Check if the app is running in the browser, because the chatbot acceses the `document` object.
    if (this.platform.isBrowser && !this.initialized) {
      const script = document.createElement('script') as HTMLScriptElement;
      script.type = 'text/javascript';
      script.async = false;
      script.src = 'https://go.botmaker.com/rest/webchat/p/4GK3B3170V/init.js';
      script.onload = () => {
        callback();
      };

      this.initialized = true;
      this._document.body.appendChild(script);
      this.dataLayer.push(this.getDataLayer('chat'), 'preloadchat');
    }
  }

  /**
   * Display the chatbot.
   */
  public show() {
    // The chatbot takes approximately 2 seconds to load.
    const TIME = 2000;
    this.initialize(() => setTimeout(this.showChat, TIME));
  }

  /**
   * Hide the chatbot.
   */
  public hide() {
    this.hideChat();
  }

  /**
   * Generate the `DataLayer` object.
   * @param element Element or text that triggered the chatbot.
   * @returns `DataLayer` object to be used in the `dataLayer` service.
   */
  public getDataLayer(element: string): DataLayer {
    return {
      eventName: 'contact',
      eventParams: {
        action: 'open',
        channel: 'chat bot',
        element,
      },
    };
  }

  private showChat = (): void => {
    bmMaximize();
    bmShow();
  };

  private hideChat = (): void => {
    bmMinimize();
    bmHide();
  };
}
