<div class="body-container flex-column">
  <div class="qr flex-column">
    <p class="equis-body-1-medium">{{ data.qrHint }}</p>
    <picture [nxContentfulImage]="data.qr" [forceSize]="false">
      <source />
      <source />
      <source />
      <img loading="lazy" class="qr" width="220" height="220" />
    </picture>
  </div>
  <equis-divider></equis-divider>
  <div class="phone flex-column">
    <p class="equis-body-1-medium">{{ data.formHint }}</p>
    <form>
      <equis-textfield label="Celular" icon="icon-magnifier">
        <input
          equisTextfieldInput
          [type]="INPUT_TYPE"
          [formControl]="phoneFormControl"
          [maxlength]="PHONE_LENGTH"
          [minlength]="PHONE_LENGTH"
        />
        <equis-control-message
          [text]="phoneInputData.text"
          [status]="phoneInputData.status"
          [hasIcon]="phoneInputData.hasIcon"
        ></equis-control-message>
      </equis-textfield>
      <app-button
        text="Enviar"
        (click)="sendWhatsapp()"
        [loading]="isLoading"
        [disabled]="
          phoneFormControl.invalid ||
          phoneFormControl.value?.length !== PHONE_LENGTH
        "
      ></app-button>
    </form>
  </div>
</div>
