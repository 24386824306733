import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import type { IEntry } from './entry.types';

@Component({
  selector: 'nx-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryComponent implements IEntry {
  constructor() {}
  @Input() data!: IEntry['data'];
}
