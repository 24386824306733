import { Component, Input } from '@angular/core';

import { ICapsuleX } from '@/atoms/capsule-x/capsule-x.types';

import { IPromotionalBanner } from './promotional-banner.types';

@Component({
  selector: 'nx-promotional-banner',
  templateUrl: './promotional-banner.component.html',
  styleUrls: ['./promotional-banner.component.scss'],
})
export class PromotionalBannerComponent implements IPromotionalBanner {
  constructor() {}

  // REQUIRED INPUTS
  @Input() data!: IPromotionalBanner['data'];

  /**
   * Gets the type of the promotional banner.
   */
  get type() {
    return this.data.type;
  }

  get backgroundColor() {
    return this.data.backgroundColor?.value;
  }

  get capsuleXContent(): ICapsuleX {
    return {
      data: {
        textContent: {
          title: this.data.title,
          text: this.data.text,
          titleHierarchy: this.data.titleHierarchy,
          cta: this.data.cta,
        },
        image: this.data.mainImage,
        imagePosition: this.data.imagePosition,
      },
    };
  }

  get imagePosition() {
    return this.data.imagePosition;
  }

  /**
   * Whether the pattern X position is left or right.
   */
  get patternXPosition() {
    if (this.data.imagePosition === 'Izquierda') {
      return 'pattern-x_left';
    } else return 'pattern-x_right';
  }

  get paddingClass() {
    const className = {
      'Reducir espaciado superior': 'padding_top',
      'Reducir espaciado inferior': 'padding_button',
      'Reducir espaciado superior e inferior': 'padding_both',
    };
    return className[this.data?.padding] ?? null;
  }
}
