<div
  class="navbar-bottom container"
  [ngClass]="{ 'navbar-bottom--server': platform.isServer }"
  [class.expanded]="isDropdownActive"
>
  <nx-navbar-tab-content
    class="nx-navbar-tab-content"
    [data]="data"
    [tabContent]="tabContent"
    (isDropdownActiveChange)="handleDropdownActiveValue($event)"
  ></nx-navbar-tab-content>
</div>
