<nx-clickeable-container [url]="data.url">
  <div [ngClass]="dynamicClass">
    <div class="image-wrapper">
      <picture [nxContentfulImage]="data.image">
        <source />
        <source />
        <source />
        <img loading="lazy" />
      </picture>
    </div>
    <div class="text-wrapper">
      <container-element [ngSwitch]="data.heading">
        <ng-container *ngSwitchCase="'h1'"
          ><h1 class="title equis-h2-medium">
            {{ data.title }}
          </h1></ng-container
        >
        <ng-container *ngSwitchCase="'h2'"
          ><h2 class="title equis-h2-medium">
            {{ data.title }}
          </h2></ng-container
        >
        <ng-container *ngSwitchCase="'h3'"
          ><h3 class="title equis-h2-medium">
            {{ data.title }}
          </h3></ng-container
        >
        <ng-container *ngSwitchCase="'h4'"
          ><h4 class="title equis-h2-medium">
            {{ data.title }}
          </h4></ng-container
        >
        <ng-container *ngSwitchCase="'h5'"
          ><h5 class="title equis-h2-medium">
            {{ data.title }}
          </h5></ng-container
        >
        <ng-container *ngSwitchCase="'h6'"
          ><h6 class="title equis-h2-medium">
            {{ data.title }}
          </h6></ng-container
        >
        <ng-container *ngSwitchDefault
          ><h2 class="title equis-h2-medium">
            {{ data.title }}
          </h2></ng-container
        >
      </container-element>

      <p class="description equis-body-1">{{ data.description }}</p>
    </div>
  </div>
</nx-clickeable-container>
