import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/**
 * Lazy load a page module
 * @param name Name of the page module to lazy load
 * @returns A promise of the dynamically loaded module
 */
const lazyLoadPage = (name: string) => {
  return () => import(`./routes/${name}/${name}.module`).then((m) => m.default);
};

const routes: Routes = [
  {
    path: 'baja-cuenta',
    loadChildren: lazyLoadPage('delete-account'),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./components/tooling/page-builder/page-builder.component').then(
        (m) => m.PageBuilderComponent
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
