import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EquisCardModule } from '@tn-equis/core/components/card';
import { EquisControlMessageModule } from '@tn-equis/core/components/control-message';
import { EquisTextfieldModule } from '@tn-equis/core/components/textfield';
import { FeedbackFormComponent } from './feedback-form.component';
import { ButtonComponent } from '../../../components/atoms/button/button.component';


@NgModule({
  declarations: [
    FeedbackFormComponent
  ],
  exports: [
    FeedbackFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    EquisCardModule,
    EquisTextfieldModule,
    ButtonComponent,
    EquisControlMessageModule
  ]
})
export class FeedbackFormModule { }
