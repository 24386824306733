import { Component, Input } from '@angular/core';

import type { ITextImage } from '../text-image.types';

@Component({
  selector: 'nx-text-image-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss'],
})
export class CustomComponent implements ITextImage {
  constructor() {}

  // REQUIRED INPUTS
  @Input() data!: ITextImage['data'];
  @Input() component!: ITextImage['data']['component'];

  // PROPERTIES
  get image() {
    return {
      src: this.data.image.fields.file?.url,
      alt: this.data.image.fields.title,
    };
  }
  /**
   * Checks if the component is a hero or not.
   */
  get isHero() {
    return this.type === 'Hero';
  }

  get isSecondaryHero() {
    return this.type === 'Hero secundario';
  }

  get type() {
    return this.data.type ?? 'Imagen pequeña';
  }

  get imagePosition() {
    return this.data.imagePosition ?? 'Izquierda';
  }

  // CLASSES
  /**
   * Returns the class corresponding to the the `typr` input.
   */
  get typeClass() {
    return this.isHero ? 'type__hero' : 'type__default';
  }

  /**
   * Checks if the image is on the left or the right and returns the corresponding class.
   */
  get imagePositionClass() {
    if (this.imagePosition === 'Izquierda') return 'image-position__left';
    else return 'image-position__right';
  }
}
