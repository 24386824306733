import { Component, Input, OnInit } from '@angular/core';

import { PlatformService } from '@/services/platform.service';
import { IBranch } from '../branches-map.types';

@Component({
  selector: 'nx-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss'],
})
export class BranchComponent implements OnInit {
  constructor(public platform: PlatformService) {}
  // REQUIRED INPUTS
  @Input() data!: IBranch;
  @Input() terminalFilter!: string;

  // PROPERTIES
  terminalTag!: string;
  get isMobile() {
    return this.platform.isMobile;
  }

  // HOOKS
  ngOnInit() {
    this.terminalTag = `Con ${
      this.terminalFilter.toLowerCase() ?? 'sector de autogestión'
    }`;
  }

  public getProduct = (id: string) => {
    return this.data.products.find(
      (product) => product?.prod_id === id && product?.prod_borrado === '0'
    );
  };
}
