// Ideas taken from legacy project https://gitlab.com/Naranja-Digital/naranjadotcom/frontend/naranjaweb/-/blob/integration/src/app/core/services/whastapp-bot.service.ts

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEquisModalDialogResponse as IModalResponse } from '@tn-equis/core/components/modal-dialog';

import { environment } from '@/environment';

import { ButtonDownloadAppService } from './button-download-app.service';
import { Subject } from 'rxjs/internal/Subject';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class WhatsappService {
  constructor(
    private http: HttpClient,
    private dialog: ButtonDownloadAppService
  ) {
    this.eventHandler.subscribe((status) => {
      switch (status) {
        // Show failure dialog when the process fails and the max retries are reached
        case 'error':
          this.resetFields();
          this.dialog.showStateDialog('error');
          break;
        // Execute a retry when the process fails
        case 'retry':
          this.dialog
            .showStateDialog('retry')
            .subscribe(({ event, data }: IModalResponse) => {
              if (
                event === 'close_button' &&
                data.text === 'Intentar de nuevo'
              ) {
                this.sendAppLink(this.phone, this.reCaptchaToken);
              } else {
                this.eventHandler.next('retryCancel');
              }
            });
          break;
        case 'success':
          this.dialog.showStateDialog('success');

          this.resetFields();
          break;
      }
    });
  }

  // FIELDS
  private tryCounter = 0;
  private phone!: string;
  private reCaptchaToken!: string;
  private readonly MAX_TRIES = 3;
  private readonly BFF_BOT_URL = `${environment.BFF_URL}/botmaker/template`;

  // PROPERTIES
  public eventHandler = new Subject<
    'error' | 'success' | 'retry' | 'retryCancel'
  >();

  // METHODS
  /**
   * Sends the download link to the user's Whatsapp
   * @param phone Whatsapp phone number to send the app download link
   */
  public sendAppLink(phone: string, reCaptchaToken: string): void {
    // Store the number to use it later for further retries
    this.phone = phone;
    this.reCaptchaToken = reCaptchaToken;

    // Check if the POST must be done
    if (this.tryCounter++ < this.MAX_TRIES) {
      this.http
        .post(
          this.BFF_BOT_URL,
          { phoneNumber: phone },
          {
            headers: new HttpHeaders({
              'g-recaptcha-response': reCaptchaToken,
            }),
          }
        )
        .pipe(catchError((error) => throwError(() => new Error(error))))
        .subscribe({
          next: () => this.eventHandler.next('success'),
          error: () => this.eventHandler.next('retry'),
        });
    } else {
      // Throw error
      this.eventHandler.next('error');
    }
  }

  // PRIVATE METHODS
  private resetFields() {
    this.phone = '';
    this.tryCounter = 0;
  }
}
