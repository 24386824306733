import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
} from '@angular/core';

import type { IEntryMeta } from './entry-meta.types';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { DataLayerService } from '../../../services/data-layer.service';
import { SectionService } from '../../../services/section.service';
import { AmplitudeExperiment } from '../../../common/types/data-layer';
import { VariantContainerComponent } from '../variant-container/variant-container.component';

@Component({
  selector: 'nx-entry-meta',
  templateUrl: './entry-meta.component.html',
  styleUrls: ['./entry-meta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryMetaComponent implements IEntryMeta, OnInit {
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private dataLayer: DataLayerService,
    private section: SectionService,
    @Inject(DOCUMENT) private document: Document,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}
  @Input() data!: IEntryMeta['data'];
  public estimatedReadingTime = 0;

  public async onShare() {
    const shareData = {
      title: this.titleService?.getTitle().toString(),
      text: this.metaService.getTag('name="description"')?.content,
      url: this.router.url,
    };
    this.submitDataLayer();
    await navigator.share(shareData);
  }

  ngOnInit(): void {
    setTimeout(() => {
      let totalTextLength = 0;
      const richTextElements = this.document.querySelectorAll(
        'nx-rich-text-renderer'
      );

      if (!Boolean(richTextElements.length)) {
        return;
      }

      for (const item of richTextElements as any) {
        totalTextLength += item.textContent.length;
      }

      const readingSpeed = 225;
      this.estimatedReadingTime = Math.round(
        totalTextLength / (readingSpeed * 5.1)
      );
      this.cdr.detectChanges();
    }, 250);
  }

  private submitDataLayer() {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    this.dataLayer.push(
      {
        eventName: 'ui_interaction',
        eventParams: {
          action: 'click',
          element: 'Compartir',
          section: 'Entry Meta',
        },
      },
      this.section.sectionName,
      experiment
    );
  }
}
