import { Component, Input, Optional } from '@angular/core';

import { DataLayerService } from '@/services/data-layer.service';
import { SectionService } from '@/services/section.service';

import { ICard } from './card.types';
import { VariantContainerComponent } from '../../sections/variant-container/variant-container.component';
import { AmplitudeExperiment } from '../../../common/types/data-layer';

@Component({
  selector: 'nx-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements ICard {
  constructor(
    private dataLayer: DataLayerService,
    private section: SectionService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  // REQUIRED INPUTS
  @Input() data!: ICard['data'];

  // METHODS
  public pushClick() {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }
    // If the card is clickable
    if (this.data.url)
      this.dataLayer.push(
        this.data.dataLayer,
        this.section.sectionName,
        experiment
      );
  }
}
