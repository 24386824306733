import { Component, Input, Optional, ViewEncapsulation } from '@angular/core';

import { DataLayerService } from '@/services/data-layer.service';
import { SectionService } from '@/services/section.service';

import type { ICardXl } from './card-xl.types';
import { PlatformService } from '../../../services/platform.service';
import { AmplitudeExperiment } from '../../../common/types/data-layer';
import { VariantContainerComponent } from '../../sections/variant-container/variant-container.component';

@Component({
  selector: 'nx-card-xl',
  templateUrl: './card-xl.component.html',
  styleUrls: ['./card-xl.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardXlComponent implements ICardXl {
  constructor(
    private dataLayer: DataLayerService,
    private section: SectionService,
    public platform: PlatformService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  // REQUIRED INPUTS
  @Input() data!: ICardXl['data'];

  // PROPERTIES

  get imagePosition() {
    return this.data.imagePosition ?? 'Izquierda';
  }
  get isLeft() {
    return this.imagePosition === 'Izquierda';
  }

  // METHODS
  pushClick() {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    if (this.data.url)
      this.dataLayer.push(
        this.data.dataLayer,
        this.section.sectionName,
        experiment
      );
  }
}
