<div
  class="banner"
  id="banner"
  style="--backgroundColor: {{
    data.backgroundColor?.value
  }}; --linkHoverColor: {{ data.linkHoverColor?.value }}"
  *ngIf="showBanner"
>
  <div
    class="container content"
    style="--contentAlignment: {{ contentAlignment }}"
  >
    <nx-rich-text-renderer
      *ngIf="!!data.text"
      [text]="data.text"
      class="text"
      style="--textColor: {{ data.textColor?.value }};"
    ></nx-rich-text-renderer>

    <nx-component-injector
      *ngIf="data.cta"
      [component]="data.cta"
      class="cta-button"
    ></nx-component-injector>
  </div>

  <equis-icon-container
    icon="icon-cross"
    containerColor="transparent"
    [hover]="true"
    class="close-button"
    (click)="close()"
  >
  </equis-icon-container>
</div>
