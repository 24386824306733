<div class="body-footer">
  <ul class="links-list container">
    <li class="link-list_items" *ngFor="let item of data.footerLinks">
      <p *ngIf="item.fields.title" class="equis-h3-semibold">
        {{ item.fields.title }}
      </p>
      <ul
        class="link-list_items-list"
        [class.row-list]="item.fields.isDirectionRow"
      >
        <li *ngFor="let link of item.fields.linksList" (click)="onClick(link)">
          <a
            *ngIf="isSocialLink(link.fields)"
            class="equis-body-1 link"
            [routerLink]="platform.getRelativeUrl(link.fields.url)"
            [nxAbsoluteAnchor]="link.fields.url"
            [target]="link.fields.opensNewTab ? '_blank' : '_self'"
            rel="nofollow"
          >
            <picture [nxContentfulImage]="link.fields.icon">
              <source />
              <source />
              <source />
              <img loading="lazy" />
            </picture>
            {{ link.fields.label }}
          </a>
          <a
            class="equis-body-1 link"
            [routerLink]="platform.getRelativeUrl(link.fields.url)"
            [nxAbsoluteAnchor]="link.fields.url"
            [target]="link.fields.opensNewTab ? '_blank' : '_self'"
            [rel]="getRelAnchor(link.fields.url)"
            >{{ link.fields.label }}</a
          >
        </li>
      </ul>
    </li>
  </ul>
</div>
