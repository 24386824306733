import { Component, Input, Optional } from '@angular/core';
import { EquisButtonBaseSizes } from '@tn-equis/core/components/button';

import { getWidth, mapStyle } from '@/utils/link';
import { DataLayerService } from '@/services/data-layer.service';
import { ChatbotService } from '@/services/chatbot.service';
import { SectionService } from '@/services/section.service';

import type { IButtonChatbot } from './button-chatbot.types';
import { VariantContainerComponent } from '../../sections/variant-container/variant-container.component';
import { AmplitudeExperiment } from '../../../common/types/data-layer';

@Component({
  selector: 'nx-button-chatbot',
  templateUrl: './button-chatbot.component.html',
})
export class ButtonChatbotComponent implements IButtonChatbot {
  constructor(
    private dataLayer: DataLayerService,
    private chatbot: ChatbotService,
    private section: SectionService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}
  // REQUIRED INPUTS
  @Input() data!: IButtonChatbot['data'];
  // OPTIONAL INPUTS
  @Input() width?: IButtonChatbot['width'] = 'full';
  @Input() size: IButtonChatbot['size'] = EquisButtonBaseSizes.LARGE;

  // PROPERTIES
  /**
   * Gets the button style, based on the style input.
   */
  get buttonStyle() {
    return mapStyle(this.data.style);
  }
  /**
   * Gets the button width, based on the width input.
   */
  get buttonWidth() {
    return getWidth(this.width);
  }

  // HANDLERS
  onClick() {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    this.dataLayer.push(
      this.chatbot.getDataLayer(this.data.label),
      this.section.sectionName,
      experiment
    );
    this.chatbot.show();
  }
}
