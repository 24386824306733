import { ContentType } from '@/types/contentful';

export enum PathType {
  SECTION = 'sections',
  ATOM = 'atoms',
  LAYOUT = 'layouts',
}

export type Path = {
  type: PathType;
  path: string;
};
export type Paths = Record<string, Path>;

export interface IComponentInjector {
  // REQUIRED INPUTS
  component: ContentType;
}
