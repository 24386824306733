import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquisButtonModule } from '@tn-equis/core/components/button';

import TextContentModule from '@/layouts/text-content/text-content.module';
import CapsuleXModule from '@/atoms/capsule-x/capsule-x.module';
import { DirectivesModule } from '@/directives/directives.module';

import { PromotionalBannerComponent } from './promotional-banner.component';
@NgModule({
  declarations: [PromotionalBannerComponent],
  imports: [
    CommonModule,
    EquisButtonModule,
    TextContentModule,
    CapsuleXModule,
    DirectivesModule,
  ],
  exports: [PromotionalBannerComponent],
})
export default class PromotionalBannerModule {
  public static component = PromotionalBannerComponent;
}
