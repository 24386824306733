import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DeleteAccountRoutingModule } from './delete-account-routing.module';
import { DeleteAccountComponent } from './delete-account.component';
import { LayoutModule } from './layout/layout.module';
import { InfoPanelModule } from './info-panel/info-panel.module';
import { FeedbackFormModule } from './feedback-form/feedback-form.module';
import { OtpCodeFormModule } from './otp-code-form/otp-code-form.module';
import { FeedbackMessageModule } from './feedback-message/feedback-message.module';
import { DeleteAccountService } from './delete-account.service';
import { ButtonComponent } from '../../components/atoms/button/button.component';

@NgModule({
  declarations: [
    DeleteAccountComponent,
  ],
  providers: [DeleteAccountService],
  imports: [
    CommonModule,
    DeleteAccountRoutingModule,
    LayoutModule,
    InfoPanelModule,
    FeedbackFormModule,
    OtpCodeFormModule,
    FeedbackMessageModule,
    ButtonComponent
  ]
})
export default class DeleteAccountModule { }
