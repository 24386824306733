<div class="post-footer">
  <div class="container">
    <ul class="legal-images">
      <li *ngFor="let image of data.legals">
        <a
          [routerLink]="platform.getRelativeUrl(image.fields.url)"
          [nxAbsoluteAnchor]="image.fields.url"
          target="_blank"
          (click)="pushImage(image)"
          rel="nofollow"
        >
          <img
            loading="lazy"
            *ngIf="getImage(image)"
            [src]="getImage(image)!.src"
            alt="getImage(image)!.alt"
            loading="lazy"
            [width]="getImage(image)!.width"
            [height]="getImage(image)!.height"
          />
        </a>
      </li>
    </ul>
    <ul class="links-list">
      <li *ngFor="let link of data.postfooterLinks">
        <a
          class="equis-body-2 link"
          [routerLink]="platform.getRelativeUrl(link.fields.url)"
          [nxAbsoluteAnchor]="link.fields.url"
          [target]="link.fields.opensNewTab ? '_blank' : '_self'"
          (click)="pushLink(link)"
          rel="nofollow"
          >{{ link.fields.label }}
        </a>
      </li>
    </ul>
  </div>
</div>
