import { Component, Input } from '@angular/core';
import { ICard } from '../card.types';

@Component({
  selector: 'nx-card-medium',
  templateUrl: './card-medium.component.html',
  styleUrls: ['./card-medium.component.scss'],
})
export class CardMediumComponent {
  constructor() {}

  // REQUIRED INPUTS
  @Input() data!: ICard['data'];

  // PROPERTIES
  get isClickeable() {
    return !!this.data.url;
  }

  /**
   * Generates a class based on the component properties
   */
  get dynamicClass() {
    return {
      card: true,
      'card-with-url': this.isClickeable,
    };
  }
}
