import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

import { ICapsuleX } from '@/atoms/capsule-x/capsule-x.types';
import { SectionService } from '@/services/section.service';

import { IContainerX } from './container-x.types';

@Component({
  selector: 'nx-container-x',
  templateUrl: './container-x.component.html',
  styleUrls: ['./container-x.component.scss'],
  providers: [SectionService],
})
export class ContainerXComponent implements IContainerX {
  constructor(private sanitizer: DomSanitizer) {}

  @ViewChild('textSpan', { static: true }) textSpanRef: ElementRef | undefined;

  @ViewChild('mobileTextSpan', { static: true }) mobileTextSpan:
    | ElementRef
    | undefined;

  @Input() data!: IContainerX['data'];

  get backgroundText(): string {
    if (this.data.backgroundText?.length) {
      const backgroundTextLength = this.data.backgroundText?.length * 50;
      const repetitionsNeeded = 7680 / backgroundTextLength;
      let backgroundText = '';
      for (let i = 0; i < repetitionsNeeded; i++) {
        backgroundText += ' ' + this.data.backgroundText;
      }
      return backgroundText;
    } else {
      return ''; // Return an empty string if there's no backgroundText
    }
  }

  get backgroundColor(): string | undefined {
    if (this.data.backgroundColor === undefined) {
      return undefined;
    }
    return this.data.backgroundColor.value;
  }

  get backgroundTextColor(): any | undefined {
    if (this.data.backgroundTextColor === undefined) {
      return undefined;
    }
    return this.sanitizer.bypassSecurityTrustStyle(
      `-webkit-text-stroke-color: ${this.data.backgroundTextColor.value}`
    );
  }

  get capsuleXContent(): ICapsuleX {
    return {
      data: {
        textContent: {
          title: this.data.title,
          text: this.data.text,
          titleHierarchy: this.data.titleHierarchy,
          cta: this.data.cta,
        },
        image: this.data.image,
        imagePosition: this.data.imagePosition,
      },
    };
  }

  get paddingClass() {
    const className = {
      'Reducir espaciado superior': 'padding_top',
      'Reducir espaciado inferior': 'padding_button',
      'Reducir espaciado superior e inferior': 'padding_both',
    };
    return className[this.data?.padding] ?? null;
  }
}
