// Idea extracted from: https://itbusinesshub.com/blog/integrate-google-tag-manager-in-angular-app/

import { Injectable } from '@angular/core';

import type { DataLayer as ContentfulDataLayer } from '@/types/contentful';
import type {
  AmplitudeExperiment,
  DataLayerArgument,
  GoogleDataLayer,
} from '@/types/data-layer';
import { environment } from '@/environment';

import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  constructor(private platform: PlatformService) {}

  // FIELDS
  private get dataLayer(): GoogleDataLayer | null {
    if (typeof window !== 'undefined') return (window as any).dataLayer;
    return null;
  }
  private get flow(): string {
    // Slicethe pathname to remove the first /
    const path = window.location.pathname.slice(1);

    // Check if the path is empty. In that case, we are in 'home'
    if (path === '') return 'home';
    else return path;
  }

  // METHODS
  /**
   * Pushes an event to the `dataLayer`. `flow` key and `section` key are calculated automatically if not present in the object
   * @param obj Object containing the event name and the event parameters
   * @param section Fallback section name if the `eventParams.section` is not present
   */
  public push(
    obj: ContentfulDataLayer | undefined,
    section: string,
    experiment?: AmplitudeExperiment
  ) {
    // If dataLayer != undefined, then window != undefined
    if (this.isValidObject(obj) && this.dataLayer) {
      const flow = obj.eventParams.flow ?? this.flow;
      const sectionName = `${obj.eventParams.section ?? section}${
        this.platform.isMobile ? ' mobile' : ''
      }`;

      let object: DataLayerArgument = {
        event: 'ga4.trackEvent',
        eventName: obj.eventName,
        eventParams: this.sanitizeObject({
          ...obj.eventParams,
          flow,
          section: sectionName,
        }),
        ...obj['extraParams']
      };

      if (experiment) {
        object.amplitudeExperiment = {
          key: experiment.key,
          variant: experiment.variant,
        };
      }

      // Add the absolute URL in referral if its present
      if ('referral' in obj.eventParams) {
        object.eventParams = {
          ...object.eventParams,
          referral: this.platform.getAbsoluteURL(obj.eventParams.referral),
        };
      }

      this.dataLayer.push(object);
      // Log the object if we are in development
      if (!environment.production) console.warn(object);
    }
  }

  // PRIVATE METHODS
  /**
   * Checks if a DataLayer object is valid (not undefined and has the required fields)
   * @param obj DataLayer object to check
   * @returns `true` if the object is valid, `false` otherwise
   */
  private isValidObject(
    obj: ContentfulDataLayer | undefined
  ): obj is ContentfulDataLayer {
    return !!obj && 'eventName' in obj && 'eventParams' in obj;
  }

  /**
   * Sanitizes Data Layer `eventParams`, removing accents and setting to lower case all the string values
   * @param object DataLayer params to sanitize
   * @returns A DataLayer ready params to push to the `dataLayer`
   */
  private sanitizeObject(
    params: DataLayerArgument['eventParams']
  ): DataLayerArgument['eventParams'] {
    let result = {} as DataLayerArgument['eventParams'];

    Object.entries(params).forEach(([key, value]) => {
      const sanitized = value
        // Remove accents (idea taken from https://www.30secondsofcode.org/js/s/remove-accents/)
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        // Set to lower case
        .toLowerCase();
      result = { ...result, [key]: sanitized };
    });

    return result;
  }
}
