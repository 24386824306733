import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CtaSectionComponent } from './cta-section.component';
import LinkModule from '@/atoms/link/link.module';
import { ComponentInjectorModule } from '../../tooling/component-injector/component-injector.module';

@NgModule({
  declarations: [CtaSectionComponent],
  imports: [CommonModule, LinkModule, ComponentInjectorModule],
  exports: [CtaSectionComponent],
})
export default class CtaSectionModule {
  public static component = CtaSectionComponent;
}
