import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

type paloEvent = {
  id_palo: string;
  id_partido: string;
  id_minuto: string;
  timestamp_end: number;
};

@Injectable({ providedIn: 'root' })
export class CountdownDataService {
  private dataSubject = new Subject<paloEvent>();

  setData(data: paloEvent) {
    this.dataSubject.next(data);
  }

  getData() {
    return this.dataSubject.asObservable();
  }
}
