<section [style.background-image]="'url(' + backgroudImageUrl + ')'">
  <div class="blog container">
    <div class="text-area">
      <nx-text-content
        [title]="data.title"
        [text]="data.text"
        [titleHierarchy]="data.titleHierarchy"
        [cta]="data.link"
        [size]="'small'"
      ></nx-text-content>
    </div>

    <ul class="cards-list">
      <li *ngFor="let card of data.cards">
        <nx-card [data]="card.fields"></nx-card>
      </li>
    </ul>
  </div>
</section>
