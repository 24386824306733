import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalculatorComponent } from './calculator.component';
import { SectionService } from '../../../services/section.service';
import { ComponentInjectorModule } from '../../tooling/component-injector/component-injector.module';
import { EquisTextfieldModule } from '@tn-equis/core/components/textfield';
import { EquisControlMessageModule } from '@tn-equis/core/components/control-message';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '../button/button.component';
import { DirectivesModule } from '../../../directives/directives.module';

@NgModule({
  declarations: [CalculatorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonComponent,
    ComponentInjectorModule,
    EquisTextfieldModule,
    EquisControlMessageModule,
    DirectivesModule
  ],
  exports: [CalculatorComponent],
  providers: [SectionService],
})
export default class CalculatorModule {
  public static component = CalculatorComponent;
}
