import { Component, Input, Optional } from '@angular/core';

import { DataLayerService } from '@/services/data-layer.service';

import { IQuicklink } from './quicklink.types';
import { SectionService } from '@/services/section.service';
import { PlatformService } from '../../../services/platform.service';
import { AmplitudeExperiment } from '../../../common/types/data-layer';
import { VariantContainerComponent } from '../../sections/variant-container/variant-container.component';

@Component({
  selector: 'nx-quicklink',
  templateUrl: './quicklink.component.html',
  styleUrls: ['./quicklink.component.scss'],
})
export class QuicklinkComponent implements IQuicklink {
  constructor(
    private dataLayer: DataLayerService,
    private section: SectionService,
    public platform: PlatformService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  // REQUIRED INPUTS
  @Input() data!: IQuicklink['data'];

  // METHODS
  pushClick() {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    this.dataLayer.push(
      this.data.dataLayer,
      this.section.sectionName,
      experiment
    );
  }
}
