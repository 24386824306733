<!-- Vertical Extra Large Card -->
<a
  *ngIf="data.isVertical"
  [nxAbsoluteAnchor]="data.url!"
  [routerLink]="platform.getRelativeUrl(data.url!)"
  class="vertical_extra-large-card"
  [ngClass]="{
    'vertical_card-without-description': !data.text,
    'card-with-url': data.url
  }"
  (click)="pushClick()"
>
  <div class="thumbnail-title-container">
    <h2
      class="title equis-h1-semibold"
      [ngClass]="{
        'vertical_title-without-description': !data.text,
        'equis-h2-medium': !data.text
      }"
      [ngStyle]="{ order: data.showTitleBelowThumbnail ? '1' : null }"
    >
      {{ data.title }}
    </h2>
    <div
      class="vertical_image-wrapper"
      [ngClass]="{
        'vertical_image-wrapper-without-description': !data.text
      }"
    >
      <picture [nxContentfulImage]="data.image">
        <source />
        <source />
        <source />
        <img loading="lazy" />
      </picture>
    </div>
  </div>

  <div class="vertical_text-wrapper">
    <nx-rich-text-renderer
      *ngIf="!!data.text"
      [text]="data.text"
    ></nx-rich-text-renderer>
    <nx-component-injector
      *ngIf="data.cta"
      [component]="data.cta"
    ></nx-component-injector>
  </div>
</a>

<!-- Horizontal Extra Large Card -->
<div
  class="horizontal_extra-large-card"
  [ngClass]="{
    'horizontal_card-without-description': !data.text,
    'flip-right': !isLeft
  }"
  *ngIf="!data.isVertical"
  (click)="pushClick()"
>
  <div
    class="horizontal_image-wrapper"
    [ngClass]="{
      'horizontal_image-wrapper-without-description': !data.text
    }"
  >
    <picture [nxContentfulImage]="data.image">
      <source />
      <source />
      <img loading="lazy" />
    </picture>
  </div>
  <div class="horizontal_text-wrapper">
    <div class="icon-and-title">
      <picture *ngIf="data.icon" [nxContentfulImage]="data.icon">
        <source />
        <source />
        <img loading="lazy" />
      </picture>
      <h2
        class="title equis-h1-semibold"
        [ngClass]="{
          'horizontal_title-without-description': !data.text,
          'equis-h2-medium': !data.text
        }"
      >
        {{ data.title }}
      </h2>
    </div>
    <h3
      *ngIf="data.subtitle"
      [style.color]="data.subtitleColor?.value || '#FE5000'"
      class="equis-body-1-medium subtitle"
    >
      {{ data.subtitle }}
    </h3>

    <nx-rich-text-renderer
      *ngIf="!!data.text"
      [text]="data.text"
    ></nx-rich-text-renderer>
    <nx-component-injector
      *ngIf="data.cta"
      [component]="data.cta"
    ></nx-component-injector>
  </div>
</div>
