import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MapAnchorPoint, MapInfoWindow } from '@angular/google-maps';

import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/internal/operators/catchError';
import { Observable } from 'rxjs/internal/Observable';
import { PlatformService } from '../../../services/platform.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { IEnviroment } from '../../../common/types/enviroment';
import { IBranch } from './branches-map.types';

const enviromentState = makeStateKey<IEnviroment>('enviroment');

@Injectable()
export class GoogleMapService {
  private GMAPS_API_KEY!: string;
  constructor(
    private http: HttpClient,
    private platform: PlatformService,
    private readonly transferState: TransferState,
    @Optional()
    @Inject('enviromentFromVault')
    public enviroment: IEnviroment
  ) {
    this.GMAPS_API_KEY = this.transferState.get(
      enviromentState,
      null
    )!.GMAPS_API_KEY;
  }

  // PROPERTIES
  public infoWindow = {
    locality: '',
    address: '',
  };
  public apiLoaded!: Observable<boolean>;
  public readonly MAP_OPTIONS: google.maps.MapOptions = {
    // Argentina coords
    center: {
      lat: -38.416097,
      lng: -63.616672,
    },
    zoom: 4,
  };
  public readonly MARKER_OPTIONS: google.maps.MarkerOptions = {
    draggable: false,
    icon: 'assets/images/icons/map-icon.svg',
  };
  public branchesCoordinates!: google.maps.LatLngLiteral[];

  // METHODS
  /**
   * Downloads the Google Maps API script and stores it into an Observable
   * For more information about the Google Maps API/Component, check: https://github.com/angular/components/blob/main/src/google-maps/README.md
   */
  public loadAPI(): void {
    if (this.platform.isServer) return;
    // Check if the Google Maps API is already loaded
    if (window?.google && window.google?.maps) {
      this.apiLoaded = of(true);
    } else {
      // Load the Google Maps API
      this.apiLoaded = this.http
        .jsonp(
          `https://maps.googleapis.com/maps/api/js?key=${this.GMAPS_API_KEY}`,
          'callback'
        )
        .pipe(
          map(() => true),
          catchError(() => of(false))
        );
    }
  }

  /**
   * Generates an array of coordinates from the branches
   * @param branches Branches to be rendered as a marker
   * @returns Array of coordinates
   */
  public generateCoordinates(branches: IBranch[]): void {
    this.branchesCoordinates = branches.map((b) =>
      this.getBranchCoordinates(b)
    );
  }

  /**
   * Opens the InfoWindow element, displaying the branch's locality and address
   * @param infoWindow InfoWindow element to be opened
   * @param marker Marker that triggers the InfoWindow event
   * @param branch Branch associated with the marker
   */
  public openInfoWindow(
    infoWindow: MapInfoWindow,
    marker: MapAnchorPoint,
    branch: IBranch
  ) {
    // Store the branch's data to be displayed in the InfoWindow
    this.infoWindow = {
      locality: branch.address.city,
      address: branch.address.full_address,
    };
    infoWindow.open(marker);
  }

  /**
   * Gets the coordinates from a branch object
   * @param branch Branch to get the coordinates from
   * @returns Object with the branch's coordinates
   */
  public getBranchCoordinates(branch: IBranch): google.maps.LatLngLiteral {
    return {
      lat: Number(branch.geolocation.latitude),
      lng: Number(branch.geolocation.longitude),
    };
  }
}
