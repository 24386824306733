<p *ngIf="data.subtitle" class="equis-h1-semibold subtitle">
  {{ data.subtitle }}
</p>
<ul
  class="components-list"
  [class.three-columns]="data.columns === '3'"
  [class.two-columns]="data.columns === '2'"
  [class.one-column]="data.columns === '1'"
  [ngClass]="{ countdown_cards: isCountdownStyle }"
>
  <li class="list-item" *ngFor="let card of filteredComponents; let i = index">
    <ng-container *ngIf="isCardSmallOrMedium(card)">
      <nx-card [data]="card.fields"></nx-card>
    </ng-container>

    <ng-container *ngIf="isCardLarge(card)">
      <nx-card-large [data]="card.fields"></nx-card-large>
    </ng-container>

    <ng-container *ngIf="isCardXl(card)">
      <nx-card-xl [data]="card.fields"></nx-card-xl>
    </ng-container>

    <ng-container *ngIf="isCardVideo(card)">
      <nx-card-video
        [data]="card.fields"
        [id]="'video' + i.toString()"
      ></nx-card-video>
    </ng-container>

    <ng-container *ngIf="isCardCountdown(card)">
      <nx-card-countdown
        [data]="card.fields"
        [entryId]="card.sys.id!"
      ></nx-card-countdown>
    </ng-container>
  </li>
</ul>
<equis-paginator-big
  *ngIf="!platform.isServer && data.componentsPerPage"
  [currentPage]="1"
  [totalItems]="pages"
  (changePage)="changePageEvent($event)"
></equis-paginator-big>
