import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import LinkModule from '@/atoms/link/link.module';
import { ComponentInjectorModule } from '@/tooling/component-injector/component-injector.module';
import { RichTextRendererModule } from '@/tooling/rich-text-renderer/rich-text-renderer.module';
import { DirectivesModule } from '@/directives/directives.module';

import { CardXlComponent } from './card-xl.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CardXlComponent],
  imports: [
    CommonModule,
    LinkModule,
    DirectivesModule,
    ComponentInjectorModule,
    RichTextRendererModule,
    RouterModule
  ],
  exports: [CardXlComponent],
})
export default class CardXlModule {
  public static component = CardXlComponent;
}
