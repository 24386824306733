import { Component, Input } from '@angular/core';

import { SectionService } from '@/services/section.service';

import { IFooter } from './footer.types';

@Component({
  selector: 'nx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [SectionService],
})
export class FooterComponent implements IFooter {
  constructor(section: SectionService) {
    section.sectionName = 'footer';
  }

  @Input() data!: IFooter['data'];
}
