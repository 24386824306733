import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[nxNumbersOnly]',
})
export class NumbersOnlyDirective {
  constructor(private _el: ElementRef) {}

  @HostListener('keydown', ['$event']) onInputChange(event: any) {
    const input = this._el.nativeElement as HTMLInputElement;
    const value = input.value;
    input.type = 'number';

    const allowedKeys = [
      'Backspace',
      'Tab',
      'End',
      'Home',
      'ArrowLeft',
      'ArrowRight',
      'Delete',
    ];

    // Allow navigation keys, backspace, etc.
    if (
      allowedKeys.includes(event.key) ||
      (event.ctrlKey && ['a', 'c', 'v', 'x'].includes(event.key.toLowerCase()))
    ) {
      return;
    }

    // Prevent entering leading zero
    if (value.length === 0 && event.key === '0') {
      event.preventDefault();
    }

    // Prevent entering negatives numbers
    if (!/^[1-9]\d*$/.test(value + event.key)) {
      event.preventDefault();
    }
  }
}
