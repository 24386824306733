import { Component, Input, OnInit } from '@angular/core';

import { ActiveTabService } from '../active-tab.service';
import { INavbarBottom } from './navbar-bottom.types';
import { PlatformService } from '../../../../services/platform.service';

@Component({
  selector: 'nx-navbar-bottom',
  templateUrl: './navbar-bottom.component.html',
  styleUrls: ['./navbar-bottom.component.scss'],
})
export class NavbarBottomComponent implements INavbarBottom, OnInit {
  constructor(
    public platform: PlatformService,
    private activeTabService: ActiveTabService
  ) {}

  @Input() data!: INavbarBottom['data'];

  isDropdownActive!: boolean;
  tabContent!: INavbarBottom['tabContent'];

  handleDropdownActiveValue(isDropdownActive: boolean) {
    this.isDropdownActive = isDropdownActive;
  }

  ngOnInit() {
    this.tabContent = this.activeTabService.activeTab.fields.tabContent;
  }
}
