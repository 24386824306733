import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquisIconContainerModule } from '@tn-equis/core/components/icon-container';
import { EquisAccordionModule } from '@tn-equis/core/components/accordion';
import { EquisDropdownModule } from '@tn-equis/core/components/dropdown';

import ButtonDownloadAppModule from '@/atoms/button-download-app/button-download-app.module';
import LinkModule from '@/atoms/link/link.module';
import { DirectivesModule } from '@/directives/directives.module';

import { NavbarComponent } from './navbar.component';
import { NavbarTopComponent } from './navbar-top/navbar-top.component';
import { NavbarBottomComponent } from './navbar-bottom/navbar-bottom.component';
import { NavbarTabsComponent } from './navbar-top/navbar-tabs/navbar-tabs.component';
import { NavbarTabContentComponent } from './navbar-bottom/navbar-tab-content/navbar-tab-content.component';
import { NavbarTabComponent } from './navbar-top/navbar-tabs/navbar-tab/navbar-tab.component';
import { NavbarTabContentItemComponent } from './navbar-bottom/navbar-tab-content/navbar-tab-content-item/navbar-tab-content-item.component';

import { ActiveTabService } from './active-tab.service';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '../../atoms/button/button.component';
import { ComponentInjectorModule } from '../../tooling/component-injector/component-injector.module';

@NgModule({
  declarations: [
    NavbarComponent,
    NavbarTopComponent,
    NavbarBottomComponent,
    NavbarTabsComponent,
    NavbarTabContentComponent,
    NavbarTabComponent,
    NavbarTabContentItemComponent,
  ],
  imports: [
    CommonModule,
    ButtonComponent,
    EquisIconContainerModule,
    EquisAccordionModule,
    EquisDropdownModule,
    ButtonDownloadAppModule,
    DirectivesModule,
    LinkModule,
    RouterModule,
    ComponentInjectorModule,
  ],
  exports: [NavbarComponent],
  providers: [ActiveTabService],
})
export default class NavbarModule {
  public static component = NavbarComponent;
}
