import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '@/directives/directives.module';
import { ClickeableContainerModule } from '@/tooling/clickeable-container/clickeable-container.module';

import { CardComponent } from './card.component';
import { CardMediumComponent } from './medium/card-medium.component';
import { CardSmallComponent } from './small/card-small.component';

@NgModule({
  declarations: [CardComponent, CardMediumComponent, CardSmallComponent],
  imports: [CommonModule, DirectivesModule, ClickeableContainerModule],
  exports: [CardComponent],
})
export default class CardModule {
  public static component = CardComponent;
}
