<section
  [style.backgroundColor]="data.backgroundColor?.value || '#FEE4D9'"
  *ngIf="!data.cards"
  [class]="paddingClass"
>
  <div class="container">
    <nx-capsule-x
      [data]="capsuleXContent.data"
      [isMaskConvex]="true"
    ></nx-capsule-x>
  </div>
  <span
    class="background-text first-line"
    [style]="backgroundTextColor"
    [style.color]="backgroundColor"
    >{{ backgroundText }}</span
  >
  <span
    class="background-text second-line"
    [style]="backgroundTextColor"
    [style.color]="backgroundColor"
    >{{ backgroundText }}</span
  >
  <span
    class="background-text third-line"
    [style]="backgroundTextColor"
    [style.color]="backgroundColor"
    >{{ backgroundText }}</span
  >
  <span
    class="background-text fourth-line"
    [style]="backgroundTextColor"
    [style.color]="backgroundColor"
    >{{ backgroundText }}</span
  >
</section>

<section
  class="capsule-x-with-cards"
  *ngIf="data.cards"
  [style.backgroundColor]="data.backgroundColor?.value || '#FEE4D9'"
>
  <div class="container">
    <nx-capsule-x
      [data]="capsuleXContent.data"
      [isMaskConvex]="true"
    ></nx-capsule-x>

    <ul class="cards-list">
      <li *ngFor="let card of data.cards">
        <nx-card [data]="card.fields"></nx-card>
      </li>
    </ul>
  </div>
  <span
    class="background-text first-line"
    [style]="backgroundTextColor"
    [style.color]="backgroundColor"
    >{{ backgroundText }}</span
  >
  <span
    class="background-text second-line"
    [style]="backgroundTextColor"
    [style.color]="backgroundColor"
    >{{ backgroundText }}</span
  >
  <span
    class="background-text third-line"
    [style]="backgroundTextColor"
    [style.color]="backgroundColor"
    >{{ backgroundText }}</span
  >
  <span
    class="background-text fourth-line"
    [style]="backgroundTextColor"
    [style.color]="backgroundColor"
    >{{ backgroundText }}</span
  >
</section>
