import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadingRendererComponent } from './heading-renderer.component';

@NgModule({
  declarations: [HeadingRendererComponent],
  imports: [CommonModule],
  exports: [HeadingRendererComponent],
})
export class HeadingRendererModule {
  public static component = HeadingRendererComponent;
}
