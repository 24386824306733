import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlaceholderWipComponent } from './placeholder-wip.component';

@NgModule({
  declarations: [PlaceholderWipComponent],
  imports: [CommonModule],
  exports: [PlaceholderWipComponent],
})
export default class PlaceholderWipModule {
  public static component = PlaceholderWipComponent;
}
