<div class="capsule-x" [class.image-position]="imageDirection">
  <nx-text-content
    [class.no-padding-right]="!imageDirection && maskType === 'concave-mask'"
    [class.no-padding-left]="imageDirection && maskType === 'concave-mask'"
    [title]="data.textContent.title"
    [text]="data.textContent.text"
    [titleHierarchy]="data.textContent.titleHierarchy"
    [cta]="data.textContent.cta!"
  ></nx-text-content>
  <div [class]="maskType + ' ' + maskDirection">
    <!-- DESKTOP -->
    <picture class="desktop-content" [nxContentfulImage]="data.image">
      <source />
      <source />
      <source />
      <img loading="lazy" />
    </picture>

    <!-- MOBILE -->
    <picture class="mobile-content" [nxContentfulImage]="data.image">
      <source />
      <source />
      <source />
      <img loading="lazy" />
    </picture>
  </div>
</div>
