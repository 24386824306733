<app-button
  *ngIf="!data.isFloating; else floating"
  [type]="buttonType"
  [text]="text"
  [type]="buttonStyle"
  [size]="size"
  (click)="onClick()"
  [width]="buttonWidth"
></app-button>

<!-- FLOATING BUTTON / MOBILE ONLY -->
<ng-template #floating>
  <!-- The button is hidden by default -->
  <div class="floating fade hide" #floatingDiv *ngIf="showButtonSticky">
    <app-button
      [text]="text"
      width="100%"
      (click)="onClick()"
    ></app-button>
  </div>
</ng-template>
