import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
const DEFAULT_TIME_IN_SECONDS = 45;

@Component({
  selector: 'nx-timer-button',
  templateUrl: './timer-button.component.html',
})
export class TimerButtonComponent implements OnInit {
  @Input() title!: string;
  @Input() loading: boolean = false;
  @Input() titleTimeout!: string;
  @Output() buttonClick = new EventEmitter<void>();
  timeInSeconds = DEFAULT_TIME_IN_SECONDS;
  displayTime: string = '';
  private interval: any;

  ngOnInit(): void {
    this.startTimer();
  }

  startTimer(): void {
    this.interval = setInterval(() => {
      this.updateDisplayTime();
      this.timeInSeconds--;

      if (this.timeInSeconds < 0) {
        if (!this.isResetTriggered()) {
          clearInterval(this.interval);
        }
      }
    }, 1000);
  }

  isResetTriggered(): boolean {
    return this.timeInSeconds === DEFAULT_TIME_IN_SECONDS;
  }

  resetTimer(): void {
    clearInterval(this.interval);
    this.timeInSeconds = DEFAULT_TIME_IN_SECONDS;
    this.startTimer();
  }

  updateDisplayTime(): void {
    const minutes = Math.floor(this.timeInSeconds / 60);
    const seconds = this.timeInSeconds % 60;

    const time = `${this.formatTime(minutes)}:${this.formatTime(seconds)}`;
    const timeout = this.timeInSeconds <= 0;
    this.displayTime = `${timeout ? this.titleTimeout : this.title} ${timeout ? '' : time}`;
  }

  formatTime(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  handleButtonClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.buttonClick.emit();
  }
}
