import { Color } from '@/types/contentful';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ColorContrastService {
  calculateRelativeLuminance(hexColor: string): number {
    const R = parseInt(hexColor.slice(1, 3), 16) / 255;
    const G = parseInt(hexColor.slice(3, 5), 16) / 255;
    const B = parseInt(hexColor.slice(5, 7), 16) / 255;

    const R_linear =
      R <= 0.03928 ? R / 12.92 : Math.pow((R + 0.055) / 1.055, 2.4);
    const G_linear =
      G <= 0.03928 ? G / 12.92 : Math.pow((G + 0.055) / 1.055, 2.4);
    const B_linear =
      B <= 0.03928 ? B / 12.92 : Math.pow((B + 0.055) / 1.055, 2.4);

    return 0.2126 * R_linear + 0.7152 * G_linear + 0.0722 * B_linear;
  }

  extractHexColorCode(color: Color): string {
    // Assuming the value is in the format "#RRGGBB"
    return color.value;
  }

  decideTextColor(color: Color | undefined): 'black' | 'white' {
    // Set default color when color is undefined
    if (!color) return 'black';

    const hexColor = this.extractHexColorCode(color);
    const luminance = this.calculateRelativeLuminance(hexColor);

    // Determine text color based on luminosity
    return luminance > 0.5 ? 'black' : 'white';
  }
}
