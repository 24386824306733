<!-- CARD's CONTENT -->
<ng-template #cardContentVertical>
  <div
    class="vertical_image-wrapper"
    [ngClass]="{
      'vertical_image-wrapper-without-description': !data.description
    }"
  >
    <picture [nxContentfulImage]="data.image">
      <source />
      <source />
      <source />
      <img loading="lazy" />
    </picture>
  </div>
  <div
    class="vertical_text-wrapper"
    [ngClass]="{
      'vertical_text-wrapper-without-description': !data.description
    }"
  >
    <container-element [ngSwitch]="data.heading">
      <ng-container *ngSwitchCase="'h1'"
        ><h1
          class="title equis-h2-medium"
          [ngClass]="{
            'vertical_title-without-description': !data.description,
            'equis-h3-medium': !data.description
          }"
        >
          {{ data.title }}
        </h1></ng-container
      >
      <ng-container *ngSwitchCase="'h2'"
        ><h2
          class="title equis-h2-medium"
          [ngClass]="{
            'vertical_title-without-description': !data.description,
            'equis-h3-medium': !data.description
          }"
        >
          {{ data.title }}
        </h2></ng-container
      >
      <ng-container *ngSwitchCase="'h3'"
        ><h3
          class="title equis-h2-medium"
          [ngClass]="{
            'vertical_title-without-description': !data.description,
            'equis-h3-medium': !data.description
          }"
        >
          {{ data.title }}
        </h3></ng-container
      >
      <ng-container *ngSwitchCase="'h4'"
        ><h4
          class="title equis-h2-medium"
          [ngClass]="{
            'vertical_title-without-description': !data.description,
            'equis-h3-medium': !data.description
          }"
        >
          {{ data.title }}
        </h4></ng-container
      >
      <ng-container *ngSwitchCase="'h5'"
        ><h5
          class="title equis-h2-medium"
          [ngClass]="{
            'vertical_title-without-description': !data.description,
            'equis-h3-medium': !data.description
          }"
        >
          {{ data.title }}
        </h5></ng-container
      >
      <ng-container *ngSwitchCase="'h6'"
        ><h6
          class="title equis-h2-medium"
          [ngClass]="{
            'vertical_title-without-description': !data.description,
            'equis-h3-medium': !data.description
          }"
        >
          {{ data.title }}
        </h6></ng-container
      >
      <ng-container *ngSwitchDefault
        ><h2
          class="title equis-h2-medium"
          [ngClass]="{
            'vertical_title-without-description': !data.description,
            'equis-h3-medium': !data.description
          }"
        >
          {{ data.title }}
        </h2></ng-container
      >
    </container-element>
    <p
      class="description equis-body-1"
      [ngClass]="{
        'description-for-slider': data.isInsideSlider
      }"
    >
      {{ data.description }}
    </p>
  </div>
</ng-template>
<ng-template #cardContentHorizontal>
  <div
    class="horizontal_image-wrapper"
    [ngClass]="{
      'horizontal_image-wrapper-without-description': !data.description
    }"
  >
    <picture [nxContentfulImage]="data.image">
      <source />
      <source />
      <source />
      <img loading="lazy" />
    </picture>
  </div>
  <div
    class="horizontal_text-wrapper"
    [ngClass]="{
      'horizontal_text-wrapper-without-description': !data.description
    }"
  >
    <container-element [ngSwitch]="data.heading">
      <ng-container *ngSwitchCase="'h1'"
        ><h1
          class="title equis-h2-medium"
          [ngClass]="{
            'horizontal_title-without-description': !data.description
          }"
        >
          {{ data.title }}
        </h1></ng-container
      >
      <ng-container *ngSwitchCase="'h2'"
        ><h2
          class="title equis-h2-medium"
          [ngClass]="{
            'horizontal_title-without-description': !data.description
          }"
        >
          {{ data.title }}
        </h2></ng-container
      >
      <ng-container *ngSwitchCase="'h3'"
        ><h3
          class="title equis-h2-medium"
          [ngClass]="{
            'horizontal_title-without-description': !data.description
          }"
        >
          {{ data.title }}
        </h3></ng-container
      >
      <ng-container *ngSwitchCase="'h4'"
        ><h4
          class="title equis-h2-medium"
          [ngClass]="{
            'horizontal_title-without-description': !data.description
          }"
        >
          {{ data.title }}
        </h4></ng-container
      >
      <ng-container *ngSwitchCase="'h5'"
        ><h5
          class="title equis-h2-medium"
          [ngClass]="{
            'horizontal_title-without-description': !data.description
          }"
        >
          {{ data.title }}
        </h5></ng-container
      >
      <ng-container *ngSwitchCase="'h6'"
        ><h6
          class="title equis-h2-medium"
          [ngClass]="{
            'horizontal_title-without-description': !data.description
          }"
        >
          {{ data.title }}
        </h6></ng-container
      >
      <ng-container *ngSwitchDefault>
        <h2
          class="title equis-h2-medium"
          [ngClass]="{
            'horizontal_title-without-description': !data.description
          }"
        >
          {{ data.title }}
        </h2></ng-container
      >
    </container-element>

    <p class="description equis-body-1" *ngIf="data.description">
      {{ data.description }}
    </p>
  </div>
</ng-template>

<nx-clickeable-container [url]="data.url">
  <div [ngClass]="directionalClass">
    <!-- HORIZONTAL -->
    <ng-container *ngIf="data.isVertical">
      <ng-container *ngTemplateOutlet="cardContentVertical"></ng-container>
    </ng-container>
    <!-- VERTICAL -->
    <ng-container *ngIf="!data.isVertical">
      <ng-container *ngTemplateOutlet="cardContentHorizontal"></ng-container>
    </ng-container>
  </div>
</nx-clickeable-container>
