<section [style.backgroundColor]="data.backgroundColor?.value" [class]="paddingClass">
  <div class="container">
    <div class="text">
      <nx-heading-renderer
        class="equis-hero-semibold"
        [data]="data.titleHierarchy"
        [color]="textColor"
        >{{ data.title }}</nx-heading-renderer
      >
      <p
        *ngIf="data.description"
        class="equis-body-1"
        [ngClass]="{
          'black-text': textColor === 'black',
          'white-text': textColor === 'white'
        }"
      >
        {{ data.description }}
      </p>
    </div>
    <ul class="cards-list">
      <li *ngFor="let cards of data.components">
        <nx-group-of-cards [data]="cards.fields" [id]="cards.sys.id!"></nx-group-of-cards>
      </li>
    </ul>
  </div>
</section>
