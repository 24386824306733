import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';

import { NX23Link, NX23NavbarDropdown } from '@/types/content-types';
import { ChatbotService } from '@/services/chatbot.service';
import { DataLayerService } from '@/services/data-layer.service';
import { PlatformService } from '@/services/platform.service';

import { INavbarTabContent } from './navbar-tab-content.types';
import { AmplitudeExperiment } from '../../../../../common/types/data-layer';
import { VariantContainerComponent } from '../../../variant-container/variant-container.component';

@Component({
  selector: 'nx-navbar-tab-content',
  templateUrl: './navbar-tab-content.component.html',
  styleUrls: ['./navbar-tab-content.component.scss'],
})
export class NavbarTabContentComponent implements INavbarTabContent, OnInit {
  constructor(
    private chatbot: ChatbotService,
    private dataLayer: DataLayerService,
    public platform: PlatformService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  // INPUTS
  @Input() data!: INavbarTabContent['data'];
  @Input() tabContent!: INavbarTabContent['tabContent'] | any;

  // OTUPUT
  @Output()
  isDropdownActiveChange: INavbarTabContent['isDropdownActiveChange'] =
    new EventEmitter();

  // PROPERTIES
  isDropdownActive: boolean = false;
  homeHref!: string;

  // HANDLERS
  showChatbot() {
    this.chatbot.show();
    const experiment = this.getAmplitudeExperiment();
    this.dataLayer.push(
      this.chatbot.getDataLayer('chat'),
      'preheader',
      experiment
    );
  }

  passDropdownActiveValue(isDropdownActive: boolean) {
    this.isDropdownActiveChange.emit(isDropdownActive);
  }

  isLink(item: any): item is NX23Link {
    return item.fields.dropdownContent === undefined;
  }

  isDropdown(item: any): item is NX23NavbarDropdown {
    return item.fields.dropdownContent !== undefined;
  }

  // DATA LAYER HANDLERS
  pushMobileHome() {
    const experiment = this.getAmplitudeExperiment();
    this.dataLayer.push(
      {
        eventName: 'ui_interaction',
        eventParams: {
          element: 'inicio',
          action: 'click',
          link_type: 'inbound link',
          referral: '/',
        },
      },
      'header',
      experiment
    );
  }

  onClickMobile(item?: NX23Link | NX23NavbarDropdown) {
    const experiment = this.getAmplitudeExperiment();
    if (item) this.dataLayer.push(item.fields.dataLayer, 'header', experiment);
  }

  pushExpand(content: INavbarTabContent['tabContent'][number]) {
    const experiment = this.getAmplitudeExperiment();
    this.dataLayer.push(content.fields.dataLayer, 'header', experiment);
  }
  pushDropdownLink(content: NX23Link) {
    const experiment = this.getAmplitudeExperiment();
    this.dataLayer.push(content.fields.dataLayer, 'header', experiment);
  }
  pushHelpExpand() {
    const experiment = this.getAmplitudeExperiment();
    this.dataLayer.push(
      {
        eventName: 'ui_interaction',
        eventParams: {
          action: 'expand',
          element: 'Ayuda',
          section: 'header',
        },
      },
      'header',
      experiment
    );
  }

  // HOOKS
  ngOnInit(): void {
    this.homeHref = this.platform.getAbsoluteURL('/');
  }

  /**
   * Get amplitude experiment to send to gtm if it exists
   */
  private getAmplitudeExperiment = (): AmplitudeExperiment => {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }
    return experiment;
  };
}
