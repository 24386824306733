import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  EquisControlMessageStatus,
  IEquisControlMessageBullet,
} from '@tn-equis/core/components/control-message';
import { TimerButtonComponent } from './timer-button/timer-button.component';
import {
  IEquisSnackbarData,
  EquisSnackbarService,
} from '@tn-equis/core/components/snackbar';
import { DeleteAccountService } from '../delete-account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OptCodeFormData } from '../models';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs/internal/Subscription';
import { first } from 'rxjs/internal/operators/first';
import { catchError } from 'rxjs/internal/operators/catchError';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { IEnviroment } from '../../../common/types/enviroment';
import { PlatformService } from '../../../services/platform.service';
declare const grecaptcha: any;

const enviromentState = makeStateKey<IEnviroment>('enviroment');

@Component({
  selector: 'nx-otp-code-form',
  templateUrl: './otp-code-form.component.html',
  styleUrls: ['./otp-code-form.component.scss'],
})
export class OtpCodeFormComponent implements OnInit {
  otpCodeForm!: FormGroup;
  @ViewChild(TimerButtonComponent) timerButton!: TimerButtonComponent;
  @Input() email!: string;
  @Output() formSubmitSuccess = new EventEmitter<void>();
  @Output() formSubmitError = new EventEmitter<HttpErrorResponse | Error>();
  @Output() resendError = new EventEmitter<HttpErrorResponse | Error>();
  @Output() goBackEvent = new EventEmitter<void>();
  public eventSubject: Subject<string> = new Subject();
  public eventSubscription!: Subscription;
  codeRequired: IEquisControlMessageBullet[] = [
    {
      text: 'El código es requerido',
      status: EquisControlMessageStatus.ERROR,
    },
  ];
  codeInvalid: IEquisControlMessageBullet[] = [
    {
      text: 'El código que ingresaste no es válido o se venció.',
      status: EquisControlMessageStatus.ERROR,
    },
  ];
  codeLength: IEquisControlMessageBullet[] = [
    {
      text: 'El código debe ser de 6 caracteres.',
      status: EquisControlMessageStatus.ERROR,
    },
  ];
  invalidCode = false;
  formLoading = false;
  resendLoading = false;

  get code() {
    return this.otpCodeForm.get('code');
  }

  private RECAPTCHA_API_KEY!: string;

  constructor(
    private formBuilder: FormBuilder,
    private snackService: EquisSnackbarService,
    private apiService: DeleteAccountService,
    private platform: PlatformService,
    private readonly transferState: TransferState,
    @Optional()
    @Inject('enviromentFromVault')
    public enviroment: IEnviroment
  ) {
    if (this.platform.isServer) {
      this.transferState.set(enviromentState, this.enviroment);
    } else {
      this.RECAPTCHA_API_KEY = this.transferState.get(
        enviromentState,
        null
      )!.RECAPTCHA_API_KEY;
    }
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.otpCodeForm = this.formBuilder.group({
      code: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
    });
  }

  async onSubmit() {
    if (this.otpCodeForm.valid) {
      this.formLoading = true;
      let token = '';
      try {
        token = await grecaptcha.enterprise.execute(this.RECAPTCHA_API_KEY, {
          action: 'baja_cuenta',
        });
        this.onValidate(this.otpCodeForm.value, token);
      } catch (error) {
        this.formLoading = false;
        this.formSubmitError.emit(
          new Error(
            `'Error al obtener token de recaptcha', ${JSON.stringify(error)}`
          )
        );
      }
    }
  }

  onValidate(data: OptCodeFormData, token: string) {
    this.invalidCode = false;
    if (data && data.code && /^\d+$/.test(data.code)) {
      this.formLoading = true;
      this.apiService
        .validate({
          email: this.email,
          code: parseInt(data.code, 10),
          'g-recaptcha-response': token,
        })
        .pipe(
          first(),
          catchError((error: HttpErrorResponse) => {
            this.formLoading = false;
            if (error.status === 400) {
              this.invalidCode = true;
            } else {
              this.formSubmitError.emit(error);
            }
            throw error;
          })
        )
        .subscribe(() => {
          this.formSubmitSuccess.emit();
        });
    } else {
      this.invalidCode = true;
    }
  }

  goBack() {
    this.goBackEvent.emit();
  }

  async resendCode() {
    this.resendLoading = true;
    let token = '';
    try {
      token = await grecaptcha.enterprise.execute(this.RECAPTCHA_API_KEY, {
        action: 'baja_cuenta',
      });
      this.apiService
        .send({ email: this.email, 'g-recaptcha-response': token })
        .pipe(
          first(),
          catchError((error: HttpErrorResponse) => {
            this.resendLoading = false;
            this.resendError.emit(error);
            throw error;
          })
        )
        .subscribe(() => {
          this.resendLoading = false;
          const dataSnackbar: IEquisSnackbarData = {
            duration: 5,
            text: 'Enviamos un nuevo código a tu email',
            onEvent: this.eventSubject,
            hasDuration: true,
          };

          this.snackService.showSnackbar(dataSnackbar, this.eventSubject);
          this.timerButton.resetTimer();
        });
    } catch (error) {
      this.resendLoading = false;
      this.resendError.emit(
        new Error(
          `'Error al obtener token de recaptcha', ${JSON.stringify(error)}`
        )
      );
    }
  }
}
