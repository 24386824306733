import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
} from '@angular/core';

import type { ISitewide } from './sitewide.types';
import { DOCUMENT } from '@angular/common';
import { PlatformService } from '../../../services/platform.service';
import { DataLayerService } from '../../../services/data-layer.service';
import { SectionService } from '../../../services/section.service';
import { AmplitudeExperiment } from '../../../common/types/data-layer';
import { VariantContainerComponent } from '../variant-container/variant-container.component';

@Component({
  selector: 'nx-sitewide',
  templateUrl: './sitewide.component.html',
  styleUrls: ['./sitewide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SitewideComponent implements ISitewide, OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private platform: PlatformService,
    private dataLayer: DataLayerService,
    private section: SectionService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  @Input() data!: ISitewide['data'];

  public showBanner: boolean = true;
  public contentAlignment!: string;

  ngOnInit(): void {
    if (this.platform.isServer) return;

    this.contentAlignment = this.data.alignLeft ? 'flex-start' : 'center';
    const bannerState = sessionStorage.getItem('bannerState');
    if (bannerState) {
      this.showBanner = false;
    }
  }

  close() {
    this.showBanner = false;
    sessionStorage.setItem('bannerState', 'closed');

    const navbarBefore = this.document.getElementById('before-navbar');
    const navbarHeight = this.document.getElementById('navbar-height');

    navbarBefore!.style.removeProperty('top');
    navbarHeight!.style.height = `${navbarBefore!.clientHeight}px`;
    this.submitDataLayer();
  }

  submitDataLayer() {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    this.dataLayer.push(
      {
        eventName: 'modal_app',
        eventParams: {
          action: 'click',
          element: 'cerrar Sitewide Banner',
          section: 'Sitewide Banner',
        },
      },
      this.section.sectionName,
      experiment
    );
  }
}
