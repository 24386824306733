import { environment } from '@/environment';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { IEquisSelect } from '@tn-equis/core/components/select';
import { IPaginatorBigData } from '@tn-equis/core/components/paginator-big';
import {
  IEquisChipGroupChip as IChip,
  IEquisChipGroupEvents as IChipEvent,
} from '@tn-equis/core/components/chip';
import { MapAnchorPoint, MapInfoWindow } from '@angular/google-maps';
import { v4 as uuidv4 } from 'uuid';

import { DataLayerService } from '@/services/data-layer.service';

import type {
  IBranchesMap,
  ChipFilterName,
  IBranch,
} from './branches-map.types';
import { GoogleMapService } from './google-map.service';
import { BranchFilteringService } from './branch-filtering.service';
import { PlatformService } from '@/services/platform.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { VariantContainerComponent } from '../variant-container/variant-container.component';
import { AmplitudeExperiment } from '../../../common/types/data-layer';
import { HttpClient } from '@angular/common/http';
import { take, tap } from 'rxjs';
import { makeStateKey, TransferState } from '@angular/platform-browser';

const branchesState = makeStateKey<IBranch[]>('branches');
@Component({
  selector: 'nx-branches-map',
  templateUrl: './branches-map.component.html',
  styleUrls: ['./branches-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BranchesMapComponent implements IBranchesMap, OnInit {
  constructor(
    public maps: GoogleMapService,
    public platform: PlatformService,
    private filtering: BranchFilteringService,
    private dataLayer: DataLayerService,
    private changeDetector: ChangeDetectorRef,
    private http: HttpClient,
    private readonly transferState: TransferState,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {
    this.maps.loadAPI();
  }

  public localities$ = new BehaviorSubject<IEquisSelect[]>([]);
  public states$ = new BehaviorSubject<IEquisSelect[]>([]);

  // REQUIRED INPUTS
  @Input() data!: IBranchesMap['data'];
  private BRANCHES: IBranch[] = [];

  // CONTENT
  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;

  // PROPERTEIS
  // Current page
  page: number = 1;
  branchesForMap!: (IBranch & { marker: google.maps.LatLngLiteral })[];
  // List of filter chips
  filterChips!: IChip[];
  // Helps the UI to reset the locality select. Works with *ngIf in the template
  localitySelectEnabled = true;

  // PRIVATE PROPERTIES
  // Branch filter set to active initially
  private chipFilterBy: ChipFilterName = 'branch';
  private selectFilterBy = {
    state: this.filtering.DEFAULT_OPTION.text,
    locality: this.filtering.DEFAULT_OPTION.text,
  };
  private readonly BRANCHES_PER_PAGE = 4;
  public MAP_OPTIONS: google.maps.MapOptions = {
    ...this.maps.MAP_OPTIONS,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoom: 4.8,
    minZoom: 4,
  };

  ngOnInit(): void {
    if (this.platform.isServer) {
      this.http
        .get<{ branches: IBranch[] }>(
          `${environment.BRANCHES_URL}/api/branches`,
          {
            headers: {
              consumer_id: 'NXCOM',
              flow_id: 'NXCOM',
              trace_id: uuidv4(),
            },
          }
        )
        .pipe(
          take(1),
          tap(({ branches }) => this.transferState.set(branchesState, branches))
        )
        .subscribe();
      return;
    }

    this.BRANCHES = this.transferState.get(branchesState, []);

    this.filterChips = [
      {
        content: this.data.branchFilter,
        id: 'branch',
        status: 'active',
      },
      {
        content: this.data.terminalFilter,
        id: 'terminal',
        status: 'default',
      },
    ];
    this.states();
    this.branchesForMap = this.generateBranchesForMap();
  }

  /**
   * List of Argentina's state, retrieved from the section's data
   */
  private states() {
    const unique = this.filtering.getUnique(this.BRANCHES, 'state');
    const sorted = this.filtering.sortBy('state', unique);

    const mapped = this.filtering.branchesToSelect(sorted, 'state');
    // return this.filtering.addDefaultOption(mapped);
    this.states$.next(this.filtering.addDefaultOption(mapped));
  }
  /**
   * List of Argentina's localities, depending on the selected state
   */
  private localities() {
    const { state } = this.selectFilterBy;
    // If no state filter, return empty array
    // if (!state) return [];
    if (!state) {
      return this.localities$.next([]);
    }

    // Select only the branches that match the selected state
    const stateFiltered = this.filtering.filterByField(
      'state',
      state,
      this.BRANCHES
    );
    const chipFiltered = this.filtering.filterByChip(
      this.chipFilterBy,
      stateFiltered
    );
    const unique = this.filtering.getUnique(chipFiltered, 'city');
    const sorted = this.filtering.sortBy('city', unique);

    const mapped = this.filtering.branchesToSelect(sorted, 'city');
    // return this.filtering.addDefaultOption(mapped);
    return this.localities$.next(this.filtering.addDefaultOption(mapped));
  }

  /**
   * Calculates the number of pages for the paginator
   */
  get nPages() {
    return Math.ceil(this.filteredBranches.length / this.BRANCHES_PER_PAGE);
  }

  /**
   * Returns the branches to be displayed in the current page
   */
  get paginatedBranches() {
    const start = (this.page - 1) * this.BRANCHES_PER_PAGE;
    const end = start + this.BRANCHES_PER_PAGE;

    return this.filteredBranches.slice(start, end);
  }

  /**KY
   * Filters the list of branches by state, locality and type of branch
   */
  get filteredBranches() {
    // Copy the branches array
    let array = [...this.BRANCHES];
    const { state, locality } = this.selectFilterBy;

    array = this.filtering.filterByField('state', state, array);
    array = this.filtering.filterByField('city', locality, array);
    array = this.filtering.filterByChip(this.chipFilterBy, array);

    return array;
  }

  /**
   * Returns the branches to be displayed in the map, with their coordinates
   */
  generateBranchesForMap(): (IBranch & {
    marker: google.maps.LatLngLiteral;
  })[] {
    return this.filteredBranches.map((b) => ({
      ...b,
      marker: this.maps.getBranchCoordinates(b),
    }));
  }

  // METHODS
  /**
   * Toggles the filter chip
   * @param event Event information from the chip
   */
  toggleFilter({ chip }: IChipEvent) {
    this.chipFilterBy = chip.id as ChipFilterName;
    this.pushChipSelect(chip.id as ChipFilterName);
    this.filterUpdated();
  }

  /**
   * On change event for the paginator
   * @param event Event information from the paginator
   */
  changePage({ currentPage }: IPaginatorBigData) {
    this.page = currentPage;
    this.pushChangePage(currentPage);
  }

  /**
   * On change event for the select
   * @param event Event information from the select
   * @param select Type of select
   */
  selectChange(event: IEquisSelect, select: 'state' | 'locality') {
    this.selectFilterBy[select] = event.text;
    this.pushSelectOption(select, event.text);

    // Reset locality if state is changed
    if (select === 'state') {
      this.reloadLocality();
      this.selectFilterBy.locality = this.filtering.DEFAULT_OPTION.text;
    }
    this.filterUpdated();
    this.localities();
  }

  /**
   * OnClick event for the markers
   * @param marker Marker that triggers the InfoWindow event
   * @param branch Branch associated with the marker
   */
  onMarkerClick(marker: MapAnchorPoint, branch: IBranch) {
    this.maps.openInfoWindow(this.infoWindow, marker, branch);
  }

  // DATALAYER METHODS
  pushClickSelect(type: 'state' | 'locality') {
    const experiment = this.getAmplitudeExperiment();
    this.dataLayer.push(
      {
        eventName: 'form_interaction',
        eventParams: {
          component: this.data.title,
          element: type === 'state' ? 'provincia' : 'localidad',
          action: 'click',
        },
      },
      'filtro mapa',
      experiment
    );
  }
  pushSelectOption(type: 'state' | 'locality', element: string) {
    const experiment = this.getAmplitudeExperiment();
    this.dataLayer.push(
      {
        eventName: 'form_select',
        eventParams: {
          component: this.data.title,
          component2: type === 'state' ? 'provincia' : 'localidad',
          element,
          action: 'select',
        },
      },
      'filtro mapa',
      experiment
    );
  }

  pushChipSelect(chip: ChipFilterName) {
    const experiment = this.getAmplitudeExperiment();
    const chipText =
      this.filterChips.find((c) => c.id === chip)?.content ?? 'sucursales';
    this.dataLayer.push(
      {
        eventName: 'ui_interaction',
        eventParams: {
          component: this.data.title,
          element: chipText,
          action: 'click',
        },
      },
      'filtro mapa',
      experiment
    );
  }

  pushChangePage(page: number) {
    const experiment = this.getAmplitudeExperiment();
    this.dataLayer.push(
      {
        eventName: 'ui_interaction',
        eventParams: {
          component: this.data.title,
          element: `${page}`,
          action: 'click',
        },
      },
      'mapa',
      experiment
    );
  }

  // PRIVATE METHODS
  /**
   * Actions that should be performed when a filter is updated
   */
  private filterUpdated(): void {
    // Reset page
    this.page = 1;
    // Regenerate list of branches to render
    this.branchesForMap = this.generateBranchesForMap();
  }

  /**
   * Get amplitude experiment to send to gtm if it exists
   */
  private getAmplitudeExperiment = (): AmplitudeExperiment => {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }
    return experiment;
  };

  /**
   * Reloads the locality select component by disabling and enabling it using an *ngIf
   */
  private reloadLocality() {
    this.localitySelectEnabled = false;
    this.changeDetector.detectChanges();
    this.localitySelectEnabled = true;
  }
}
