import { Component, Input, Optional } from '@angular/core';

import { DataLayerService } from '@/services/data-layer.service';
import { NX23LegalImage, NX23Link } from '@/types/content-types';
import { SectionService } from '@/services/section.service';

import { IPostFooter } from './post-footer.types';
import { PlatformService } from '../../../../services/platform.service';
import { VariantContainerComponent } from '../../variant-container/variant-container.component';
import { AmplitudeExperiment } from '../../../../common/types/data-layer';

@Component({
  selector: 'nx-post-footer',
  templateUrl: './post-footer.component.html',
  styleUrls: ['./post-footer.component.scss'],
})
export class PostFooterComponent implements IPostFooter {
  constructor(
    private dataLayer: DataLayerService,
    private section: SectionService,
    public platform: PlatformService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  // REQUIRED INPUTS
  @Input() data!: IPostFooter['data'];

  // METHODS
  /**
   * Returns the image source and alt text from the asset
   * @param image Asset object
   * @returns An object with the image source and alt text
   */
  getImage(image: NX23LegalImage) {
    const file = image.fields.image.fields.file;
    if (file && file.details && 'image' in file.details) {
      const height = 64;
      const proportion = height / (file.details.image?.height ?? 1);
      const width = (file.details.image?.width ?? 1) * proportion;
      return {
        src: image.fields.image.fields.file?.url,
        alt: image.fields.image.fields.description,
        href: image.fields.url,
        width,
        height,
      };
    } else return null;
  }

  // DATALAYER METHODS
  pushImage(image: NX23LegalImage) {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }
    this.dataLayer.push(
      image.fields.dataLayer,
      this.section.sectionName,
      experiment
    );
  }
  pushLink(link: NX23Link) {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }
    this.dataLayer.push(
      link.fields.dataLayer,
      this.section.sectionName,
      experiment
    );
  }
}
