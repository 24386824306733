<ng-template #children>
  <ng-content></ng-content>
</ng-template>

<div *ngIf="!isClickeable">
  <ng-container *ngTemplateOutlet="children"></ng-container>
</div>
<a
  *ngIf="isClickeable"
  [routerLink]="platform.getRelativeUrl(url!)"
  [nxAbsoluteAnchor]="url!"
>
  <ng-container *ngTemplateOutlet="children"></ng-container>
</a>
