import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '@/directives/directives.module';
import { ComponentInjectorModule } from '@/tooling/component-injector/component-injector.module';

import { CardLargeComponent } from './card-large.component';
import LinkModule from '../link/link.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CardLargeComponent],
  imports: [
    CommonModule,
    LinkModule,
    DirectivesModule,
    ComponentInjectorModule,
    RouterModule,
  ],
  exports: [CardLargeComponent],
})
export default class CardLargeModule {}
