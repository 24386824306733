import { Component, Inject, Input } from '@angular/core';
import {
  EquisAnchorType,
  EquisButtonModule,
} from '@tn-equis/core/components/button';
import { PlatformService } from '../../../services/platform.service';
import { CommonModule, DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  providers: [PlatformService],
  imports: [EquisButtonModule, CommonModule],
})
export class ButtonComponent {
  constructor(
    public platform: PlatformService,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (!this.platform.isServer) {
      this.document.querySelectorAll('a').forEach((element) => {
        const url = this.platform.getAbsoluteURL(element.href);
        if (!url.includes('www.naranjax.com') && !element?.rel) {
          element.setAttribute('rel', 'nofollow');
        }
      });
    }
  }

  getRelAnchor(href: string) {
    const url = this.platform.getAbsoluteURL(href);
    return !url.includes('www.naranjax.com') ? 'nofollow' : 'dofollow';
  }

  @Input() loading!: boolean;
  @Input() disabled!: boolean;
  @Input() width!: string;
  @Input() text!: string;
  @Input() icon!: string;
  @Input() iconPos!: string;
  @Input() type!: string;
  @Input() size!: string;
  @Input() id!: string;
  @Input() fadeBackground!: string;
  @Input() href!: string;
  @Input() target!: EquisAnchorType;
  @Input() responsive!: boolean;
}
