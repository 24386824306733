import { Component, Input } from '@angular/core';
import {
  documentToHtmlString,
  type Options,
} from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

import { IRichText } from './rich-text.types';

@Component({
  selector: 'nx-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss'],
})
export class RichTextComponent implements IRichText {
  // REQUIRED INPUTS
  @Input() data!: IRichText['data'];

  // PROPERTIES
  /**
   * Transforms the rich text into HTML
   */
  public get html() {
    const options: Options = {
      renderNode: {
        [BLOCKS.HEADING_1]: (node, next) =>
          `<h1 class="equis-h1-semibold">${next(node.content)}</h1>`,
        [BLOCKS.HEADING_2]: (node, next) =>
          `<h2 class="equis-h2-semibold">${next(node.content)}</h2>`,
        [BLOCKS.HEADING_3]: (node, next) =>
          `<h3 class="equis-h3-semibold">${next(node.content)}</h3>`,
        [BLOCKS.HEADING_4]: (node, next) =>
          `<h4 class="equis-h4-semibold">${next(node.content)}</h4>`,
        [BLOCKS.PARAGRAPH]: (node, next) =>
          `<p class="equis-body-2">${next(node.content)}</p>`,
      },
    };

    return documentToHtmlString(this.data.text, options);
  }

  /**
   * Checks if the rich text has borders
   */
  public get hasBorder() {
    return !!this.data.hasBorder;
  }

  // CLASS PROPERTIES
  public get alignmentClass() {
    return this.data.contentAlignment === 'Centrado' ? 'center-xs' : 'start-xs';
  }

  public get widthClass() {
    return this.hasBorder || this.alignmentClass === 'center-xs'
      ? 'col-xs-12'
      : 'col-xs-offset-1 col-xs-10';
  }

  public get borderClass() {
    return this.hasBorder ? 'has-border__true' : 'has-border__false';
  }
}
