import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';

import { DataLayerService } from '@/services/data-layer.service';
import { NX23Link, NX23NavbarDropdown } from '@/types/content-types';

import { INavbarTabContentItem } from './navbar-tab-content-item.types';
import { SectionService } from '@/services/section.service';
import { PlatformService } from '../../../../../../services/platform.service';
import { AmplitudeExperiment } from '../../../../../../common/types/data-layer';
import { VariantContainerComponent } from '../../../../variant-container/variant-container.component';

@Component({
  selector: 'nx-navbar-tab-content-item',
  templateUrl: './navbar-tab-content-item.component.html',
  styleUrls: ['./navbar-tab-content-item.component.scss'],
})
export class NavbarTabContentItemComponent implements INavbarTabContentItem {
  constructor(
    private dataLayer: DataLayerService,
    private section: SectionService,
    public platform: PlatformService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  // PROPERTIES
  @Input() hasDropdown: INavbarTabContentItem['hasDropdown'] = false;
  @Input() contentHref!: INavbarTabContentItem['contentHref'];
  @Input() link?: INavbarTabContentItem['link'];
  @Input() dropdown?: INavbarTabContentItem['dropdown'];
  @Input() isDropdownActive!: INavbarTabContentItem['isDropdownActive'];
  @Input() itemsLength!: number;
  @Output()
  isDropdownActiveChange: INavbarTabContentItem['isDropdownActiveChange'] =
    new EventEmitter();

  // METHODS
  toggleDropdown() {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    this.isDropdownActiveChange.emit(!this.isDropdownActive);
    if (this.link && this.link.fields.dataLayer) {
      const params = this.link.fields.dataLayer.eventParams;
      this.dataLayer.push(
        {
          ...this.link.fields.dataLayer,
          eventParams: {
            ...params,
            action: this.isDropdownActive ? 'contract' : 'expand',
          },
        },
        this.section.sectionName,
        experiment
      );
    }
  }

  onClick(item?: NX23Link | NX23NavbarDropdown) {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    if (item)
      this.dataLayer.push(
        item.fields.dataLayer,
        this.section.sectionName,
        experiment
      );
  }
}
