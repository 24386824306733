<section [style.backgroundColor]="data.backgroundColor?.value || '#FFFFFF'">
  <div class="container section-wrapper">
    <ng-container *ngIf="isDownloadAppButton; else isLink">
      <nx-component-injector [component]="data.cta"></nx-component-injector>
    </ng-container>
    <ng-template #isLink>
      <nx-link [data]="data.cta.fields" (click)="pushClick()"></nx-link>
    </ng-template>
  </div>
</section>
