<nx-layout>
    <div class="container">
        <ng-container *ngIf="!submited">
            <div class="row">
                <div class="col-md-6">
                    <nx-info-panel [title]="steps[activeStep].infoPanel.title"
                        [message]="steps[activeStep].infoPanel.message">
                        <div class="panel-container">
                            <ng-container *ngIf="activeStep === 1">
                                <ul class="feature-list">
                                    <li class="feature-list__item equis-body-1"><span class="icon-check"></span>
                                        Tu caja de ahorro en pesos y dólares, gratis y segura.</li>
                                    <li class="feature-list__item equis-body-1"><span class="icon-check"></span>Los
                                        rendimientos
                                        diarios.</li>
                                    <li class="feature-list__item equis-body-1"><span class="icon-check"></span>Tu
                                        tarjeta
                                        de
                                        débito
                                        Visa.</li>
                                    <li class="feature-list__item equis-body-1"><span class="icon-check"></span>Si tenés
                                        un
                                        perfil
                                        de negocio
                                        también se eliminará junto a
                                        las soluciones
                                        de cobro.</li>
                                </ul>
                            </ng-container>
                            <ng-container *ngIf="activeStep === 2">
                                <span class="equis-body-1">Para continuar tenés que ingresar el código.</span>
                            </ng-container>
                        </div>
                    </nx-info-panel>
                </div>
                <div class="col-md-6">
                    <ng-container *ngIf="activeStep === 1">
                        <nx-feedback-form (formSubmitSuccess)="handleFeedbackFormSubmitSuccess($event)"
                            (formSubmitError)="handleFeedbackFormSubmitError($event)"></nx-feedback-form>
                    </ng-container>
                    <ng-container *ngIf="activeStep === 2">
                        <nx-otp-code-form (formSubmitSuccess)="handleOptCodeFormSubmitSuccess()"
                            [email]="feedBackData.email || ''" (formSubmitError)="handleOptCodeFormSubmitError($event)"
                            (resendError)="handleResendCodeError($event)"
                            (goBackEvent)="handleOptCodeFormGoBack()"></nx-otp-code-form>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="submited && !userBlocked && !genericError">
            <nx-feedback-message title="¡Listo! Ya enviamos tu solicitud"
                stickerPath="assets/stickers/success/success.json"
                message="Tené en cuenta que este proceso puede demorar algunos días, te enviaremos novedades por email."></nx-feedback-message>
        </ng-container>
        <ng-container *ngIf="submited && userBlocked">
            <div class="error-container">
                <nx-feedback-message title="Alcanzaste la cantidad de intentos permitidos"
                    stickerPath="assets/stickers/waiting/waiting.json"
                    message="Podés intentar enviar tu solicitud en 15minutos."></nx-feedback-message>

                <div class="error-container__buttons">
                    <app-button [text]="'Contactar con soporte'" [type]="'secondary'"
                        [href]="'https://www.naranjax.com/ayuda/'"></app-button>
                    <app-button [text]="'Salir'" [type]="'primary'" (click)="handleExit()"></app-button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="submited && genericError && !userBlocked">
            <div class="error-container">
                <nx-feedback-message title="Hubo un error" stickerPath="assets/stickers/hamster/hamster.json" message="Ya estamos trabajando para resolverlo.
                    Intentá de nuevo más tarde."></nx-feedback-message>

                <div class="error-container__buttons">
                    <app-button [text]="'Ir al Inicio'" [type]="'primary'" (click)="handleExit()"></app-button>
                </div>
            </div>
        </ng-container>
    </div>
</nx-layout>