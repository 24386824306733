<div class="card">
  <div>
    <div class="countdown" [ngClass]="{ grayscale: isCountdownActive }">
      <div class="box">
        <picture
          [nxContentfulImage]="data.imageLeft"
          [forceSize]="false"
          *ngIf="data.imageLeft"
        >
          <source />
          <source />
          <source />
          <img loading="eager" />
        </picture>
        <div class="content">
          <div class="title">{{ title }}</div>
          <div class="timer">
            <ul>
              <!-- <li>
                <span id="days">{{ time.days }}</span>
              </li>
              <li>
                <span id="hours">{{ time.hours }}</span>
              </li> -->
              <li>
                <span id="minutes">{{ time.minutes }}</span>
              </li>
              <li>
                <span id="seconds">{{ time.seconds }}</span>
              </li>
            </ul>
            <ul class="days">
              <!-- <li>Días</li>
              <li>Horas</li> -->
              <li>Min</li>
              <li>Seg</li>
            </ul>
          </div>
        </div>
        <picture
          [nxContentfulImage]="data.imageRight"
          [forceSize]="false"
          *ngIf="data.imageRight"
        >
          <source />
          <source />
          <source />
          <img loading="eager" />
        </picture>
      </div>
      <div class="circle1"></div>
      <div class="circle2"></div>
    </div>
  </div>
  <div class="text-wrapper">
    <h3 class="title equis-h2-medium">
      {{ data.contentIdentifier }}
    </h3>
  </div>
  <app-button
    class="button"
    [text]="buttonText"
    width="100%"
    size="large"
    type="secondary"
    (click)="onClick()"
    [disabled]="!isCountdownActive"
  ></app-button>
</div>
