<div class="container">
  <div class="grid-container" [class]="[imagePositionClass, typeClass]">
    <div class="image-content">
      <picture [nxContentfulImage]="data.image">
        <source />
        <source />
        <source />
        <img
          [class.grow]="!isHero"
          [class.secondary-hero]="isSecondaryHero"
          [class.tertiary-hero]="isTertiaryHero"
          [attr.loading]="isHero ? 'eager' : 'lazy'"
        />
      </picture>
    </div>
    <div class="text-content" [class.center-title]="isSecondaryHero">
      <nx-text-content
        [title]="data.title"
        [titleHierarchy]="data.titleHierarchy"
        [text]="data.text"
        [cta]="data.cta"
        [backgroundColor]="data.backgroundColor"
      ></nx-text-content>
    </div>
  </div>
</div>
