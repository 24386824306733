import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { PlatformService } from './platform.service';
import type { NX23Page } from '@/types/content-types';
import type { ContentType } from '@/types/contentful';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';

// Key that will be used to transfer state between the server and the client
const STATE_KEY_URL = makeStateKey<string>('');
const STATE_KEY_PAGE = makeStateKey<NX23Page>('page');

@Injectable({
  providedIn: 'root',
})
export class PageService {
  constructor(
    private platform: PlatformService,
    private http: HttpClient,
    private state: TransferState,
    private router: Router,
    @Optional() @Inject(REQUEST) private request: any
  ) {
    this.url = platform.getApiURL('page');
  }

  // PROPERTIES
  /**
   * Contentful API URL
   */
  private url: ReturnType<typeof PlatformService.prototype.getApiURL>;
  public sections = new BehaviorSubject<ContentType[]>([]);

  // METHODS
  /**
   * Performs a GET request to the Contentful API to retrieve a page's data
   * @param slug Contentful page's slug
   * @returns An observable containing the page's data
   */
  public getPage(): Observable<NX23Page> {
    if (this.platform.isServer) {
      this.state.set(STATE_KEY_URL, this.request.originalUrl);
    }

    const requestUrl = this.platform.route;

    if (requestUrl.includes('baja-cuenta')) return of();

    if (this.platform.route === null) {
      return of();
    }

    const path: string =
      requestUrl === '' || requestUrl.startsWith('?')
        ? 'home'
        : requestUrl.split('?')[0];

    if (this.state.get(STATE_KEY_PAGE, null)?.fields.slug === path) {
      return of(this.state.get(STATE_KEY_PAGE, null)!).pipe(
        tap(() => this.state.remove(STATE_KEY_PAGE))
      );
    }

    return this.http.get<NX23Page>(`${this.url}/${path}`).pipe(
      catchError((e) => this.handleError(`${this.url}/404`)),
      tap((page) => {
        this.state.set(STATE_KEY_PAGE, page);
      }),
      map((page) => page),
      take(1)
    );
  }

  /**
   * Redirects the user to the 404 page and throws an error
   * @param e Error thrown by the HTTP request
   * @returns An observable containing the error
   */
  private handleError(url: string) {
    return this.http.get<NX23Page>(url).pipe(
      map((page) => page),
      tap(() => this.router.navigate(['404']))
    );
  }
}
