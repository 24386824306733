<!-- This renders a card that is all clickable in all its area -->
<a
  *ngIf="data.url && !data.actionsList"
  [nxAbsoluteAnchor]="data.url"
  [routerLink]="platform.getRelativeUrl(data.url)"
  class="vertical_card"
  [ngClass]="{
    'vertical_card-without-description': !data.description,
    'card-with-url': data.url && !data.actionsList
  }"
  (click)="pushClick()"
>
  <div class="image-and-text_wrapper">
    <div
      class="vertical_image-wrapper"
      [ngClass]="{
        'vertical_image-wrapper-without-description': !data.description
      }"
    >
      <picture [nxContentfulImage]="data.image">
        <source />
        <source />
        <source />
        <img loading="lazy" />
      </picture>
    </div>
    <div class="vertical_text-wrapper">
      <container-element [ngSwitch]="data.heading">
        <ng-container *ngSwitchCase="'h1'"
          ><h1
            class="title equis-h1-semibold"
            [ngClass]="{
              'vertical_title-without-description': !data.description,
              'equis-h2-medium': !data.description
            }"
          >
            {{ data.title }}
          </h1></ng-container
        >
        <ng-container *ngSwitchCase="'h2'"
          ><h2
            class="title equis-h1-semibold"
            [ngClass]="{
              'vertical_title-without-description': !data.description,
              'equis-h2-medium': !data.description
            }"
          >
            {{ data.title }}
          </h2></ng-container
        >
        <ng-container *ngSwitchCase="'h3'"
          ><h3
            class="title equis-h1-semibold"
            [ngClass]="{
              'vertical_title-without-description': !data.description,
              'equis-h2-medium': !data.description
            }"
          >
            {{ data.title }}
          </h3></ng-container
        >
        <ng-container *ngSwitchCase="'h4'"
          ><h4
            class="title equis-h1-semibold"
            [ngClass]="{
              'vertical_title-without-description': !data.description,
              'equis-h2-medium': !data.description
            }"
          >
            {{ data.title }}
          </h4></ng-container
        >
        <ng-container *ngSwitchCase="'h5'"
          ><h5
            class="title equis-h1-semibold"
            [ngClass]="{
              'vertical_title-without-description': !data.description,
              'equis-h2-medium': !data.description
            }"
          >
            {{ data.title }}
          </h5></ng-container
        >
        <ng-container *ngSwitchCase="'h6'"
          ><h6
            class="title equis-h1-semibold"
            [ngClass]="{
              'vertical_title-without-description': !data.description,
              'equis-h2-medium': !data.description
            }"
          >
            {{ data.title }}
          </h6></ng-container
        >
        <ng-container *ngSwitchDefault
          ><h2
            class="title equis-h1-semibold"
            [ngClass]="{
              'vertical_title-without-description': !data.description,
              'equis-h2-medium': !data.description
            }"
          >
            {{ data.title }}
          </h2></ng-container
        >
      </container-element>

      <p class="description equis-body-1" *ngIf="data.description">
        {{ data.description }}
      </p>
    </div>
  </div>
  <ul class="actions-list" *ngIf="data.actionsList">
    <li *ngFor="let action of data.actionsList">
      <nx-link [data]="action.fields"></nx-link>
    </li>
  </ul>
</a>

<!-- This renders a card that may or may not have clickable actions -->
<div
  *ngIf="!data.url || (data.url && data.actionsList)"
  class="vertical_card"
  [ngClass]="{
    'vertical_card-without-description': !data.description,
    'card-with-url': data.url && !data.actionsList
  }"
>
  <div class="image-and-text_wrapper">
    <div
      class="vertical_image-wrapper"
      [ngClass]="{
        'vertical_image-wrapper-without-description': !data.description
      }"
    >
      <picture [nxContentfulImage]="data.image">
        <source />
        <source />
        <source />
        <img loading="lazy" />
      </picture>
    </div>
    <div class="vertical_text-wrapper">
      <container-element [ngSwitch]="data.heading">
        <ng-container *ngSwitchCase="'h1'"
          ><h1
            class="title equis-h1-semibold"
            [ngClass]="{
              'vertical_title-without-description': !data.description,
              'equis-h2-medium': !data.description
            }"
          >
            {{ data.title }}
          </h1></ng-container
        >
        <ng-container *ngSwitchCase="'h2'"
          ><h2
            class="title equis-h1-semibold"
            [ngClass]="{
              'vertical_title-without-description': !data.description,
              'equis-h2-medium': !data.description
            }"
          >
            {{ data.title }}
          </h2></ng-container
        >
        <ng-container *ngSwitchCase="'h3'"
          ><h3
            class="title equis-h1-semibold"
            [ngClass]="{
              'vertical_title-without-description': !data.description,
              'equis-h2-medium': !data.description
            }"
          >
            {{ data.title }}
          </h3></ng-container
        >
        <ng-container *ngSwitchCase="'h4'"
          ><h4
            class="title equis-h1-semibold"
            [ngClass]="{
              'vertical_title-without-description': !data.description,
              'equis-h2-medium': !data.description
            }"
          >
            {{ data.title }}
          </h4></ng-container
        >
        <ng-container *ngSwitchCase="'h5'"
          ><h5
            class="title equis-h1-semibold"
            [ngClass]="{
              'vertical_title-without-description': !data.description,
              'equis-h2-medium': !data.description
            }"
          >
            {{ data.title }}
          </h5></ng-container
        >
        <ng-container *ngSwitchCase="'h6'"
          ><h6
            class="title equis-h1-semibold"
            [ngClass]="{
              'vertical_title-without-description': !data.description,
              'equis-h2-medium': !data.description
            }"
          >
            {{ data.title }}
          </h6></ng-container
        >
        <ng-container *ngSwitchDefault
          ><h2
            class="title equis-h1-semibold"
            [ngClass]="{
              'vertical_title-without-description': !data.description,
              'equis-h2-medium': !data.description
            }"
          >
            {{ data.title }}
          </h2></ng-container
        >
      </container-element>

      <p class="description equis-body-1" *ngIf="data.description">
        {{ data.description }}
      </p>
    </div>
  </div>
  <ul class="actions-list" *ngIf="data.actionsList">
    <li *ngFor="let action of data.actionsList">
      <nx-component-injector [component]="action"></nx-component-injector>
    </li>
  </ul>
</div>
