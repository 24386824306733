import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { ICardCountdown } from './card-countdown.types';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../../directives/directives.module';
import { EquisIconContainerModule } from '@tn-equis/core/components/icon-container';
import { interval, Subject, take, takeUntil, tap } from 'rxjs';
import { PlatformService } from '../../../services/platform.service';

import { ButtonComponent } from '../button/button.component';
import { AmplitudeExperiment } from '../../../common/types/data-layer';
import { VariantContainerComponent } from '../../sections/variant-container/variant-container.component';
import { DataLayerService } from '../../../services/data-layer.service';
import { SectionService } from '../../../services/section.service';
import {
  EquisModalDialogModule,
  EquisModalDialogService,
  IEquisModalDialogConfig as ModalConfig,
} from '@tn-equis/core/components/modal-dialog';
import { FormCountdownComponent } from './form-countdown.component';
import { CountdownDataService } from './countdown.service';
declare const braze: any;
declare global {
  interface Window {
    dataLayer: any[];
    amplitude?: any;
  }
}

@Component({
  selector: 'nx-card-countdown',
  templateUrl: './card-countdown.component.html',
  styleUrls: ['./card-countdown.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    DirectivesModule,
    EquisIconContainerModule,
    ButtonComponent,
    EquisModalDialogModule,
  ],
})
export class CardCountdownComponent implements OnInit, OnDestroy {
  @Input() data!: ICardCountdown['data'];
  @Input() entryId!: string;
  private destroyed$ = new Subject<void>();
  public isCountdownActive = false;
  public title = '';

  public time = {
    seconds: '00',
    minutes: '00',
    hours: '00',
    days: '00',
  };

  constructor(
    private platform: PlatformService,
    private changeDetectorRef: ChangeDetectorRef,
    private dataLayer: DataLayerService,
    private section: SectionService,
    private modalDialogService: EquisModalDialogService,
    private countdownDataService: CountdownDataService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  async ngOnInit() {
    if (this.platform.isServer) return;
    this.updateCountdown();
    this.startCountdown().subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  get buttonText() {
    if (!this.data.endDate) {
      return 'Esperando';
    }

    return this.isCountdownActive ? 'Participar' : 'Finalizado';
  }

  private startCountdown = () =>
    interval(1000).pipe(
      takeUntil(this.destroyed$),
      tap(() => this.updateCountdown()),
      tap(() => this.changeDetectorRef.detectChanges())
    );

  private updateCountdown = () => {
    if (!this.data.endDate) {
      this.title = 'Si hay palo, participá acá';
      return;
    }

    const seconds = 1000,
      minutes = seconds * 60,
      hours = minutes * 60,
      days = hours * 24;

    const endDate = this.convertDateToUTC(
      new Date(this.data.endDate)
    ).getTime();
    const currentDate = this.convertDateToUTC(new Date()).getTime();
    const diff = endDate - currentDate;

    // date is reached or null
    if (diff < 0) {
      this.title = '¡Tiempo cumplido!';
      return this.turnOffCountdown();
    }

    this.isCountdownActive = true;
    this.title = '¡Es ahora, metele!';

    this.time.days = Math.floor(diff / days)
      .toString()
      .padStart(2, '00');

    this.time.hours = Math.floor((diff % days) / hours)
      .toString()
      .padStart(2, '00');

    this.time.minutes = Math.floor((diff % hours) / minutes)
      .toString()
      .padStart(2, '00');

    this.time.seconds = Math.floor((diff % minutes) / seconds)
      .toString()
      .padStart(2, '00');
  };

  private turnOffCountdown = () => {
    this.isCountdownActive = false;
    this.time.days = '00';
    this.time.hours = '00';
    this.time.minutes = '00';
    this.time.seconds = '00';
    this.changeDetectorRef.detectChanges();
  };

  private convertDateToUTC = (date: Date) => {
    const now = date;

    return new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      )
    );
  };

  public onClick = () => {
    if (!this.isCountdownActive) return;
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    const timestamp = new Date(this.data.endDate).getTime();

    this.dataLayer.push(
      {
        eventName: this.data.dataLayer.eventName,
        eventParams: {
          action: 'click',
          element: 'participar',
          section: this.section.sectionName,
        },
        extraParams: {
          paloParams: {
            id_palo: this.data.dataLayer['paloParams'].id_palo,
            id_partido: this.data.dataLayer['paloParams'].id_partido,
            id_minuto: this.data.dataLayer['paloParams'].id_minuto,
            timestamp_end: timestamp,
          },
        },
      },
      this.section.sectionName,
      experiment
    );

    braze.getDeviceId((deviceId: string) =>
      window.dataLayer.push({
        event: 'amplitude.trackEvent',
        amplitude: {
          eventName: 'nxcom_paloxpalo',
          eventProperties: {
            flow: 'paloxpalo',
            action: 'click',
            element: 'participar',
            braze_deviceId: deviceId,
            id_palo: this.data.dataLayer['paloParams'].id_palo,
            id_partido: this.data.dataLayer['paloParams'].id_partido,
            id_minuto: this.data.dataLayer['paloParams'].id_minuto,
            timestamp_end: timestamp,
          },
        },
      })
    );

    this.showModal(timestamp);
  };

  public showModal = (timestamp: number) => {
    const customConfig: ModalConfig = {
      type: 'custom',
      size: 'small',
      data: {
        header: {
          title: 'Formulario de Contacto',
          crossButton: true,
          alignContent: 'center',
        },
      },
    };

    this.modalDialogService
      .showModalDialog(customConfig, FormCountdownComponent)
      .pipe(take(1))
      .subscribe();

    this.countdownDataService.setData({
      id_palo: this.data.dataLayer['paloParams'].id_palo,
      id_partido: this.data.dataLayer['paloParams'].id_partido,
      id_minuto: this.data.dataLayer['paloParams'].id_minuto,
      timestamp_end: timestamp,
    });
  };
}
