import { Component, Input } from '@angular/core';
import { ICapsuleX } from './capsule-x.types';

@Component({
  selector: 'nx-capsule-x',
  templateUrl: './capsule-x.component.html',
  styleUrls: ['./capsule-x.component.scss'],
})
export class CapsuleXComponent implements ICapsuleX {
  constructor() {}

  // REQUIRED INPUTS
  @Input() data!: ICapsuleX['data'];

  // OPTIONAL INPUTS
  @Input() isMaskConvex: ICapsuleX['isMaskConvex'] = false;

  // PROPERTIES

  /**
   * The capsule's image information
   */
  get image() {
    return {
      src: this.data.image.fields.file?.url,
      alt: this.data.image.fields.description,
    };
  }

  get maskType(): string {
    if (this.isMaskConvex) {
      return 'convex-mask';
    } else {
      return 'concave-mask';
    }
  }

  get maskDirection(): string {
    if (this.data.imagePosition === 'Izquierda') {
      return 'flip-right';
    } else {
      return 'flip-left';
    }
  }

  /**
   * Whether the image position is left or right.
   */
  get imageDirection() {
    return this.data.imagePosition === 'Izquierda';
  }
}
