<div
  class="vertical-padding"
  [style.backgroundColor]="backgroundColor"
  [class]="[typeClass, imageSizeClass, paddingClass]"
>
  <nx-text-image-custom
    [component]="data.component"
    [data]="data"
    *ngIf="data.component"
  ></nx-text-image-custom>

  <ng-container #noComponent *ngIf="!data.component">
    <ng-container *ngIf="hasFullImage; else hasPaddedImage">
      <nx-text-image-full [data]="data"> </nx-text-image-full>
    </ng-container>

    <ng-template #hasPaddedImage>
      <nx-text-image-padded [data]="data"></nx-text-image-padded>
    </ng-template>
  </ng-container>
</div>
