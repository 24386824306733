import { environment } from '@/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class DeleteAccountService {

  private apiUrl = environment.BFF_REMOVE_ACCOUNT_URL;

  constructor(private http: HttpClient) { }

  start(data: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.apiUrl}/start`, data);
  }

  send(data: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.apiUrl}/send`, data);
  }

  validate(data: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.apiUrl}/validate`, data);
  }
}
