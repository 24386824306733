<section [style.backgroundColor]="backgroundColor || '#FAFAFA'">
  <div [class]="['container', 'section-wrapper', paddingClass]">
    <nx-heading-renderer
      *ngIf="data.title && data.titleHierarchy"
      class="equis-h1-semibold"
      [data]="data.titleHierarchy"
      >{{ data.title }}</nx-heading-renderer
    >
    <ul
      class="information-list"
      [ngClass]="{
        'one-column': columns === 1,
        'two-columns': columns === 2 || columns === 4,
        'three-columns': columns === 3 || columns >= 5
      }"
    >
      <li
        class="list-item"
        *ngFor="let information of data.informationList; let i = index"
      >
        <div
          *ngIf="information.fields.title || information.fields.icon"
          class="icon-and-title"
        >
          <picture
            *ngIf="information.fields.icon"
            [nxContentfulImage]="information.fields.icon"
          >
            <source />
            <source />
            <source />
            <img class="icon" loading="lazy" />
          </picture>

          <h2 *ngIf="information.fields.title" class="equis-h2-medium">
            {{ information.fields.title }}
          </h2>
        </div>
        <nx-rich-text-renderer
          [text]="information.fields.description"
        ></nx-rich-text-renderer>
        <nx-component-injector
          *ngIf="information.fields.cta"
          [component]="information.fields.cta"
        ></nx-component-injector>
      </li>
    </ul>
  </div>
</section>
