import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import CapsuleXModule from '@/atoms/capsule-x/capsule-x.module';
import CardModule from '@/atoms/card/card.module';

import { ContainerXComponent } from './container-x.component';

@NgModule({
  declarations: [ContainerXComponent],
  imports: [CommonModule, CapsuleXModule, CardModule],
  exports: [ContainerXComponent],
})
export default class ContainerXModule {
  public static component = ContainerXComponent;
}
