import { Component, Input } from '@angular/core';
import { IHeadingRenderer } from './heading-renderer.types';

@Component({
  selector: 'nx-heading-renderer',
  templateUrl: './heading-renderer.component.html',
  styleUrls: ['./heading-renderer.component.scss'],
})
export class HeadingRendererComponent implements IHeadingRenderer {
  @Input() data!: IHeadingRenderer['data'];
  @Input() color?: IHeadingRenderer['color'];
}
