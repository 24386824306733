import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LinkComponent } from './link.component';
import { ButtonComponent } from '../button/button.component';

@NgModule({
  declarations: [LinkComponent],
  imports: [CommonModule, ButtonComponent],
  exports: [LinkComponent],
})
export default class LinkModule {
  public static component = LinkComponent;
}
