import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RichTextRendererComponent } from './rich-text-renderer.component';

@NgModule({
  declarations: [RichTextRendererComponent],
  imports: [CommonModule],
  exports: [RichTextRendererComponent],
})
export class RichTextRendererModule {}
