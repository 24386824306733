import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SitewideComponent } from './sitewide.component';
import { RichTextRendererModule } from '../../tooling/rich-text-renderer/rich-text-renderer.module';
import ButtonDownloadAppModule from '../../atoms/button-download-app/button-download-app.module';
import { SectionService } from '../../../services/section.service';
import { EquisIconContainerModule } from '@tn-equis/core/components/icon-container';
import { ComponentInjectorModule } from '../../tooling/component-injector/component-injector.module';

@NgModule({
  declarations: [SitewideComponent],
  imports: [
    CommonModule,
    RichTextRendererModule,
    ButtonDownloadAppModule,
    EquisIconContainerModule,
    ComponentInjectorModule,
  ],
  exports: [SitewideComponent],
  providers: [SectionService],
})
export default class SitewideModule {
  public static component = SitewideComponent;
}
