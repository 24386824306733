<div class="feedback-message">
    <equis-card>
        <div class="feedback-message__content">
            <div class="feedback-message__sticker-container">
                <equis-sticker-animation [autoPlay]="true" [loop]="false" [width]="'160'" [height]="'160'"
                    [path]="stickerPath">
                </equis-sticker-animation>
            </div>
            <div class="feedback-message__text-container">
                <h1 class="equis-h2-semibold">{{title}}</h1>
                <p class="equis-body-1">{{message}} </p>
            </div>
        </div>
    </equis-card>
</div>