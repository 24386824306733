import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { EquisHeaderModule } from '@tn-equis/core/components/header'



@NgModule({
  declarations: [LayoutComponent],
  exports: [LayoutComponent],
  imports: [
    CommonModule,
    EquisHeaderModule
  ]
})
export class LayoutModule { }
