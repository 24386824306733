import { Component, Input } from '@angular/core';

import {
  NX23Link,
  NX23LinkFields,
  NX23SocialLink,
  NX23SocialLinkFields,
} from '@/types/content-types';
import { DataLayerService } from '@/services/data-layer.service';
import { SectionService } from '@/services/section.service';

import { IBodyFooter, OnClickItem } from './body-footer.types';
import { PlatformService } from '../../../../services/platform.service';

@Component({
  selector: 'nx-body-footer',
  templateUrl: './body-footer.component.html',
  styleUrls: ['./body-footer.component.scss'],
})
export class BodyFooterComponent implements IBodyFooter {
  constructor(
    private _dataLayerService: DataLayerService,
    private section: SectionService,
    public platform: PlatformService
  ) {}

  // REQUIRED INPUT
  @Input() data!: IBodyFooter['data'];

  // METHODS

  /**
   * Returns the image source and alt text from a link
   * @param link Link element to get the image from
   * @returns An object with the image source and alt text if the link is a social link, otherwise null
   */
  getImage(link: NX23Link | NX23SocialLink) {
    if (this.isSocialLink(link.fields)) {
      return {
        src: link.fields.icon.fields.file?.url,
        alt: link.fields.icon.fields.description,
      };
    } else return null;
  }

  // Type guard function that helps TypeScript narrow down the type of link (a social Link or a regular Link)
  isSocialLink(
    link: NX23LinkFields | NX23SocialLinkFields
  ): link is NX23SocialLinkFields {
    return (link as NX23SocialLinkFields).icon !== undefined;
  }

  getRelAnchor(href: string) {
    const url = this.platform.getAbsoluteURL(href);
    return !url.includes('www.naranjax.com') ? 'nofollow' : 'dofollow';
  }

  onClick(item: OnClickItem) {
    this._dataLayerService.push(
      item.fields.dataLayer,
      this.section.sectionName
    );
  }
}
