import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoPanelComponent } from './info-panel.component';
import { EquisMessageModule } from '@tn-equis/core/components/message';



@NgModule({
  declarations: [InfoPanelComponent],
  exports: [InfoPanelComponent],
  imports: [
    CommonModule,
    EquisMessageModule

  ]
})
export class InfoPanelModule { }
