<section
  [class]="[patternXPosition, paddingClass]"
  [style.backgroundColor]="backgroundColor"
  [style.--background-color]="backgroundColor"
  *ngIf="type === 'Con fondo'"
>
  <div class="container">
    <nx-capsule-x [data]="capsuleXContent.data"></nx-capsule-x>
  </div>
</section>

<section
  class="section-small"
  [style.backgroundColor]="backgroundColor"
  *ngIf="type === 'Sin fondo (small)'"
>
  <div
    class="alternative-promotional-banner small container"
    [class.flip-direction]="imagePosition === 'Izquierda'"
  >
    <nx-text-content
      class="text-content"
      [title]="data.title"
      [text]="data.text"
      [titleHierarchy]="data.titleHierarchy"
      [cta]="data.cta!"
      [size]="'small'"
      [backgroundColor]="data.backgroundColor"
    ></nx-text-content>

    <picture class="image-small" [nxContentfulImage]="data.mainImage">
      <source />
      <source />
      <source />
      <img loading="lazy" />
    </picture>
  </div>
</section>

<section
  class="section-big"
  [style.backgroundColor]="backgroundColor"
  *ngIf="type === 'Sin fondo (big)'"
>
  <div
    class="alternative-promotional-banner big container"
    [class.flip-direction]="imagePosition === 'Izquierda'"
  >
    <nx-text-content
      class="text-content"
      [title]="data.title"
      [text]="data.text"
      [titleHierarchy]="data.titleHierarchy"
      [cta]="data.cta!"
      [size]="'small'"
      [backgroundColor]="data.backgroundColor"
    ></nx-text-content>
    <picture class="image-big" [nxContentfulImage]="data.mainImage">
      <source />
      <source />
      <source />
      <img loading="lazy" />
    </picture>
  </div>
</section>
