import { Component, HostBinding, Input } from '@angular/core';

import { IClickeableContainer } from './clickeable-container.types';
import { PlatformService } from '../../../services/platform.service';

@Component({
  selector: 'nx-clickeable-container',
  templateUrl: './clickeable-container.component.html',
  styleUrls: ['./clickeable-container.component.scss'],
})
export class ClickeableContainerComponent implements IClickeableContainer {
  constructor(public platform: PlatformService) {}
  // REQUIRED INPUTS
  @Input() url?: string;

  @HostBinding('class') get class() {
    return 'clickeable-container';
  }

  // PROPERTIES
  get isClickeable() {
    return !!this.url;
  }
}
