import { Component, Input, Optional } from '@angular/core';

import { DataLayerService } from '@/services/data-layer.service';
import { SectionService } from '@/services/section.service';

import { IAccordion } from './accordion.types';
import { AmplitudeExperiment } from '../../../../common/types/data-layer';
import { VariantContainerComponent } from '../../variant-container/variant-container.component';
@Component({
  selector: 'nx-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements IAccordion {
  constructor(
    private dataLayer: DataLayerService,
    private section: SectionService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}
  // REQUIRED INPUTS
  @Input() data!: IAccordion['data'];

  // METHODS
  pushExpand(mustOpen: boolean) {
    const dl = this.data.fields.dataLayer;

    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    this.dataLayer.push(
      {
        eventName: dl.eventName,
        eventParams: {
          ...dl.eventParams,
          action: mustOpen ? 'expand' : 'contract',
        },
      },
      this.section.sectionName,
      experiment
    );
  }
}
