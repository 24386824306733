<ng-container *ngIf="data.url; else withoutLink">
  <a
    class="quicklink"
    [ngClass]="{ 'has-server-quicklink': platform.isServer }"
    [nxAbsoluteAnchor]="data.url"
    [routerLink]="platform.getRelativeUrl(data.url)"
    (click)="pushClick()"
  >
    <div class="icon-wrapper">
      <picture [nxContentfulImage]="data.icon">
        <source />
        <source />
        <source />
        <img loading="lazy" />
      </picture>
    </div>
    <p class="equis-body-1-link text desktop">{{ data.text }}</p>
    <p class="equis-body-2-link text mobile">{{ data.text }}</p>
  </a>
</ng-container>

<ng-template #withoutLink>
  <a
    class="quicklink--nolink"
    [ngClass]="{ 'has-server-quicklink': platform.isServer }"
  >
    <div class="icon-wrapper">
      <picture [nxContentfulImage]="data.icon">
        <source />
        <source />
        <source />
        <img loading="lazy" />
      </picture>
    </div>
    <p class="equis-body-1-link text desktop">{{ data.text }}</p>
    <p class="equis-body-2-link text mobile">{{ data.text }}</p>
  </a>
</ng-template>
