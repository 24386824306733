import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { EquisControlMessageStatus } from '@tn-equis/core/components/control-message';
import { EquisTextfieldTypes } from '@tn-equis/core/components/textfield';
import { DOCUMENT } from '@angular/common';

import { DataLayerService } from '@/services/data-layer.service';
import { WhatsappService } from '@/atoms/button-download-app/whatsapp.service';

import { ButtonDownloadAppService } from '../button-download-app.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { IEnviroment } from '../../../../common/types/enviroment';
import { AmplitudeExperiment } from '../../../../common/types/data-layer';
import { VariantContainerComponent } from '../../../sections/variant-container/variant-container.component';

declare const grecaptcha: any;

const DEFAULT_PHONE_PLACEHOLDER = 'Escribilo sin el 0 ni el 15';
const enviromentState = makeStateKey<IEnviroment>('enviroment');

@Component({
  selector: 'nx-modal-download-app',
  templateUrl: './modal-download-app.component.html',
  styleUrls: ['./modal-download-app.component.scss'],
})
export class ModalDownloadAppComponent implements OnInit, OnDestroy {
  constructor(
    private whatsapp: WhatsappService,
    private dialog: ButtonDownloadAppService,
    private dataLayer: DataLayerService,
    private renderer: Renderer2,
    @Optional() public variantContainerComponent: VariantContainerComponent,
    private readonly transferState: TransferState,
    @Inject(DOCUMENT) private _document: Document,
    @Optional()
    @Inject('enviromentFromVault')
    public enviroment: IEnviroment
  ) {
    // Configuration taken from https://gitlab.com/Naranja-Digital/naranjadotcom/frontend/naranjaweb/-/blob/integration/src/app/shared/components/action-button/modal-template/modal-template.component.ts
    this.phoneFormControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.phoneFormControl.invalid) {
          const error = Object.keys(this.phoneFormControl?.errors!)[0];
          this.phoneInputData.text = this.phoneFormErrorTexts.get(error)!;
          this.phoneInputData.status = EquisControlMessageStatus.ERROR;
        } else {
          this.phoneInputData.text =
            this.phoneFormControl.value?.length === this.PHONE_LENGTH
              ? ''
              : DEFAULT_PHONE_PLACEHOLDER;
          this.phoneInputData.status = EquisControlMessageStatus.DEFAULT;
        }
        this.phoneInputData.hasIcon = this.phoneFormControl.invalid;
      });

    this.RECAPTCHA_API_KEY_V3 = this.transferState.get(
      enviromentState,
      null
    )!.RECAPTCHA_API_KEY_V3;
  }

  // PROPERTIES
  private RECAPTCHA_API_KEY_V3!: string;
  readonly INPUT_TYPE = EquisTextfieldTypes.TEL;
  readonly PHONE_LENGTH = 10;
  isLoading = false;
  private readonly phoneFormErrorTexts = new Map([
    ['minlength', `Deben ser al menos ${this.PHONE_LENGTH} digitos.`],
    ['pattern', 'Deben ser solo números.'],
  ]);
  private destroyed$ = new Subject<void>();

  // FormControl
  phoneFormControl = new FormControl(
    '',
    Validators.compose([
      Validators.minLength(this.PHONE_LENGTH),
      Validators.pattern('^[0-9]*$'),
    ])
  );

  phoneInputData = {
    text: 'Escribilo sin el 0 ni el 15',
    status: EquisControlMessageStatus.DEFAULT,
    hasIcon: false,
  };

  get data() {
    return this.dialog.dialogData;
  }

  // METHODS
  /**
   * Sends the download link to the user's Whatsapp number
   */
  public async sendWhatsapp() {
    this.isLoading = true;

    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    if (this.dialog.dialogData.dataLayer)
      this.dataLayer.push(
        {
          eventName: 'modal_app',
          eventParams: {
            action: 'click',
            element: 'enviar',
            section: this.dialog.dialogData.dataLayer.eventParams.section,
            flow: this.dialog.dialogData.dataLayer.eventParams.flow,
          },
        },
        this.dialog.dialogData.section,
        experiment
      );

    this.whatsapp.eventHandler.subscribe((status) => {
      if (
        status === 'success' ||
        status === 'error' ||
        status === 'retryCancel'
      ) {
        this.isLoading = false;
      }
    });

    if (this.phoneFormControl.value) {
      const reCaptchaToken = await this.getReCaptchaToken();
      this.whatsapp.sendAppLink(this.phoneFormControl.value, reCaptchaToken);
    }
  }

  // HOOKS
  ngOnInit(): void {
    // Add script on init to avoid using when not needed
    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.src = '/assets/js/lottie.min.js';
    script.defer = true;
    this.renderer.appendChild(this._document.body, script);

    this.initReCaptcha();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private initReCaptcha() {
    const recaptcha: HTMLElement | null =
      this._document.querySelector('.grecaptcha-badge');

    if (recaptcha) {
      recaptcha.style.display = 'block';
      return;
    }

    const script = this.renderer.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.RECAPTCHA_API_KEY_V3}`;
    script.id = 'recaptcha';
    script.type = 'text/javascript';
    this.renderer.appendChild(this._document.head, script);
  }

  private async getReCaptchaToken(): Promise<string> {
    return new Promise((resolve) => {
      grecaptcha.enterprise.ready(() => {
        grecaptcha.enterprise
          .execute(this.RECAPTCHA_API_KEY_V3, {
            action: 'getWhatsAppLink',
          })
          .then((token: string) => resolve(token));
      });
    });
  }
}
