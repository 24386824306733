<div class="container">
  <div class="grid-container" [class]="[imagePositionClass, typeClass]">
    <nx-component-injector
      [component]="data.component"
      *ngIf="data.component"
    ></nx-component-injector>
    <div class="text-content" [class.center-title]="isSecondaryHero">
      <nx-text-content
        [title]="data.title"
        [titleHierarchy]="data.titleHierarchy"
        [text]="data.text"
        [cta]="data.cta"
        [backgroundColor]="data.backgroundColor"
      ></nx-text-content>
    </div>
  </div>
</div>
