<form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()" class="feedback-form">
    <equis-card>
        <div class="feedback-form__controls">
            <h4 class="equis-h4-medium feedback-form__title">Completá estos datos para enviar la solicitud de baja de tu
                cuenta</h4>

            <div class="feedback-form__controls__item">
                <equis-textfield [label]="'Email'">
                    <input equisTextfieldInput [id]="'email'" formControlName="email" [autocomplete]="'off'" />
                </equis-textfield>
                <equis-control-message
                    *ngIf="email?.invalid && (email?.dirty || email?.touched) && email?.hasError('required')"
                    [bulletList]="emailRequired">
                </equis-control-message>
                <equis-control-message
                    *ngIf="email?.invalid && (email?.dirty || email?.touched) && email?.hasError('invalidEmail')"
                    [bulletList]="emailInvalid">
                </equis-control-message>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="feedback-form__controls__item">
                        <equis-textfield [label]="'Nombre'">
                            <input equisTextfieldInput [id]="'name'" formControlName="name" [autocomplete]="'off'" />
                        </equis-textfield>
                        <equis-control-message
                            *ngIf="name?.invalid && (name?.dirty || name?.touched) && name?.hasError('required')"
                            [bulletList]="nameRequired">
                        </equis-control-message>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="feedback-form__controls__item">
                        <equis-textfield [label]="'Apellido'">
                            <input equisTextfieldInput [id]="'lastName'" formControlName="lastname"
                                [autocomplete]="'off'" />
                        </equis-textfield>
                        <equis-control-message
                            *ngIf="lastname?.invalid && (lastname?.dirty || lastname?.touched) && lastname?.hasError('required')"
                            [bulletList]="lastnameRequired">
                        </equis-control-message>
                    </div>
                </div>
            </div>

            <select class="custom-select" id="reason" formControlName="reason" required>
                <option class="equis-body-1" value="" disabled>Motivo de baja</option>
                <option value="No me interesan las promociones o no puedo usarla en los lugares que quiero.">No me
                    interesan las promociones o no puedo usarla en los lugares que quiero.</option>
                <option value="La app tiene interrupciones o demoras">La app tiene interrupciones o demoras.</option>
                <option value=">No recibí la atención esperada">No recibí la atención esperada.</option>
                <option value="Estoy disconforme con los costos del servicio para
                comercios">Estoy disconforme con los costos del servicio para
                    comercios.</option>
                <option value="Tuve problemas para cobrar con Toque">Tuve problemas para cobrar con Toque.</option>
                <option value="Tuve una mala experiencia operando">Tuve una mala experiencia operando.</option>
            </select>
            <equis-control-message
                *ngIf="reason?.invalid && (reason?.dirty || reason?.touched) && reason?.hasError('required')"
                [bulletList]="reasonRequired">
            </equis-control-message>
        </div>
    </equis-card>
    <app-button class="feedback-form__button" [text]="'Continuar'" [type]="'submit'" [loading]="loading"
        [disabled]="feedbackForm.invalid"></app-button>
</form>