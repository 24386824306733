import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import type { IVideo } from './video.types';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'nx-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class VideoComponent implements IVideo {
  constructor(public sanitizer: DomSanitizer) {}
  @Input() data!: IVideo['data'];

  get sourceType() {
    if (this.data.embed.includes('tiktok.com')) {
      return 'tiktok';
    }

    return 'default';
  }

  get paddingClass() {
    const className = {
      'Aumentar espaciado superior': 'padding_top',
      'Aumentar espaciado inferior': 'padding_button',
      'Aumentar espaciado superior e inferior': 'padding_both',
    };
    return className[this.data?.padding] ?? null;
  }
}
