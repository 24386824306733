<form [formGroup]="otpCodeForm" (ngSubmit)="onSubmit()" class="opt-code-form ">
    <equis-card>
        <div class="opt-code-form__controls">
            <h4 class="equis-h4-medium opt-code-form__title">Ingresar código</h4>

            <equis-textfield [label]="'Código'">
                <input equisTextfieldInput [id]="'code'" formControlName="code" [autocomplete]="'off'" />
            </equis-textfield>
            <equis-control-message *ngIf="code?.invalid && (code?.dirty || code?.touched) && code?.hasError('required')"
                [bulletList]="codeRequired">
            </equis-control-message>
            <equis-control-message *ngIf="code?.invalid && (code?.dirty || code?.touched) && (code?.hasError('minlength') || code?.hasError('maxlength'))"
                [bulletList]="codeLength">
            </equis-control-message>
            <equis-control-message *ngIf="(code?.dirty || code?.touched) && invalidCode" [bulletList]="codeInvalid">
            </equis-control-message>
            <div class="opt-code-form__timer">
                <nx-timer-button title="Reenviar código en:" titleTimeout="Reenviar código" (buttonClick)="resendCode()"
                    [loading]="resendLoading"></nx-timer-button>
            </div>
        </div>
    </equis-card>
    <div class="opt-code-form__buttons">
        <app-button [text]="'Volver'" [type]="'secondary'" (click)="goBack()"></app-button>
        <app-button [text]="'Continuar'" [type]="'submit'" [disabled]="otpCodeForm.invalid"
            [loading]="formLoading"></app-button>
    </div>

</form>