import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import TextContentModule from '@/layouts/text-content/text-content.module';
import { DirectivesModule } from '@/directives/directives.module';

import { CapsuleXComponent } from './capsule-x.component';
import { ButtonComponent } from '../button/button.component';

@NgModule({
  declarations: [CapsuleXComponent],
  imports: [
    CommonModule,
    ButtonComponent,
    TextContentModule,
    DirectivesModule,
  ],
  exports: [CapsuleXComponent],
})
export default class CapsuleXModule {
  public static component = CapsuleXComponent;
}
