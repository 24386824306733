import { Component, Input, OnInit, Self } from '@angular/core';

import { SectionService } from '@/services/section.service';
import { PlatformService } from '@/services/platform.service';

import type { IAccordionList } from './accordion-list.types';

@Component({
  selector: 'nx-accordion-list',
  templateUrl: './accordion-list.component.html',
  styleUrls: ['./accordion-list.component.scss'],
  providers: [SectionService],
})
export class AccordionListComponent implements IAccordionList, OnInit {
  constructor(
    @Self() section: SectionService,
    private platform: PlatformService
  ) {
    section.sectionName = this.SECTION_NAME;
  }

  // REQUIRED INPUTS
  @Input() data!: IAccordionList['data'];

  // PROPERTIES
  absoluteHref!: string;
  readonly HELP_LINK = '/ayuda';
  readonly HELP_TITLE = '¿Tenés más dudas?';
  readonly HELP_BUTTON_TEXT = 'Ir al Centro de Ayuda';
  private readonly SECTION_NAME = 'faq';

  get isFAQ(): boolean {
    return Boolean(this.data.cta);
  }

  // HOOKS
  ngOnInit(): void {
    this.absoluteHref = this.platform.getAbsoluteURL(this.HELP_LINK);
  }

  get paddingClass() {
    const className = {
      'Reducir espaciado superior': 'padding_top',
      'Reducir espaciado inferior': 'padding_button',
      'Reducir espaciado superior e inferior': 'padding_both',
    };
    return className[this.data?.padding] ?? null;
  }
}
