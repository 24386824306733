import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { ColorContrastService } from '@/services/color-contrast.service';
import { SectionService } from '@/services/section.service';

import type { ITextContent } from './text-content.types';

@Component({
  selector: 'nx-text-content',
  templateUrl: './text-content.component.html',
  styleUrls: ['./text-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SectionService],
})
export class TextContentComponent implements ITextContent, OnInit {
  // REQUIRED INPUTS
  @Input() title!: ITextContent['title'];

  // OPTIONAL INPUTS
  @Input() text?: ITextContent['text'];
  @Input() cta?: ITextContent['cta'];
  @Input() style?: ITextContent['style'] = 'default';
  @Input() size?: ITextContent['size'] = 'big';
  @Input() backgroundColor?: ITextContent['backgroundColor'];
  @Input() titleHierarchy!: ITextContent['titleHierarchy'];

  constructor(
    private colorContrastService: ColorContrastService,
    section: SectionService
  ) {
    section.sectionName = 'content';
  }

  // PROPERTIES
  textColor: 'black' | 'white' = 'black';

  /**
   * Whether the size is big or not.
   */
  get isBig() {
    return this.size === 'big';
  }

  /**
   * Whether the style is alternative or not.
   */
  get isAlternative() {
    return this.style === 'alternative' || this.textColor === 'white';
  }

  /**
   * Gets the cta data, based on the type of `TextContent`. Overrides the `style` property in case it's necessary
   */
  get ctaData(): ITextContent['cta'] | undefined {
    if (this.cta && this.isAlternative) {
      // Override the style
      const style = this.isAlternative ? 'Alternativo' : this.cta.fields.style;

      return {
        ...this.cta,
        fields: { ...this.cta.fields, style },
      } as ITextContent['cta'];
    } else {
      return this.cta;
    }
  }

  // HOOKS
  ngOnInit(): void {
    this.textColor = this.colorContrastService.decideTextColor(
      this.backgroundColor
    );
  }
}
