import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RichTextRendererModule } from '@/tooling/rich-text-renderer/rich-text-renderer.module';

import { RichTextComponent } from './rich-text.component';

@NgModule({
  declarations: [RichTextComponent],
  imports: [CommonModule, RichTextRendererModule],
  exports: [RichTextComponent],
})
export default class RichTextModule {
  public static component = RichTextComponent;
}
