import { Injectable } from '@angular/core';

import { DataLayer } from '@/types/contentful';

@Injectable()
/**
 * Service for setting and passing the section name to the children an then the data layer.
 */
export class SectionService {
  public sectionName: NonNullable<DataLayer['eventParams']['section']> =
    'content';
}
