import { PlatformService } from '@/services/platform.service';
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[nxAbsoluteAnchor]',
})
export class AbsoluteAnchorDirective implements AfterViewInit {
  constructor(
    private el: ElementRef<HTMLAnchorElement>,
    private platform: PlatformService
  ) {}

  // REQUIRED INPUTS
  @Input() nxAbsoluteAnchor!: string;

  // FIELDS
  private get absoluteURL() {
    return this.platform.getAbsoluteURL(this.nxAbsoluteAnchor);
  }

  // PRIVATE METHODS
  private setHref() {
    const anchor = this.el.nativeElement;
    anchor.setAttribute('href', this.absoluteURL);
  }

  // HOOKS
  ngAfterViewInit(): void {
    this.setHref();
  }
}
