import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VideoComponent } from './video.component';
import { ComponentInjectorModule } from '../../tooling/component-injector/component-injector.module';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [VideoComponent],
  imports: [CommonModule, ComponentInjectorModule],
  exports: [VideoComponent],
})
export default class VideoModule {
  public static component = VideoComponent;
}
