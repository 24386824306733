import { Component, Input } from '@angular/core';

import type { IPlaceholderWip } from './placeholder-wip.types';

@Component({
  selector: 'nx-placeholder-wip',
  templateUrl: './placeholder-wip.component.html',
  styleUrls: ['./placeholder-wip.component.scss'],
})
export class PlaceholderWipComponent implements IPlaceholderWip {
  // REQUIRED INPUTS
  @Input() data!: IPlaceholderWip['data'];
}
