import {
  ChangeDetectorRef,
  Injectable,
  Injector,
  ViewContainerRef,
  createNgModule,
} from '@angular/core';
import { Path, PathType, Paths } from './component-injector.types';
import { ContentType } from '@/types/contentful';

const componentMap: Paths = {
  // PLOP_INJECT_COMPONENT_MAP
  nx23Quicklink: { type: PathType.ATOM, path: 'quicklink' },
  nx23ChatbotButton: { type: PathType.ATOM, path: 'button-chatbot' },
  nx23CtaSection: { type: PathType.SECTION, path: 'cta-section' },
  nx23BranchesMap: { type: PathType.SECTION, path: 'branches-map' },
  nx23FeaturedInformation: {
    type: PathType.SECTION,
    path: 'featured-information',
  },
  nx23PlaceholderWip: { type: PathType.SECTION, path: 'placeholder-wip' },
  nx23GenericGridSection: {
    type: PathType.SECTION,
    path: 'generic-grid-section',
  },
  nx23Navbar: { type: PathType.SECTION, path: 'navbar' },
  nx23AccordionList: { type: PathType.SECTION, path: 'accordion-list' },
  nx23RichText: { type: PathType.SECTION, path: 'rich-text' },
  nx23TextImage: { type: PathType.SECTION, path: 'text-image' },
  nx23PromotionalBanner: { type: PathType.SECTION, path: 'promotional-banner' },
  nx23ContainerX: { type: PathType.SECTION, path: 'container-x' },
  nx23Footer: { type: PathType.SECTION, path: 'footer' },
  nx23Blog: { type: PathType.SECTION, path: 'blog' },
  nx23Carousel: { type: PathType.SECTION, path: 'carousel' },
  nx23DownloadAppButton: { type: PathType.ATOM, path: 'button-download-app' },
  nx23Link: { type: PathType.ATOM, path: 'link' },
  nx23Sitewide: { type: PathType.SECTION, path: 'sitewide' },
  nx23Title: { type: PathType.ATOM, path: 'title' },
  nx23Entry: { type: PathType.SECTION, path: 'entry' },
  nx23EntryMeta: { type: PathType.SECTION, path: 'entry-meta' },
  nx23Calculator: { type: PathType.ATOM, path: 'calculator' },
  nx23CardVideo: { type: PathType.ATOM, path: 'card-video' },
  nx23Video: { type: PathType.SECTION, path: 'video' },
  variantContainer: { type: PathType.SECTION, path: 'variant-container' },
};

@Injectable({ providedIn: 'root' })
export class ComponentInjectorService {
  // METHODS
  /**
   * Checks if a section can be injected, based on the component map
   * @param section Section to check if it can be injected
   * @returns `true` if the section can be injected, `false` otherwise
   */
  public canInject(section: ContentType) {
    const id = section.sys.contentType.sys.id;
    return id in componentMap;
  }

  /**
   * Load a component dynamically into the view container
   * @param container The container to load the component into
   * @param component The contentful component to load
   */
  public async injectComponent(
    section: ContentType,
    container: ViewContainerRef,
    injector: Injector,
    cdr: ChangeDetectorRef
  ) {
    const componentId = this.getComponentType(section);
    const moduleRef = componentMap[componentId];

    // No matching component file found
    if (!moduleRef) return;

    // Then lazy load the module
    const { module, component } = await this.lazyLoadModule(moduleRef);
    const componentRef = container.createComponent(component, {
      injector: injector,
      ngModuleRef: createNgModule(module, injector),
    });

    // Pass the data to the component
    componentRef.setInput('data', section.fields);
    cdr.detectChanges();
  }

  // PRIVATE METHODS
  /**
   * Gets the content-type id of the component
   * @param component Component to get the type of
   * @returns A string representing the type of the component
   */
  private getComponentType(component: ContentType) {
    return component.sys.contentType.sys.id;
  }

  /**
   * Lazy load an Angular module
   * @param path Name of the file containing the module to load
   * @returns A promise with the module and component
   */
  private async lazyLoadModule({ type, path }: Path) {
    const file = await import(`../../${type}/${path}/${path}.module`);
    return {
      module: file.default,
      component: file.default.component,
    };
  }
}
