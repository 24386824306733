import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeadingRendererModule } from '@/tooling/heading-renderer/heading-renderer.module';
import CardLargeModule from '@/atoms/card-large/card-large.module';
import CardModule from '@/atoms/card/card.module';
import CardXlModule from '@/atoms/card-xl/card-xl.module';

import { GenericGridSectionComponent } from './generic-grid-section.component';
import { EquisPaginatorBigModule } from '@tn-equis/core/components/paginator-big';
import { GroupOfCardsComponent } from './group-of-cards/group-of-cards.component';
import { CardVideoComponent } from '../../atoms/card-video/card-video.component';
import { CardCountdownComponent } from '../../atoms/card-countdown/card-countdown.component';

@NgModule({
  declarations: [GenericGridSectionComponent, GroupOfCardsComponent],
  imports: [
    CommonModule,
    CardModule,
    CardLargeModule,
    CardXlModule,
    CardVideoComponent,
    CardCountdownComponent,
    HeadingRendererModule,
    EquisPaginatorBigModule,
  ],
  exports: [GenericGridSectionComponent],
})
export default class GenericGridSectionModule {
  public static component = GenericGridSectionComponent;
}
