import { Component, Input, Optional } from '@angular/core';

import { DataLayerService } from '@/services/data-layer.service';
import { NX23NavbarTab } from '@/types/content-types';
import { SectionService } from '@/services/section.service';

import { INavbarTabs } from './navbar-tabs.types';
import { AmplitudeExperiment } from '../../../../../common/types/data-layer';
import { VariantContainerComponent } from '../../../variant-container/variant-container.component';

@Component({
  selector: 'nx-navbar-tabs',
  templateUrl: './navbar-tabs.component.html',
  styleUrls: ['./navbar-tabs.component.scss'],
})
export class NavbarTabsComponent implements INavbarTabs {
  constructor(
    private dataLayer: DataLayerService,
    private section: SectionService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  // REQUIRED INPUTS
  @Input() data!: INavbarTabs['data'];

  // METHODS
  clickTab(tab: NX23NavbarTab) {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    this.dataLayer.push(
      tab.fields.dataLayer,
      this.section.sectionName,
      experiment
    );
  }
}
