import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquisTextfieldModule } from '@tn-equis/core/components/textfield';
import {
  EquisControlMessageModule,
  EquisControlMessageStatus,
  IEquisControlMessageBullet,
} from '@tn-equis/core/components/control-message';
import { DirectivesModule } from '../../../directives/directives.module';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { EquisCheckboxsModule } from '@tn-equis/core/components/checkboxs';
import { ButtonComponent } from '../button/button.component';
import { EquisStickerAnimationModule } from '@tn-equis/core/components/sticker-animation';
import { CountdownDataService } from './countdown.service';
import { take, tap } from 'rxjs';
import { EquisModalDialogService } from '@tn-equis/core/components/modal-dialog';
declare const braze: any;
declare global {
  interface Window {
    dataLayer: any[];
    amplitude?: any;
  }
}

@Component({
  selector: 'nx-form-countdown',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    CommonModule,
    EquisTextfieldModule,
    EquisControlMessageModule,
    DirectivesModule,
    ReactiveFormsModule,
    EquisCheckboxsModule,
    ButtonComponent,
    EquisStickerAnimationModule,
  ],
  styleUrls: ['./form-countdown.component.scss'],
  template: `
    <div class="overlay"></div>
    <div class="success-message" *ngIf="isFormSubmitted">
      <equis-sticker-animation
        [autoPlay]="true"
        [loop]="true"
        width="180"
        height="180"
        [loop]="false"
        [path]="'./assets/stickers/success/success.json'"
      >
      </equis-sticker-animation>
      <h2>¡LISTO!</h2>
      <p>Ya estás participando.</p>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!isFormSubmitted">
      <div class="wrapper">
        <div>
          <equis-textfield label="Nombre completo">
            <input
              equisTextfieldInput
              formControlName="name"
              [autocomplete]="'off'"
            />
          </equis-textfield>
          <equis-control-message
            *ngIf="form.get('name')?.invalid && form.get('name')?.touched"
            [bulletList]="bullets[0]"
          >
          </equis-control-message>
        </div>

        <div>
          <equis-textfield label="DNI">
            <input
              equisTextfieldInput
              (ngModelChange)="onlyNumbers($event, 'dni')"
              formControlName="dni"
              maxlength="8"
              [autocomplete]="'off'"
              inputmode="numeric"
            />
          </equis-textfield>
          <equis-control-message
            *ngIf="form.get('dni')?.invalid && form.get('dni')?.touched"
            [bulletList]="bullets[1]"
          >
          </equis-control-message>
        </div>

        <div>
          <equis-textfield label="Email">
            <input
              equisTextfieldInput
              formControlName="email"
              [autocomplete]="'off'"
            />
          </equis-textfield>

          <equis-control-message
            *ngIf="form.get('email')?.invalid && form.get('email')?.touched"
            [bulletList]="bullets[2]"
          >
          </equis-control-message>
        </div>

        <div>
          <equis-textfield label="Teléfono">
            <input
              equisTextfieldInput
              (ngModelChange)="onlyNumbers($event, 'phone')"
              formControlName="phone"
              maxlength="10"
              [autocomplete]="'off'"
              inputmode="numeric"
            />
          </equis-textfield>
          <equis-control-message
            text="Sin el 0 ni el 15"
          ></equis-control-message>
          <equis-control-message
            *ngIf="form.get('phone')?.invalid && form.get('phone')?.touched"
            [bulletList]="bullets[3]"
          >
          </equis-control-message>
        </div>

        <div class="checkbox">
          <equis-checkbox
            [checkbox]="checkbox"
            (checkboxChecked)="checkSelected()"
          ></equis-checkbox>
          <p>
            Acepto
            <a
              href="https://assets.ctfassets.net/yxlyq25bynna/3Kw9I1zykT8faqZsbIBQXk/b19ed5c52694fb4034e509520f066131/BASES_Y_CONDICIONES_-_PaloxPalo.pdf"
              target="_blank"
              >Términos y Condiciones</a
            >
          </p>
        </div>

        <app-button
          text="Enviar"
          width="100%"
          (click)="onSubmit()"
          [disabled]="!form.valid"
        ></app-button>
      </div>
    </form>
  `,
})
export class FormCountdownComponent implements OnInit {
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly countdownDataService: CountdownDataService,
    private readonly dialogService: EquisModalDialogService
  ) {}
  public form!: FormGroup;
  public isFormSubmitted = false;
  private countdownData!: any;
  public bullets: IEquisControlMessageBullet[][] = [
    [
      {
        text: `El nombre es requerido`,
        status: EquisControlMessageStatus.ERROR,
      },
    ],
    [
      {
        text: `El DNI no es válido`,
        status: EquisControlMessageStatus.ERROR,
      },
    ],
    [
      {
        text: `El email no es válido`,
        status: EquisControlMessageStatus.ERROR,
      },
    ],
    [
      {
        text: `El teléfono no es válido`,
        status: EquisControlMessageStatus.ERROR,
      },
    ],
  ];

  public checkbox = {
    id: 1,
    disabled: false,
    selected: false,
    indeterminate: false,
    text: '',
  };

  ngOnInit(): void {
    this.initForm();
    this.countdownDataService
      .getData()
      .pipe(
        take(1),
        tap((data) => (this.countdownData = data))
      )
      .subscribe();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      dni: ['', [Validators.required, Validators.pattern(/^\d{7,8}$/)]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ],
      ],
      phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      terms: [false, [Validators.required, Validators.requiredTrue]],
    });
  }

  public checkSelected() {
    this.checkbox.selected = !this.checkbox.selected;
    this.form.get('terms')?.setValue(this.checkbox.selected);
  }

  public onlyNumbers(event: any, formControlName: string) {
    if (isNaN(event)) {
      this.form.get(formControlName)?.setValue(event.slice(0, -1));
    }
  }

  public async onSubmit() {
    if (this.form.invalid) return;

    try {
      braze.getUser().setEmail(this.form.get('email')?.value);
      braze.getUser().setFirstName(this.form.get('name')?.value);
      braze.getUser().setPhoneNumber(this.form.get('phone')?.value);
      braze
        .getUser()
        .setCustomUserAttribute('dni', this.form.get('dni')?.value);
      braze.getUser().setCustomUserAttribute('idCampaña', 'Palo X Palo');
    } catch (error) {
      console.log(error, 'Send Data To Braze');
    } finally {
      this.logEventToBraze();
      this.pushToDataLayer(); //para GA4 y Amplitude
      this.closeDialog();
    }
  }

  private logEventToBraze() {
    braze.logCustomEvent('nxcom_paloxpalo', {
      action: 'confirmacion',
      id_palo: this.countdownData.id_palo,
      id_partido: this.countdownData.id_partido,
      id_minuto: this.countdownData.id_minuto,
      timestamp_end: this.countdownData.timestamp_end,
      amplitudeDeviceId: window?.amplitude?.getDeviceId(),
    });
    braze.requestImmediateDataFlush();
    this.setSuccessMessage();
  }

  private closeDialog() {
    setTimeout(() => {
      this.dialogService.closeModalDialog({});
    }, 3500);
  }

  private setSuccessMessage() {
    window.document.getElementsByClassName('modal-dialog__header')[0].remove();
    this.isFormSubmitted = true;
  }

  get formValues() {
    return this.form.getRawValue() as {
      name: string;
      dni: string;
      phone: string;
      email: string;
    };
  }

  private pushToDataLayer() {
    const { email, dni, phone } = this.formValues;

    // push solo para GA4
    window.dataLayer.push({
      event: 'ga4.trackEvent',
      eventName: 'ui_interaction',
      eventParams: {
        flow: 'paloxpalo',
        action: 'click',
        element: 'confirmacion',
      },
      paloParams: {
        id_palo: this.countdownData.id_palo,
        id_partido: this.countdownData.id_partido,
        id_minuto: this.countdownData.id_minuto,
        timestamp_end: this.countdownData.timestamp_end,
      },
    });

    // push solo para amplitude analytics cuando no sea identificado
    if (window?.amplitude?.getUserId() == undefined) {
      window.dataLayer.push({
        event: 'amplitude.trackUserProperties',
        amplitude: {
          user_properties: {
            email: email,
            naranjaId: btoa(dni),
          },
        },
      });
    }

    braze.getDeviceId((deviceId: string) =>
      window.dataLayer.push({
        event: 'amplitude.trackEvent',
        amplitude: {
          eventName: 'nxcom_paloxpalo',
          eventProperties: {
            flow: 'paloxpalo',
            action: 'click',
            element: 'confirmacion',
            braze_deviceId: deviceId,
            naranjaId: btoa(dni),
            email,
            phone,
            id_palo: this.countdownData.id_palo,
            id_partido: this.countdownData.id_partido,
            id_minuto: this.countdownData.id_minuto,
            timestamp_end: this.countdownData.timestamp_end,
          },
        },
      })
    );
  }
}
