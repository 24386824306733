// Idea extracted from: https://medium.com/@baunov/dynamic-meta-tags-for-angular-app-part-1-dc5957af202c

import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { environment } from '@/environment';
import { DOCUMENT } from '@angular/common';
import { PlatformService } from './platform.service';
import { StructuredData } from '@/types/contentful';

export interface PageMetadata {
  title: string;
  description: string;
  author: string;
  keywords: string;
  type: string;
  image: string;
  imageAlt: string;
  twitterCard: string;
  twitterUser: string;
  structuredData?: StructuredData;
  index?: boolean;
}

const defaultMetadata: PageMetadata = {
  title: 'Naranja X | Tarjeta de crédito, débito, cuenta gratis y más',
  description:
    'Una cuenta gratis donde tu plata crece, tarjeta de crédito y débito, préstamos y promociones con Plan Z. Ingresá para ver que podés hacer con Naranja X.',
  author: 'NaranjaX',
  keywords: 'NaranjaX, Tarjetas, cuenta gratis',
  type: 'website',
  image: '/assets/images/logo-nx.svg',
  imageAlt: 'Naranja X | Tarjeta de crédito, débito, cuenta gratis y más',
  twitterCard: 'summary_large_image',
  twitterUser: '@NaranjaX',
  structuredData: undefined,
  index: false,
};

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  constructor(
    private metaTagService: Meta,
    private titleService: Title,
    @Inject(DOCUMENT) private doc: Document,
    private platform: PlatformService
  ) {}

  public updateMetadata(metadata: Partial<PageMetadata>): void {
    const canonicalUrl = this.platform.getAbsoluteURL();
    // Only index if the environment is production and the page is indexable
    const shouldIndex = environment.production && metadata.index;
    const pageMetadata: PageMetadata = { ...defaultMetadata, ...metadata };

    const metatags: MetaDefinition[] =
      this.generateMetaDefinitions(pageMetadata);

    this.metaTagService.addTags([
      ...metatags,
      {
        property: 'og:url',
        content: canonicalUrl,
      },
      { name: 'robots', content: shouldIndex ? 'index, follow' : 'noindex' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
    ]);

    this.titleService.setTitle(pageMetadata.title);
    this.createCanonicalLink(canonicalUrl);

    // Structured data
    this.generateBreadcrumbs();
    this.generateGenericStructuredData(pageMetadata.structuredData);
  }

  private generateMetaDefinitions(metadata: PageMetadata): MetaDefinition[] {
    const title = metadata.title ? metadata.title : defaultMetadata.title;
    const description = metadata.description
      ? metadata.description
      : defaultMetadata.description;
    const author = metadata.author ? metadata.author : defaultMetadata.author;
    const keywords = metadata.keywords
      ? metadata.keywords
      : defaultMetadata.keywords;

    const image = metadata.image ? metadata.image : defaultMetadata.image;
    const imageAlt = metadata.imageAlt
      ? metadata.imageAlt
      : defaultMetadata.imageAlt;

    return [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'author', content: author },
      { name: 'keywords', content: keywords },

      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:author', content: author },
      { property: 'og:type', content: metadata.type },

      { property: 'og:image', content: image },
      { property: 'og:image:alt', content: imageAlt },
      { property: 'og:image:width', content: '1200' },
      { property: 'og:image:height', content: '630' },
      { property: 'og:image:type', content: 'image/jpg' },

      { property: 'twitter:title', content: title },
      { property: 'twitter:description', content: description },
      { property: 'twitter:card', content: metadata.twitterCard },
      { property: 'twitter:site', content: metadata.twitterUser },
      { property: 'twitter:creator', content: metadata.twitterUser },
    ];
  }

  private createCanonicalLink(url: string) {
    if (!this.platform.isServer) return;
    const { origin, pathname } = new URL(url);
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', origin + pathname);
    this.doc.head.appendChild(link);
  }

  /**
   * Generate a list of breadcrums for the current route and appends it to the head of the document.
   */
  private generateBreadcrumbs() {
    const sanitizeName = (str: string) =>
      (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

    const url = this.platform.getAbsoluteURL();
    // Remove the host URL from the absolute URL
    const path = url.replace(environment.HOST_URL, '');
    // Add empty string to the beginning to generate the home breadcrumb
    const parts = ['', ...path.split('/').filter((part) => Boolean(part))];

    const breadcrumbs = parts.map((part, i) => {
      const breadcrumbUrl = `${environment.HOST_URL}/${parts
        .slice(0, i + 1)
        // Remove empty part
        .filter((part) => Boolean(part))
        .join('/')}`;

      return {
        '@type': 'ListItem',
        position: i + 1, // Counting starts from 1
        name: sanitizeName(part === '' ? 'Inicio' : part),
        item: breadcrumbUrl,
      };
    });

    this.appendGenericStructuredData({
      '@context': 'https://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs,
    });
  }

  private generateGenericStructuredData(data: StructuredData | undefined) {
    if (data) this.appendGenericStructuredData(data);
  }

  /**
   * Generates and appends a generic structured data script to the head of the document.
   * @param type Structured data type. See https://schema.org/docs/schemas.html
   * @param object Object to be appended to the script
   */
  private appendGenericStructuredData(object: any) {
    this.doc.getElementById(object['@type'])?.remove();
    const script: HTMLScriptElement = this.doc.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    script.setAttribute('id', object['@type']);
    script.text = JSON.stringify(object);

    this.doc.head.appendChild(script);
  }
}
