import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import LinkModule from '@/atoms/link/link.module';
import { ComponentInjectorModule } from '@/tooling/component-injector/component-injector.module';
import { RichTextRendererModule } from '@/tooling/rich-text-renderer/rich-text-renderer.module';
import { HeadingRendererModule } from '@/tooling/heading-renderer/heading-renderer.module';

import { TextContentComponent } from './text-content.component';

@NgModule({
  declarations: [TextContentComponent],
  imports: [
    CommonModule,
    LinkModule,
    ComponentInjectorModule,
    RichTextRendererModule,
    HeadingRendererModule,
  ],
  exports: [TextContentComponent],
})
export default class TextContentModule {
  public static component = TextContentComponent;
}
