import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import type { ITitle } from './title.types';

@Component({
  selector: 'nx-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleComponent implements ITitle {
  @Input() data!: ITitle['data'];
}
