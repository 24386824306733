<div>
  <div class="tab-content-item">
    <ng-container *ngIf="hasDropdown">
      <button
        class="dropdown-container"
        tabindex="0"
        (click)="toggleDropdown()"
      >
        <p class="equis-body-1-medium">
          <ng-container *ngTemplateOutlet="tabContent"></ng-container>
        </p>
        <equis-icon-container
          class="angle-icon"
          [class.rotate]="isDropdownActive"
          [containerColor]="'#f3eef6'"
          [iconColor]="'#5b5b5b'"
          [icon]="'icon-angle-down'"
        >
        </equis-icon-container>
      </button>
    </ng-container>

    <ng-container *ngIf="!hasDropdown">
      <a
        class="equis-body-1-medium"
        [routerLink]="platform.getRelativeUrl(contentHref)"
        [nxAbsoluteAnchor]="contentHref"
        (click)="onClick(link)"
      >
        <ng-container *ngTemplateOutlet="tabContent"></ng-container>
      </a>
      <equis-icon-container
        [containerColor]="'#f3eef6'"
        [iconColor]="'#5b5b5b'"
        [icon]="''"
      >
      </equis-icon-container>
    </ng-container>
  </div>

  <ul
    *ngIf="hasDropdown"
    [class.open]="isDropdownActive"
    [ngClass]="{ 'dropdown--small': itemsLength > 6 }"
  >
    <li *ngFor="let content of dropdown; let i = index">
      <a
        class="equis-body-1 dropdown"
        [routerLink]="platform.getRelativeUrl(content.fields.url)"
        [nxAbsoluteAnchor]="content.fields.url"
        (click)="onClick(content)"
        >{{ content.fields.label }}</a
      >
    </li>
  </ul>
</div>

<ng-template #tabContent>
  <ng-content></ng-content>
</ng-template>
