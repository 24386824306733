<nx-card-small
  *ngIf="data.size === 'Small'"
  [data]="data"
  [title]="data.description ? data.description : data.title"
  (click)="pushClick()"
></nx-card-small>
<nx-card-medium
  *ngIf="data.size === 'Medium'"
  [data]="data"
  [title]="data.description ? data.description : data.title"
  (click)="pushClick()"
></nx-card-medium>
