import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { PageService } from '@/services/page.service';
import { ComponentInjectorService } from '@/tooling/component-injector/component-injector.service';
import { ComponentInjectorComponent } from '../component-injector/component-injector.component';
import { tap } from 'rxjs/internal/operators/tap';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { IEnviroment } from '../../../common/types/enviroment';
import { PlatformService } from '../../../services/platform.service';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
declare const amplitude: any;
const enviromentState = makeStateKey<IEnviroment>('enviroment');
@Component({
  selector: 'nx-page-builder',
  templateUrl: './page-builder.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageBuilderComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  @ViewChild('component', { read: ViewContainerRef, static: true })
  component!: ViewContainerRef;

  constructor(
    private readonly service: ComponentInjectorService,
    private readonly pageService: PageService,
    private readonly cdr: ChangeDetectorRef,
    private platform: PlatformService,
    private readonly transferState: TransferState,
    @Optional()
    @Inject('enviromentFromVault')
    public enviroment: IEnviroment
  ) {
    if (this.platform.isServer) {
      this.transferState.set(enviromentState, this.enviroment);
    }
  }

  ngOnInit(): void {
    this.pageService.sections
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged(),
        tap(() => this.component.clear()),
        tap((data) => {
          let index = 0;
          for (let i in data) {
            if (!this.service.canInject(data[i])) {
              continue;
            }
            const ref = this.component.createComponent(
              ComponentInjectorComponent,
              {
                index: Number(index),
              }
            );
            ref.setInput('component', data[i]);
            index += 1;
          }
        }),
        tap(() => this.cdr.markForCheck())
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
