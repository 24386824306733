import { Component, Input } from '@angular/core';

import { ColorContrastService } from '@/services/color-contrast.service';
import { SectionService } from '@/services/section.service';

import type { IGenericGridSection } from './generic-grid-section.types';

@Component({
  selector: 'nx-generic-grid-section',
  templateUrl: './generic-grid-section.component.html',
  styleUrls: ['./generic-grid-section.component.scss'],
  providers: [SectionService],
})
export class GenericGridSectionComponent implements IGenericGridSection {
  // REQUIRED INPUTS
  @Input() data!: IGenericGridSection['data'];

  constructor(
    private colorContrastService: ColorContrastService,
    section: SectionService
  ) {
    section.sectionName = 'cards';
  }

  get textColor(): string {
    if (this.data.backgroundColor) {
      return this.colorContrastService.decideTextColor(
        this.data.backgroundColor
      );
    }
    // Return a default text color if backgroundColor is undefined
    return 'black'; // or any other default color you prefer
  }

  get paddingClass() {
    const className = {
      'Reducir espaciado superior': 'padding_top',
      'Reducir espaciado inferior': 'padding_button',
      'Reducir espaciado superior e inferior': 'padding_both',
    };
    return className[this.data?.padding] ?? null;
  }
}
