import { Component, Input } from '@angular/core';

import { SectionService } from '@/services/section.service';

import type { IFeaturedInformation } from './featured-information.types';

@Component({
  selector: 'nx-featured-information',
  templateUrl: './featured-information.component.html',
  styleUrls: ['./featured-information.component.scss'],
  providers: [SectionService],
})
export class FeaturedInformationComponent implements IFeaturedInformation {
  constructor(section: SectionService) {
    section.sectionName = this.SECTION_NAME;
  }
  // REQUIRED INPUTS
  @Input() data!: IFeaturedInformation['data'];

  // PROPERTIES
  private readonly SECTION_NAME = 'content';

  get columns() {
    return this.data.informationList.length;
  }

  get backgroundColor() {
    return this.data.backgroundColor;
  }

  get paddingClass() {
    const className = {
      'Reducir espaciado superior': 'padding_top',
      'Reducir espaciado inferior': 'padding_button',
      'Reducir espaciado superior e inferior': 'padding_both',
    };
    return className[this.data?.padding] ?? null;
  }
}
