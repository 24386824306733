import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TitleComponent } from './title.component';
import { SectionService } from '../../../services/section.service';
import { ComponentInjectorModule } from '../../tooling/component-injector/component-injector.module';

@NgModule({
  declarations: [TitleComponent],
  imports: [CommonModule, ComponentInjectorModule],
  exports: [TitleComponent],
  providers: [SectionService],
})
export default class TitleModule {
  public static component = TitleComponent;
}
