import { Component, Input, Optional } from '@angular/core';

import { DataLayerService } from '@/services/data-layer.service';
import { SectionService } from '@/services/section.service';

import { IPreFooter } from './pre-footer.types';
import { Router } from '@angular/router';
import { AmplitudeExperiment } from '../../../../common/types/data-layer';
import { VariantContainerComponent } from '../../variant-container/variant-container.component';

@Component({
  selector: 'nx-pre-footer',
  templateUrl: './pre-footer.component.html',
  styleUrls: ['./pre-footer.component.scss'],
})
export class PreFooterComponent implements IPreFooter {
  constructor(
    private dataLayer: DataLayerService,
    private section: SectionService,
    private router: Router,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}
  // REQUIRED INPUTS
  @Input() data!: IPreFooter['data'];

  // METHODS
  pushGoHome(event: Event) {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    this.dataLayer.push(
      {
        eventName: 'ui_interaction',
        eventParams: {
          section: 'prefooter',
          action: 'click',
          element: 'ir al inicio',
        },
      },
      this.section.sectionName,
      experiment
    );
    event.preventDefault();
    this.router.navigate(['/']);
  }
}
