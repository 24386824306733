import { Asset } from '@/types/contentful';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AssetDetails } from 'contentful';

@Directive({
  selector: '[nxContentfulImage]',
})
export class ContentfulImageDirective implements OnInit {
  constructor(private el: ElementRef<HTMLPictureElement>) {}

  // REQUIRED INPUTS
  @Input() nxContentfulImage!: Asset;
  @Input() forceSize = true;

  // FIELDS
  private get fields() {
    return this.nxContentfulImage.fields;
  }
  private readonly SOURCES = [
    {
      media: '(min-width: 1200px)',
      srcset: '?fm=avif',
      type: 'image/avif',
      scale: 1,
    },
    {
      media: '(min-width: 768px)',
      srcset: '?fm=avif',
      type: 'image/avif',
      scale: 1,
    },
    {
      media: '(min-width: 280px)',
      srcset: '?fm=avif',
      type: 'image/avif',
      scale: 1,
    },
  ];

  // PRIVATE METHODS
  private hasImageDetails(details: any): details is AssetDetails {
    return 'image' in details;
  }

  private setSize(
    element: HTMLImageElement | HTMLSourceElement,
    scale: number = 1
  ) {
    if (this.forceSize && this.hasImageDetails(this.fields.file?.details)) {
      const width = this.fields.file?.details.image?.width as number;
      const height = this.fields.file?.details.image?.height as number;

      element.width = this.capSize(width);
      element.height = this.capSize(height);

      if (element.srcset) {
        element.srcset = `${element.srcset}&w=${Math.round(width * scale)}`;
      }
    }
  }

  private initImage() {
    const image = this.el.nativeElement.querySelector<HTMLImageElement>('img');
    if (image) {
      image.alt = this.fields.description as string;
      image.src = `${this.fields.file?.url}?q=85&fm=webp`;
      this.setSize(image);
    }
  }

  private initSources() {
    const sources =
      this.el.nativeElement.querySelectorAll<HTMLSourceElement>('source');
    const array = Array.from(sources);

    array.forEach((source, i) => {
      const config = this.SOURCES[i];

      source.media = config.media;
      source.srcset = `${this.fields.file?.url}${config.srcset}`;
      this.setSize(source, config.scale);
    });
  }

  /**
   * Caps the size at 4000px, which is the limit of the Contentful image API
   * @param size Size to evaluate
   * @returns The size capped at 4000px
   */
  private capSize(size: number) {
    // Contentful image API has a max width of 4000px as specified by
    // https://www.contentful.com/developers/docs/references/images-api/#/reference/changing-formats/8-bit-pngs
    const CFUL_MAX_WIDTH = 4000;
    return Math.min(size, CFUL_MAX_WIDTH);
  }

  // HOOKS
  ngOnInit(): void {
    this.initImage();
    this.initSources();
  }
}
