import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  EquisButtonBaseSizes,
  EquisButtonBaseTypes,
} from '@tn-equis/core/components/button';

import { PlatformService } from '@/services/platform.service';
import { mapStyle } from '@/utils/link';

import { DataLayerService } from '@/services/data-layer.service';
import { SectionService } from '@/services/section.service';

import type { IButtonDownloadApp } from './button-download-app.types';
import { ButtonDownloadAppService } from './button-download-app.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { VariantContainerComponent } from '../../sections/variant-container/variant-container.component';
import { AmplitudeExperiment } from '../../../common/types/data-layer';

@Component({
  selector: 'nx-button-download-app',
  templateUrl: './button-download-app.component.html',
  styleUrls: ['./button-download-app.component.scss'],
})
export class ButtonDownloadAppComponent
  implements IButtonDownloadApp, AfterViewInit, OnDestroy
{
  constructor(
    public platform: PlatformService,
    private service: ButtonDownloadAppService,
    private dataLayer: DataLayerService,
    private section: SectionService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  // REQUIRED INPUTS
  @Input() data!: IButtonDownloadApp['data'];
  // OPTIONAL INPUTS
  @Input() size: IButtonDownloadApp['size'] = EquisButtonBaseSizes.LARGE;
  @Input() width?: IButtonDownloadApp['width'] = 'full';

  // VIEW ELEMENTS
  @ViewChild('floatingDiv', { static: false })
  floating!: ElementRef<HTMLDivElement>;
  public showButtonSticky = true;

  // PROPERTIES
  /**
   * The threshold in percentage of the scroll height
   */
  private readonly SCROLL_THRESHOLD = 5;
  buttonType = EquisButtonBaseTypes.PRIMARY;
  get text() {
    return this.data.label;
  }

  /**
   * Gets the button style, based on the style input.
   */
  get buttonStyle() {
    return mapStyle(this.data.style);
  }

  get buttonWidth() {
    return this.width === 'full' ? '100%' : 'auto';
  }

  // METHODS
  /**
   * Shows the dialog on browsers and redirects to the app store on mobile
   */
  onClick() {
    this.dataLayerPush();
    if (this.platform.isMobile)
      this.service.redirectToApp(this.data.mobileLink);
    else this.service.show(this.data.dialogTitle);
  }

  // PRIVATE METHODS
  private handleScroll(event: Event) {
    if (!this.showButtonSticky) return;
    const target = (event.target as Document).scrollingElement;
    const div = this.floating.nativeElement;
    if (target) {
      // Scroll values
      const scrollHeight = target.scrollHeight;
      const scrollTop = target.scrollTop;
      const clientHeight = target.clientHeight;
      const percentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

      const passedThreshold = percentage < this.SCROLL_THRESHOLD;
      const isHidden = div.classList.contains('hide');

      if (passedThreshold && !isHidden) {
        this.floating.nativeElement.classList.remove('show');
        this.floating.nativeElement.classList.add('hide');
      } else if (!passedThreshold && isHidden) {
        this.floating.nativeElement.classList.add('show');
        this.floating.nativeElement.classList.remove('hide');
      }
    }
  }

  // PRIVATE METHODS
  private dataLayerPush() {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    const data = this.data.dataLayer;
    if (!data) return;

    if (this.data.isFloating)
      this.dataLayer.push(
        {
          eventName: data.eventName,
          eventParams: {
            ...data.eventParams,
            component: 'sticky mobile',
          },
        },
        'sticky mobile',
        experiment
      );
    else
      this.dataLayer.push(
        this.data.dataLayer,
        this.section.sectionName,
        experiment
      );
  }

  // HOOKS
  ngAfterViewInit(): void {
    if (this.platform.isBrowser && this.data.isFloating)
      window.addEventListener('scroll', this.handleScroll.bind(this));

    // Store the data from contentful into the service
    this.service.dialogData = {
      formHint: this.data.formHint,
      qrHint: this.data.qrHint,
      qr: this.data.qr,
      dataLayer: this.data.dataLayer,
      section: this.section.sectionName ?? '',
    };

    if (this.platform.isServer) return;

    if (sessionStorage.getItem('utm_source_app')) {
      this.showButtonSticky = false;
      this.cdr.detectChanges();
      return;
    }

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (params['utm_source'] === 'app') {
        sessionStorage.setItem('utm_source_app', 'true');
        this.showButtonSticky = false;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.platform.isBrowser && this.data.isFloating) {
      window.removeEventListener('scroll', this.handleScroll.bind(this));
    }
  }
}
