import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentInjectorComponent } from './component-injector.component';

@NgModule({
  declarations: [ComponentInjectorComponent],
  imports: [CommonModule],
  exports: [ComponentInjectorComponent],
})
export class ComponentInjectorModule {}
