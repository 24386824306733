import { Component, Input } from '@angular/core';

@Component({
  selector: 'nx-feedback-message',
  templateUrl: './feedback-message.component.html',
  styleUrls: ['./feedback-message.component.scss']
})
export class FeedbackMessageComponent {
  @Input() title!: string;
  @Input() message!: string;
  @Input() stickerPath!: string;
}
