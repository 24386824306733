import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@/directives/directives.module';

import { QuicklinkComponent } from './quicklink.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [QuicklinkComponent],
  imports: [CommonModule, DirectivesModule, RouterModule],
  exports: [QuicklinkComponent],
})
export default class QuicklinkModule {
  public static component = QuicklinkComponent;
}
