import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VariantContainerComponent } from './variant-container.component';
import { SectionService } from '../../../services/section.service';
import { ComponentInjectorModule } from '../../tooling/component-injector/component-injector.module';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [VariantContainerComponent],
  imports: [CommonModule, ComponentInjectorModule],
  exports: [VariantContainerComponent],
  providers: [SectionService, { provide: LOCALE_ID, useValue: 'es' }],
})
export default class VariantContainerModule {
  public static component = VariantContainerComponent;
}
