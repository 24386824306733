<div class="card card-with-url" (click)="actionDialog('open')">
  <div class="image-wrapper">
    <equis-icon-container
      icon="icon-play"
      containerColor="unset"
      iconColor="#e2e2e2"
      [size]="iconSize"
    >
    </equis-icon-container>
    <picture [nxContentfulImage]="data.image">
      <source />
      <source />
      <source />
      <img loading="lazy" />
    </picture>
  </div>
  <div class="text-wrapper">
    <h3 class="title equis-h2-medium">
      {{ data.title }}
    </h3>
  </div>
</div>

<dialog [id]="id">
  <equis-icon-container
    *ngIf="source | async"
    icon="icon-cross"
    (click)="actionDialog('close')"
    containerColor="unset"
    iconColor="#e2e2e2"
  >
  </equis-icon-container>
  <div class="video__wrapper" *ngIf="source | async">
    <img
      class="logo"
      src="/assets/images/navbar-isologo-naranjax.png"
      alt="Isologo Naranja X"
      width="130"
      height="30"
      loading="eager"
    />
    <iframe
      [src]="source | async"
      allow="autoplay"
      width="100%"
      height="100%"
      frameborder="0"
      allowfullscreen="allowfullscreen"
    ></iframe>
  </div>
</dialog>
