<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="wrapper">
    <h2 class="title">Calculá tus ganancias con NaranjaX</h2>
    <div class="margin-bottom-6">
      <equis-textfield [label]="'Monto inicial'">
        <input
          equisTextfieldInput
          nxNumbersOnly
          formControlName="amount"
          [autocomplete]="'off'"
        />
      </equis-textfield>
      <equis-control-message
        *ngIf="amount?.hasError('max')"
        [bulletList]="amountMax"
      >
      </equis-control-message>
    </div>
    <div class="margin-bottom-24">
      <equis-textfield [label]="'Cantidad de días'">
        <input
          equisTextfieldInput
          nxNumbersOnly
          formControlName="days"
          [autocomplete]="'off'"
        />
      </equis-textfield>
      <equis-control-message
        *ngIf="days?.hasError('max')"
        [bulletList]="daysMax"
      ></equis-control-message>
    </div>

    <app-button
      text="Calcular"
      type="primary"
      size="medium"
      width="100%"
      [disabled]="form.invalid"
    ></app-button>

    <ng-container *ngIf="result">
      <span class="result">{{ result }}</span>
    </ng-container>

    <equis-control-message
      class="result--items"
      *ngIf="isSubmited"
      [bulletList]="bulletList"
    ></equis-control-message>
  </div>
</form>
