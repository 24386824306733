<section [class]="paddingClass">
  <nx-heading-renderer
    *ngIf="data.title && data.titleHierarchy"
    class="equis-h1-semibold"
    [data]="data.titleHierarchy"
    >{{ data.title }}</nx-heading-renderer
  >
  <ul class="container accordion-list">
    <li *ngFor="let accordion of data.accordionList">
      <nx-accordion [data]="accordion"></nx-accordion>
    </li>
  </ul>

  <div *ngIf="isFAQ" class="container faq-wrapper">
    <p class="equis-h3-medium">{{ HELP_TITLE }}</p>
    <nx-component-injector [component]="data.cta"> </nx-component-injector>
  </div>
</section>
