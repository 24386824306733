<h1
  *ngIf="data === 'h1'"
  [ngClass]="{
    'black-text': color === 'black',
    'white-text': color === 'white'
  }"
>
  <ng-container *ngTemplateOutlet="headerContent"></ng-container>
</h1>
<h2
  *ngIf="data === 'h2'"
  [ngClass]="{
    'black-text': color === 'black',
    'white-text': color === 'white'
  }"
>
  <ng-container *ngTemplateOutlet="headerContent"></ng-container>
</h2>
<h3
  *ngIf="data === 'h3'"
  [ngClass]="{
    'black-text': color === 'black',
    'white-text': color === 'white'
  }"
>
  <ng-container *ngTemplateOutlet="headerContent"></ng-container>
</h3>
<h4
  *ngIf="data === 'h4'"
  [ngClass]="{
    'black-text': color === 'black',
    'white-text': color === 'white'
  }"
>
  <ng-container *ngTemplateOutlet="headerContent"></ng-container>
</h4>
<h5
  *ngIf="data === 'h5'"
  [ngClass]="{
    'black-text': color === 'black',
    'white-text': color === 'white'
  }"
>
  <ng-container *ngTemplateOutlet="headerContent"></ng-container>
</h5>
<h6
  *ngIf="data === 'h6'"
  [ngClass]="{
    'black-text': color === 'black',
    'white-text': color === 'white'
  }"
>
  <ng-container *ngTemplateOutlet="headerContent"></ng-container>
</h6>

<ng-template #headerContent>
  <ng-content></ng-content>
</ng-template>
