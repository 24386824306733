import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EquisModalDialogModule } from '@tn-equis/core/components/modal-dialog';
import { EquisDividerModule } from '@tn-equis/core/components/divider';
import { EquisTextfieldModule } from '@tn-equis/core/components/textfield';
import { EquisControlMessageModule } from '@tn-equis/core/components/control-message';

import { DirectivesModule } from '@/directives/directives.module';

import { ButtonDownloadAppComponent } from './button-download-app.component';
import { ModalDownloadAppComponent } from './modal-download-app/modal-download-app.component';
import { ButtonDownloadAppService } from './button-download-app.service';
import { WhatsappService } from './whatsapp.service';
import { ButtonComponent } from '../button/button.component';

@NgModule({
  declarations: [ButtonDownloadAppComponent, ModalDownloadAppComponent],
  imports: [
    CommonModule,
    // EquisButtonModule,
    ButtonComponent,
    EquisModalDialogModule,
    EquisDividerModule,
    EquisTextfieldModule,
    EquisControlMessageModule,
    ReactiveFormsModule,
    FormsModule,
    DirectivesModule,
  ],
  providers: [ButtonDownloadAppService, WhatsappService],
  exports: [ButtonDownloadAppComponent],
})
export default class ButtonDownloadAppModule {
  public static component = ButtonDownloadAppComponent;
}
