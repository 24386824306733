<div
  class="navbar-height"
  id="navbar-height"
  [ngClass]="{ 'navbar-height--server': platform.isServer }"
>
  <div
    class="before-navbar"
    [class.open-navbar]="isMenuOpen"
    id="before-navbar"
  >
    <div class="navbar">
      <nx-navbar-top
        (menuToggleEvent)="menuToggle($event)"
        [data]="data"
      ></nx-navbar-top>
      <nx-navbar-bottom
        class="desktop-content"
        [data]="data"
      ></nx-navbar-bottom>
      <nx-navbar-bottom
        class="mobile-content"
        *ngIf="isMenuOpen"
        [data]="data"
      ></nx-navbar-bottom>
    </div>
    <div class="cta-container mobile-content" *ngIf="isMenuOpen">
      <nx-link [data]="activeTab.signInButton"></nx-link>

      <nx-component-injector
        *ngIf="!!data.downloadAppButton"
        [component]="data.downloadAppButton"
      ></nx-component-injector>
    </div>
  </div>
</div>

<!-- FIXED BUTTON (FLOATING) -->
<nx-component-injector
  *ngIf="!!stickyComponent"
  [component]="stickyComponent"
></nx-component-injector>

<nx-navbar-bottom
  class="mobile-content"
  *ngIf="platform.isServer"
  [data]="data"
></nx-navbar-bottom>
