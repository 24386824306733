import { Component, Input } from '@angular/core';

@Component({
  selector: 'nx-info-panel',
  templateUrl: './info-panel.component.html',
})
export class InfoPanelComponent {
  @Input() title!: string;
  @Input() message!: string;
}
