import { NgModule } from '@angular/core';

import { ContentfulImageDirective } from './contentful-image.directive';
import { AbsoluteAnchorDirective } from './absolute-anchor.directive';
import { NumbersOnlyDirective } from './numbers-ony.directive';

@NgModule({
  declarations: [
    ContentfulImageDirective,
    AbsoluteAnchorDirective,
    NumbersOnlyDirective,
  ],
  exports: [
    ContentfulImageDirective,
    AbsoluteAnchorDirective,
    NumbersOnlyDirective,
  ],
})
export class DirectivesModule {}
