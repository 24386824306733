import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentInjectorModule } from '@/tooling/component-injector/component-injector.module';

import { CarouselComponent } from './carousel.component';

@NgModule({
  declarations: [CarouselComponent],
  imports: [CommonModule, ComponentInjectorModule],
  exports: [CarouselComponent],
  // CUSTOM SCHEMA is used to avoid errors with the custom elements provided by Swiper
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export default class CarouselModule {
  public static component = CarouselComponent;
}
