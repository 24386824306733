import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquisAccordionModule } from '@tn-equis/core/components/accordion';

import { RichTextRendererModule } from '@/tooling/rich-text-renderer/rich-text-renderer.module';
import { HeadingRendererModule } from '@/tooling/heading-renderer/heading-renderer.module';
import { ComponentInjectorModule } from '@/tooling/component-injector/component-injector.module';

import { AccordionListComponent } from './accordion-list.component';
import { AccordionComponent } from './accordion/accordion.component';

@NgModule({
  declarations: [AccordionListComponent, AccordionComponent],
  imports: [
    CommonModule,
    EquisAccordionModule,
    RichTextRendererModule,
    HeadingRendererModule,
    ComponentInjectorModule,
  ],
  exports: [AccordionListComponent, AccordionComponent],
})
export default class AccordionListModule {
  public static component = AccordionListComponent;
}
