import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {
  EquisDropdownTypes,
  EquisDropdownWidth,
  EquisDropdownDisplay,
  IEquisDropdownEmission,
  IEquisDropdown,
} from '@tn-equis/core/components/dropdown';
import { EquisButtonBaseSizes } from '@tn-equis/core/components/button';

import { DataLayer } from '@/types/contentful';
import { DataLayerService } from '@/services/data-layer.service';
import { SectionService } from '@/services/section.service';
import { ChatbotService } from '@/services/chatbot.service';

import { INavbarTop, DropdownProps } from './navbar-top.types';
import { ActiveTabService } from '../active-tab.service';
import { NX23Link } from '@/types/content-types';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { take } from 'rxjs/internal/operators/take';
import { AmplitudeExperiment } from '../../../../common/types/data-layer';
import { VariantContainerComponent } from '../../variant-container/variant-container.component';

@Component({
  selector: 'nx-navbar-top',
  templateUrl: './navbar-top.component.html',
  styleUrls: ['./navbar-top.component.scss'],
})
export class NavbarTopComponent implements INavbarTop, OnInit {
  constructor(
    private dataLayer: DataLayerService,
    private section: SectionService,
    public activeTab: ActiveTabService,
    private chatbot: ChatbotService,
    private router: Router,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  // REQUIRED INPUTS
  @Input() data!: INavbarTop['data'];

  // OUTPUTS
  @Output() menuToggleEvent: INavbarTop['menuToggleEvent'] = new EventEmitter();

  // PROPERTIES
  helpDropdownProps!: DropdownProps;
  isMenuOpen: boolean = false;

  readonly DOWNLOAD_APP_SIZE = EquisButtonBaseSizes.MEDIUM;
  readonly SIGNIN_SIZE = EquisButtonBaseSizes.MEDIUM;
  private readonly CHATBOT_TEXT = 'Chat';
  // Empty because it shouldn't redirect to any page
  private readonly CHATBOT_LINK = '';

  // METHODS
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.menuToggleEvent.emit(this.isMenuOpen);
  }

  // HANDLERS
  pushGoHome() {
    const experiment = this.getAmplitudeExperiment();
    const object: DataLayer = {
      eventName: 'ui_interaction',
      eventParams: {
        action: 'click',
        element: 'ir al inicio',
        section: 'preheader',
      },
    };
    this.dataLayer.push(object, this.section.sectionName, experiment);
  }
  pushHelp() {
    const experiment = this.getAmplitudeExperiment();
    const object: DataLayer = {
      eventName: 'ui_interaction',
      eventParams: {
        action: 'click',
        element: 'ayuda',
        section: 'preheader',
      },
    };
    this.dataLayer.push(object, this.section.sectionName, experiment);
  }
  pushLogin() {
    const experiment = this.getAmplitudeExperiment();
    const object: DataLayer = {
      eventName: 'ui_interaction',
      eventParams: {
        action: 'click',
        element: 'ingresar',
        section: 'preheader',
      },
    };
    this.dataLayer.push(object, this.section.sectionName, experiment);
  }
  onDropdownItemClick(item: IEquisDropdownEmission) {
    const experiment = this.getAmplitudeExperiment();
    const index = item.globalIndex;
    const dropdownItem =
      this.helpDropdownProps.dropdownList[index].optionList[0];
    const section = 'preheader :: ayuda';

    if (dropdownItem.isBot) {
      this.chatbot.show();
      this.dataLayer.push(
        this.chatbot.getDataLayer(this.CHATBOT_TEXT),
        section,
        experiment
      );
    } else {
      this.dataLayer.push(dropdownItem.dataLayer, section, experiment);
    }
  }

  // PRIVATE METHODS
  /**
   * Generates a single dropdown item.
   * @param item Item to generate the dropdown item. If it is undefined, it will generate the chatbot item.
   * @returns EquisDropdown object
   */
  private generateDropdownItem(item?: NX23Link): IEquisDropdown {
    return {
      optionList: [
        {
          text: item ? item.fields.label : this.CHATBOT_TEXT,
          link: item ? item.fields.url : this.CHATBOT_LINK,
          // If the item is the chatbot, it should open in the same tab
          openSameTab: true,
          type: EquisDropdownTypes.LINK,
          fontWeight: '900',
          textColor: '#333333',
          // Although dataLayer is not necessary to render the dropdown, it is necessary in the onClick event
          dataLayer: item?.fields?.dataLayer,
          isBot: !item,
        },
      ],
    };
  }
  /**
   * Generates the dropdown list.
   * @returns Dropdown list
   */
  private generateDropdownList(): IEquisDropdown[] {
    const dataArray = this.data.helpDropdown ?? [];
    const chatbot = this.generateDropdownItem();
    const array = dataArray.map((item) => this.generateDropdownItem(item));

    return [chatbot, ...array];
  }
  /**
   * Initializes the dropdown props.
   */
  private initDropdownProps() {
    this.helpDropdownProps = {
      dropdownDirection: EquisDropdownDisplay.BOTTOM_LEFT,
      marginTop: 4,
      marginSide: 8,
      widthType: EquisDropdownWidth.PIXELS,
      widthPixels: 300,
      closeOnClick: true,
      dropdownList: this.generateDropdownList(),
    };
  }

  /**
   * Get amplitude experiment to send to gtm if it exists
   */
  private getAmplitudeExperiment = (): AmplitudeExperiment => {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }
    return experiment;
  };

  // HOOKS
  ngOnInit() {
    this.initDropdownProps();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        this.isMenuOpen = false;
        this.menuToggleEvent.emit(false);
      });
  }
}
