<ng-container *ngIf="platform.isServer; else default">
  <a
    [href]="href"
    class="icon container-button equis-button container-button--{{
      size
    }} container-button--{{ size }}--{{ type }} container-button--{{ size }}--{{
      type
    }}--text-only button-{{ type }}-base"
    [ngClass]="{ 'button-primary-disable': disabled }"
    style="max-width: 100%; width: 100%"
    [target]="target"
    [rel]="getRelAnchor(href)"
  >
    <p class="container-button__text">
      {{ text }}
    </p>
  </a>
</ng-container>
<ng-template #default>
  <equis-button
    [loading]="loading"
    [disabled]="disabled"
    [width]="width"
    [text]="text"
    [icon]="icon"
    [iconPos]="iconPos"
    [type]="type"
    [size]="size"
    [id]="id"
    [fadeBackground]="fadeBackground"
    [href]="href"
    [target]="target"
    [responsive]="responsive"
  >
  </equis-button>
</ng-template>
