<div class="text-content-container">
  <div class="text-container" [class.alternative]="isAlternative">
    <!-- Big title -->
    <ng-container *ngIf="isBig; else isSmall">
      <nx-heading-renderer
        *ngIf="title && titleHierarchy"
        class="equis-hero-semibold"
        [ngClass]="{
          'black-text': textColor === 'black',
          'white-text': textColor === 'white'
        }"
        [data]="titleHierarchy"
        >{{ title }}</nx-heading-renderer
      >
    </ng-container>
    <!-- Small title -->
    <ng-template #isSmall>
      <nx-heading-renderer
        *ngIf="title && titleHierarchy"
        class="equis-h1-semibold"
        [ngClass]="{
          'black-text': textColor === 'black',
          'white-text': textColor === 'white'
        }"
        [data]="titleHierarchy"
        >{{ title }}</nx-heading-renderer
      >
    </ng-template>
    <!-- Description -->
    <nx-rich-text-renderer
      *ngIf="text"
      [text]="text"
      [ngClass]="{
        'black-text': textColor === 'black',
        'white-text': textColor === 'white'
      }"
    ></nx-rich-text-renderer>
  </div>
  <!-- Link / Button -->
  <!-- If ctaData === true, then we're sure ctaData will be defined -->
  <nx-component-injector
    *ngIf="ctaData"
    [component]="ctaData"
  ></nx-component-injector>
</div>
