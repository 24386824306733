<div class="flex">
  <equis-icon-container
    [icon]="iconProps.icon"
    [containerColor]="iconProps.containerColor"
    [iconColor]="iconProps.iconColor"
    [hover]="iconProps.hover"
    [size]="iconProps.size"
  >
  </equis-icon-container>
  <span class="equis-caption" [ngClass]="[isEnabledClass]">{{ text }}</span>
</div>
