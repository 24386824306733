import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import TextContentModule from '@/layouts/text-content/text-content.module';
import { DirectivesModule } from '@/directives/directives.module';

import { TextImageComponent } from './text-image.component';
import { PaddedImageComponent } from './padded-image/padded-image.component';
import { FullImageComponent } from './full-image/full-image.component';
import { CustomComponent } from './custom-component/custom.component';
import { ComponentInjectorModule } from '../../tooling/component-injector/component-injector.module';

@NgModule({
  declarations: [TextImageComponent, PaddedImageComponent, FullImageComponent, CustomComponent],
  imports: [CommonModule, TextContentModule, DirectivesModule, ComponentInjectorModule],
  exports: [TextImageComponent],
})
export default class TextImageModule {
  public static component = TextImageComponent;
}
