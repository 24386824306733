import { Component, Input, OnInit } from '@angular/core';
import { INavbarTab } from './navbar-tab.types';
import { ActiveTabService } from '@/sections/navbar/active-tab.service';
import { PlatformService } from '../../../../../../services/platform.service';

@Component({
  selector: 'nx-navbar-tab',
  templateUrl: './navbar-tab.component.html',
  styleUrls: ['./navbar-tab.component.scss'],
})
export class NavbarTabComponent implements INavbarTab, OnInit {
  constructor(
    private tabService: ActiveTabService,
    public platform: PlatformService
  ) {}

  // REQUIRED INPUTS
  @Input() tabUrl!: string;

  // PROPERTIES
  isActive = false;

  // HOOKS
  ngOnInit() {
    this.isActive = this.tabService.activeTab.fields.url === this.tabUrl;
  }
}
