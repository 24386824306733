<ul>
  <nx-navbar-tab
    class="nx-navbar-tab"
    *ngFor="let tab of data.tabs; let i = index"
    [tabUrl]="tab.fields.url"
    (click)="clickTab(tab)"
  >
    {{ tab.fields.label }}
  </nx-navbar-tab>
</ul>
