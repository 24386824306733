import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import TextContentModule from '@/layouts/text-content/text-content.module';
import CardModule from '@/atoms/card/card.module';
import { DirectivesModule } from '@/directives/directives.module';

import { BlogComponent } from './blog.component';

@NgModule({
  declarations: [BlogComponent],
  imports: [
    CommonModule,
    TextContentModule,
    CardModule,
    DirectivesModule,
  ],
  exports: [BlogComponent],
})
export default class BlogModule {
  public static component = BlogComponent;
}
