import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FeedbackFormData, Step } from './models';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { IEnviroment } from '../../common/types/enviroment';
import { PlatformService } from '../../services/platform.service';

const enviromentState = makeStateKey<IEnviroment>('enviroment');

@Component({
  selector: 'nx-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit {
  private RECAPTCHA_API_KEY!: string;
  constructor(
    private platform: PlatformService,
    private readonly transferState: TransferState,
    @Optional()
    @Inject('enviromentFromVault')
    public enviroment: IEnviroment
  ) {
    if (this.platform.isServer) {
      this.transferState.set(enviromentState, this.enviroment);
    } else {
      this.RECAPTCHA_API_KEY = this.transferState.get(
        enviromentState,
        null
      )!.RECAPTCHA_API_KEY;
    }
  }

  ngOnInit(): void {
    if (!this.platform.isServer) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.RECAPTCHA_API_KEY}`;
      document.head.appendChild(script);
    }
  }

  activeStep = 1;
  feedBackData: FeedbackFormData = {} as FeedbackFormData;
  submited = false;
  userBlocked = false;
  genericError = false;

  steps: { [key: number]: Step } = {
    1: {
      infoPanel: {
        title: 'Si das de baja tu cuenta vas a perder:',
        message:
          'Tu cuenta es única. Si continúas con la baja, en el futuro no vas a poder reabrirla ni crear una nueva.',
      },
    },
    2: {
      infoPanel: {
        title: '',
        message:
          'Si hay algún error en tu email podés volver atrás para editarlo y poder continuar con tu solicitud.',
      },
    },
  };

  maskEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return '';
    }

    const [username, domain] = email.split('@');
    const domainParts = domain.split('.');
    const maskedUsername =
      username.length > 3
        ? username.substring(0, 3) + '*'.repeat(username.length - 3)
        : username;

    let maskedEmail = maskedUsername;

    if (domainParts.length > 1) {
      maskedEmail += '@' + domainParts.join('.');
    } else {
      maskedEmail += '@' + '*'.repeat(domain.length);
    }

    return maskedEmail;
  }

  handleFeedbackFormSubmitSuccess(data: FeedbackFormData): void {
    this.feedBackData = data;
    this.steps[2].infoPanel.title = `Te enviamos un código al email ${this.maskEmail(
      this.feedBackData?.email
    )}`;
    this.activeStep = 2;
  }

  handleFeedbackFormSubmitError(error: HttpErrorResponse | Error): void {
    if (error instanceof HttpErrorResponse && error.status === 402) {
      console.error(
        'Start API Error: Usuario bloqueado por reenvíos o ingresos inválidos'
      );
      this.submited = true;
      this.userBlocked = true;
    } else {
      console.error('Start API Error:', error);
      this.submited = true;
      this.genericError = true;
    }
  }

  handleOptCodeFormSubmitSuccess(): void {
    this.userBlocked = false;
    this.genericError = false;
    this.submited = true;
  }

  handleOptCodeFormSubmitError(error: HttpErrorResponse | Error): void {
    if (error instanceof HttpErrorResponse && error.status === 402) {
      console.error(
        'Validate API Error: Usuario bloqueado por reenvíos o ingresos inválidos'
      );
      this.submited = true;
      this.userBlocked = true;
    } else {
      console.error('Validate API Error:', error);
      this.submited = true;
      this.genericError = true;
    }
  }

  handleOptCodeFormGoBack() {
    this.activeStep = 1;
  }

  handleExit() {
    this.activeStep = 1;
    this.userBlocked = false;
    this.submited = false;
  }

  handleResendCodeError(error: HttpErrorResponse | Error) {
    if (error instanceof HttpErrorResponse && error.status === 402) {
      console.error(
        'Send API Error: Usuario bloqueado por reenvíos o ingresos inválidos'
      );
      this.submited = true;
      this.userBlocked = true;
    } else {
      console.error('Send API Error:', error);
      this.submited = true;
      this.genericError = true;
    }
  }
}
