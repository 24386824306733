import { Injectable } from '@angular/core';
import { IEquisSelect } from '@tn-equis/core/components/select';

import { ChipFilterName, IBranch, SelectFields } from './branches-map.types';

@Injectable()
export class BranchFilteringService {
  // PRIVATE PROPERTIES
  private DEFAULT_SELECT_TEXT = 'Todos';
  private DEFAULT_SELECT_ID = -1;
  // PROPERTIES
  public DEFAULT_OPTION = this.createSelectItem(
    this.DEFAULT_SELECT_TEXT,
    this.DEFAULT_SELECT_ID
  );

  // METHODS
  /**
   * Filters the repeated values of a field in an array of branches
   * @param arr Array of branches
   * @param field Field to filter by
   * @returns Returns an array of branches with unique 'field' values
   */
  public getUnique(arr: IBranch[], field: keyof IBranch['address']): IBranch[] {
    const res: IBranch[] = [];

    for (const item of arr) {
      // If the state is not already in the result array, push it
      if (res.findIndex((i) => i.address[field] === item.address[field]) === -1) {
        res.push(item);
      }
    }

    return res;
  }

  /**
   * Filters an array of branches by a field and value
   * @param field Type of field to filter by
   * @param value Value to use as filter
   * @param branches List of branches to filter
   * @returns Returns an array of branches filtered by the provided field and value
   */
  public filterByField(
    field: SelectFields,
    value: string,
    branches: IBranch[]
  ) {
    // If default option is selected or no value is provided, return all branches
    if (!value || value === this.DEFAULT_SELECT_TEXT) return branches;
    return branches.filter((branch) => branch.address[field] === value);
  }

  /**
   * Filters an array of branches by a chip filter value
   * @param value Chip filter value
   * @param branches List of branches to filter
   * @returns Returns an array of branches filtered by the provided chip filter value
   */
  public filterByChip(value: ChipFilterName, branches: IBranch[]) {
    if (value === 'terminal')
      return branches.filter(
        (branch) =>
          branch.products.findIndex((product) => product.prod_id === '1') >= 0
      );
    // Default filter or unknown value, return all branches
    else return branches;
  }

  /**
   * Alphabetically sorts an array of branches using a field as parameter
   * @param key Key to sort by
   * @param branches List of branches to sort
   * @returns Returns an array of branches sorted by the provided key
   */
  public sortBy(key: SelectFields, branches: IBranch[]) {
    return branches.sort((a, b) => {
      const aField: string = a.address[key];
      const bField: string = b.address[key];

      return aField.localeCompare(bField);
    });
  }

  /**
   * Adds a default option to a select items array in the first position
   * @param arr Select items array
   * @returns Returns the provided array with a default option prepended
   */
  public addDefaultOption(arr: IEquisSelect[]): IEquisSelect[] {
    return [this.DEFAULT_OPTION, ...arr];
  }

  /**
   * Maps an array of branches to an array of select items
   * @param branches List of branches
   * @param key Field to use as value for the select
   * @returns Returns an array of objects with the properties of a select item
   */
  public branchesToSelect(
    branches: IBranch[],
    key: SelectFields
  ): IEquisSelect[] {
    return branches.map((branch, i) =>
      this.createSelectItem(branch.address[key], i)
    );
  }

  /**
   * Creates an object with the properties of a select item
   * @param text Text to display in the select
   * @param id ID of the select item
   * @returns Returns an object with the select item properties
   */
  public createSelectItem(text: string, id: number): IEquisSelect {
    return {
      id,
      text,
      disabled: false,
    };
  }
}
