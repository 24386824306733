<equis-accordion [isOpen]="false" (accordionEvents)="pushExpand($event)">
  <div headerTitle>
    <h3 class="accordion-label equis-body-1-medium">
      {{ data.fields.title }}
    </h3>
  </div>
  <nx-rich-text-renderer
    class="text"
    [text]="data.fields.text"
  ></nx-rich-text-renderer>
</equis-accordion>
