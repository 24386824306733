import type { Link } from '@/types/link';
import { EquisButtonBaseTypes as ButtonTypes } from '@tn-equis/core/components/button';

/**
 * Maps the style received from the CMS to the `ButtonTypes` enum.
 * @param style The style received from the CMS.
 * @returns An entry of the `ButtonTypes` enum.
 */
export const mapStyle = (style: Link['style'] = 'Relleno') => {
  switch (style) {
    case 'Relleno':
      return ButtonTypes.PRIMARY;
    case 'Transparente con bordes':
      return ButtonTypes.SECONDARY;
    case 'Transparente':
      return ButtonTypes.LINK;
    case 'Solo texto':
      return ButtonTypes.MENU;
    case 'Alternativo':
      return ButtonTypes.SECONDARY_LIGHT;
    default:
      return ButtonTypes.PRIMARY;
  }
};

/**
 * Gets the width value based on the width prop.
 * @param width Width prop
 * @returns Width value
 */
export const getWidth = (width?: string) => {
  return width === 'full' ? '100%' : 'auto';
};
